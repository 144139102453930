import React, { useEffect, useState, useCallback } from 'react';
import { FaThLarge, FaThList, FaCheck, FaEye, FaEraser, FaTrashAlt } from 'react-icons/fa';
import './PreencherQuadro.css';

interface ProcessData {
  title: string;
  processes: {
    [key: string]: {
      [key: string]: string[];
    };
  };
  processGroups: string[];
  knowledgeAreas: string[];
}

interface PreencherQuadroProps {
  data: ProcessData;
}

const PreencherQuadro: React.FC<PreencherQuadroProps> = ({ data }) => {
  const [processData, setProcessData] = useState<ProcessData>(data);

  const renderTable = useCallback(() => {
    if (!processData) return;
    const { processGroups, knowledgeAreas, processes } = processData;
    let tableHTML = '<table class="preencher-quadro-table"><thead><tr><th></th>';
    processGroups.forEach(group => {
      tableHTML += `<th>${group}</th>`;
    });
    tableHTML += '</tr></thead><tbody>';
    knowledgeAreas.forEach(area => {
      tableHTML += `<tr><td>${area}</td>`;
      processGroups.forEach(group => {
        const processList = processes[area] ? processes[area][group] : [];
        const processText = processList ? processList.join('<hr>') : '';
        tableHTML += `<td><div class="preencher-quadro-process-wrapper" contenteditable="true" data-area="${area}" data-group="${group}" data-correct-answer="${processText}"></div></td>`;
      });
      tableHTML += '</tr>';
    });
    tableHTML += '</tbody></table>';
    document.getElementById('processTable')!.innerHTML = tableHTML;
    const wrappers = document.querySelectorAll('.preencher-quadro-process-wrapper');
    wrappers.forEach(wrapper => {
      wrapper.addEventListener('input', handleInput as EventListener);
      wrapper.addEventListener('keypress', handleEnterKey as EventListener);
      wrapper.addEventListener('click', handleClick as EventListener);
    });
    syncRowHeights();
  }, [processData]);

  useEffect(() => {
    if (data) {
      setProcessData(data);
      renderTable();
    }
  }, [data, renderTable]);

  const autoResizeDiv = (e: Event) => {
    const target = e.target as HTMLElement;
    target.style.height = 'auto';
    target.style.height = target.scrollHeight + 'px';
    syncRowHeights();
  };

  const handleInput = (e: Event) => {
    autoResizeDiv(e);
  };

  const handleEnterKey = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.execCommand('insertHTML', false, '<br><hr><br>');
      syncRowHeights();
    }
  };

  const handleClick = (e: MouseEvent) => {
    const div = e.currentTarget as HTMLDivElement;
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(div);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
    div.focus();
  };

  const syncRowHeights = () => {
    const rows = document.querySelectorAll('#processTable tbody tr');
    rows.forEach(row => {
      let maxHeight = 0;
      const cells = row.querySelectorAll('.preencher-quadro-process-wrapper');
      cells.forEach(cell => {
        const height = cell.scrollHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      cells.forEach(cell => {
        (cell as HTMLElement).style.height = `${maxHeight}px`;
      });
    });
  };

  const showAnswers = () => {
    const wrappers = document.querySelectorAll('.preencher-quadro-process-wrapper');
    wrappers.forEach(wrapper => {
      wrapper.innerHTML = (wrapper as HTMLElement).dataset.correctAnswer!.replace(/<hr>/g, '<br><hr><br>');
      (wrapper as HTMLElement).style.height = 'auto';
      (wrapper as HTMLElement).style.height = wrapper.scrollHeight + 'px';
    });
    syncRowHeights();
  };

  const clearFields = () => {
    const wrappers = document.querySelectorAll('.preencher-quadro-process-wrapper');
    wrappers.forEach(wrapper => {
      (wrapper as HTMLElement).innerHTML = '';
      (wrapper as HTMLElement).style.height = 'auto';
      (wrapper as HTMLElement).classList.remove('preencher-quadro-correct', 'preencher-quadro-incorrect', 'preencher-quadro-partial', 'preencher-quadro-no-process');
      (wrapper as HTMLElement).style.borderColor = '';
      (wrapper as HTMLElement).style.color = '';
      (wrapper as HTMLElement).style.backgroundColor = '';
    });
    document.getElementById('results')!.innerHTML = '';
    syncRowHeights();
  };

  const evaluateItems = () => {
    const wrappers = document.querySelectorAll('.preencher-quadro-process-wrapper');
    let correctCount = 0;
    let totalCount = 0;

    wrappers.forEach(wrapper => {
      const correctAnswers = new Set(tokenize((wrapper as HTMLElement).dataset.correctAnswer!));
      const userAnswers = new Set(tokenize(wrapper.innerHTML));

      if (correctAnswers.size === 0) {
        (wrapper as HTMLElement).classList.add('preencher-quadro-no-process');
        (wrapper as HTMLElement).style.backgroundColor = 'black';
        (wrapper as HTMLElement).textContent = '';
        return;
      }

      totalCount += correctAnswers.size;
      let newValue = '';
      let correctInThisCell = 0;

      userAnswers.forEach(answer => {
        if (correctAnswers.has(answer)) {
          newValue += `<span style="color:green">${answer}</span><hr>`;
          correctCount++;
          correctInThisCell++;
          correctAnswers.delete(answer);
        } else {
          newValue += `<span style="color:red; text-decoration: line-through;">${answer}</span><hr>`;
        }
      });

      correctAnswers.forEach(answer => {
        newValue += `<span style="color:orange; text-decoration: none !important;">${answer}</span><hr>`;
      });

      (wrapper as HTMLElement).innerHTML = newValue.trim();
      (wrapper as HTMLElement).style.height = 'auto';
      (wrapper as HTMLElement).style.height = wrapper.scrollHeight + 'px';

      if (correctInThisCell === userAnswers.size) {
        (wrapper as HTMLElement).style.borderColor = 'green';
        (wrapper as HTMLElement).classList.add('preencher-quadro-correct');
        (wrapper as HTMLElement).classList.remove('preencher-quadro-incorrect', 'preencher-quadro-partial');
      } else if (correctInThisCell > 0) {
        (wrapper as HTMLElement).style.borderColor = 'yellow';
        (wrapper as HTMLElement).classList.add('preencher-quadro-partial');
        (wrapper as HTMLElement).classList.remove('preencher-quadro-correct', 'preencher-quadro-incorrect');
      } else {
        (wrapper as HTMLElement).style.borderColor = 'red';
        (wrapper as HTMLElement).classList.add('preencher-quadro-incorrect');
        (wrapper as HTMLElement).classList.remove('preencher-quadro-correct', 'preencher-quadro-partial');
      }
    });

    const percentage = ((correctCount / totalCount) * 100).toFixed(2);
    document.getElementById('results')!.innerHTML = `<strong>Total Correct: ${correctCount}</strong> out of ${totalCount} (${percentage}%)`;
    syncRowHeights();
  };

  const clearIncorrects = () => {
    const wrappers = document.querySelectorAll('.preencher-quadro-process-wrapper');
    wrappers.forEach(wrapper => {
      const spans = wrapper.querySelectorAll('span');
      let newValue = '';
      spans.forEach(span => {
        if (span.style.color === 'green') {
          newValue += `${span.innerText}<hr>`;
        }
      });
      (wrapper as HTMLElement).innerHTML = newValue.trim();
      (wrapper as HTMLElement).style.height = 'auto';
      (wrapper as HTMLElement).style.height = wrapper.scrollHeight + 'px';
      if (newValue.trim() === '') {
        (wrapper as HTMLElement).classList.remove('preencher-quadro-incorrect', 'preencher-quadro-partial');
        (wrapper as HTMLElement).style.borderColor = '';
        (wrapper as HTMLElement).style.color = '';
        (wrapper as HTMLElement).style.backgroundColor = '';
      }
    });
    syncRowHeights();
  };

  const tokenize = (content: string): string[] => {
    return content
      .split(/<br>|<hr>/)
      .map(item => item.trim().toLowerCase())
      .filter(Boolean);
  };

  return (
    <div className="preencher-quadro-container">
      <h2>{processData.title}</h2>
      <div id="processTable"></div>
      <div className="preencher-quadro-actions">
        <button className="preencher-quadro-button-evaluate" onClick={evaluateItems}><FaCheck /><span>Avaliar</span></button>
        <button className="preencher-quadro-button-show-answers" onClick={showAnswers}><FaEye /><span>Ver respostas</span></button>
        <button className="preencher-quadro-button-clear-fields" onClick={clearFields}><FaEraser /><span>Limpar</span></button>
        <button className="preencher-quadro-button-clear-incorrects" onClick={clearIncorrects}><FaTrashAlt /><span>Limpar Incorretas</span></button>
      </div>
      <div id="results"></div>
    </div>
  );
};

export default PreencherQuadro;