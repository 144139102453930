import React, { useState, useEffect, useRef } from 'react';
import './PerguntaDigitadaTreino.css';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface PerguntaDigitadaTreinoProps {
  pergunta: string;
  respostaCorreta: string;
  comment: string;
  onAcertou: () => void;
  onErrou: () => void;
}

const PerguntaDigitadaTreino: React.FC<PerguntaDigitadaTreinoProps> = ({
  pergunta,
  respostaCorreta,
  comment,
  onAcertou,
  onErrou,
}) => {
  const [resposta, setResposta] = useState<string>('');
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAnswered]);

  const handleResponderClick = () => {
    const acertou = resposta.trim().toLowerCase() === respostaCorreta.trim().toLowerCase();
    setIsCorrect(acertou);
    setIsAnswered(true);
  };

  const handleNextClick = () => {
    if (isCorrect) {
      onAcertou();
    } else {
      onErrou();
    }
    setResposta('');
    setIsAnswered(false);
    setIsCorrect(null);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="PerguntaDigitadaTreino-container">
      <div className="PerguntaDigitadaTreino-question">{pergunta}</div>
      <input
        ref={inputRef}
        type="text"
        value={resposta}
        onChange={(e) => setResposta(e.target.value)}
        disabled={isAnswered}
      />
      <div className="PerguntaDigitadaTreino-buttons">
        {!isAnswered && (
          <button onClick={handleResponderClick} disabled={!resposta.trim()}>
            Responder
          </button>
        )}
        {isAnswered && (
          <>
            <div className={`PerguntaDigitadaTreino-result ${isCorrect ? 'correct' : 'incorrect'}`}>
              {isCorrect ? 'Correto!' : `Incorreto. A resposta correta é: ${respostaCorreta}`}
            </div>
            <button onClick={handleNextClick}>Próxima</button>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Mostrar Comentário
              </AccordionSummary>
              <AccordionDetails>
                <div className="PerguntaDigitadaTreino-comment">{comment}</div>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
    </div>
  );
};

export default PerguntaDigitadaTreino;
