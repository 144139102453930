// src/components/PrivateRoute.tsx

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute: React.FC<{ allowedRoles: string[] }> = ({ allowedRoles }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(user.tipo)) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
