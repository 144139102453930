import React, { useState, useEffect } from 'react';
import { fetchComponentesParaRevisao, registrarSessaoRevisaoEspacada } from '../services/api';
import { Button, Typography } from '@mui/material';
import CompleteTexto from '../components/organisms/CompleteTexto/CompleteTexto';
import ImageOcclusion from '../components/organisms/ImageOcclusion/ImageOcclusion';
import ImageOcclusionPorClique from '../components/organisms/ImageOcclusionPorClique/ImageOcclusionPorClique';
import PreencherQuadro from '../components/organisms/PreencherQuadro/PreencherQuadro';
import SequencingGame from '../components/organisms/SequencingGame/SequencingGame';
import PreencherItemCategoria from '../components/organisms/PreencherItemCategoria/PreencherItemCategoria';
import ArrastarSoltarCategoria from '../components/organisms/ArrastarSoltarCategoria/ArrastarSoltarCategoria';
import Ordenacao from '../components/organisms/Ordenacao/Ordenacao';
import QuizDigitado from '../components/organisms/QuizDigitado/QuizDigitado';
import QuizMultiplaEscolha from '../components/organisms/QuizMultiplaEscolha/QuizMultiplaEscolha';
import TimeLine from '../components/organisms/TimeLine/TimeLine';
import FlipCard from '../components/organisms/FlipCard/FlipCard';
import RevealCard from '../components/organisms/RevealCard/RevealCard';
import TreinoFlashCard from '../components/organisms/treino/TreinoFlashCard/TreinoFlashCard';
import TreinoTermoDefinicao from '../components/organisms/treino/TreinoTermoDefinicao/TreinoTermoDefinicao';
import PerguntaDigitado from '../components/organisms/PerguntaDigitado/PerguntaDigitado';
import FlashCard from '../components/organisms/FlashCard/FlashCard';
import QuizMultiploImediato from '../components/organisms/QuizMultiploImediato/QuizMultiploImediato';
import DecisionTreeComic from '../components/organisms/DecisionTreeComic/DecisionTreeComic';
import Texto from '../components/organisms/Texto/Texto';
import ExcalidrawViewer from '../components/organisms/ExcalidrawViewer/ExcalidrawViewer';
import PerguntaMultiplaEscolha from '../components/organisms/PerguntaMultiplaEscolha/PerguntaMultiplaEscolha';
import Confetti from 'react-confetti'; // Importando o Confetti
import './RevisaoEspacadaViewer.css';  // Novo arquivo CSS para estilização

interface RevisaoEspacadaViewerProps {
  usuarioId: number;
  baralhoIds: number[];
}

const RevisaoEspacadaViewer: React.FC<RevisaoEspacadaViewerProps> = ({ usuarioId, baralhoIds }) => {
  const [componentes, setComponentes] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [concluido, setConcluido] = useState(false);
  const [viewerKey, setViewerKey] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false); // Novo estado para mostrar o Confetti


  useEffect(() => {
    const fetchComponentes = async () => {
      try {
        const todosComponentes = [];
        for (const baralhoId of baralhoIds) {
          const response = await fetchComponentesParaRevisao(baralhoId);
          todosComponentes.push(...response.data);
        }
        setComponentes(todosComponentes);
        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao buscar componentes para revisão:', error);
        setIsLoading(false);
      }
    };

    if (baralhoIds && baralhoIds.length > 0) {
      fetchComponentes();
    }
  }, [baralhoIds]);

const handleResponse = async (response: string) => {
    const componente = componentes[currentIndex];
    const itemRevisao = componente.itemRevisao;

    if (!itemRevisao) {
        console.error('Item de revisão não encontrado para o componente:', componente);
        return;
    }

    try {
        await registrarSessaoRevisaoEspacada(usuarioId, itemRevisao.id, response, response !== 'Errei');

        let newComponentes = [...componentes];

        if (response === 'Errei') {
            const [removed] = newComponentes.splice(currentIndex, 1);
            const newPosition = newComponentes.length > 4 ? Math.min(currentIndex + 5, newComponentes.length) : newComponentes.length;
            newComponentes.splice(newPosition, 0, removed);
        } else {
            newComponentes.splice(currentIndex, 1);
        }

        if (newComponentes.length > 0) {
            setComponentes(newComponentes);
            setCurrentIndex(0);
        } else {
            setConcluido(true);
            setShowConfetti(true); 
        }

        // Se for o último item e a resposta for "Errei", forçamos uma recriação do componente
        if (newComponentes.length === 1 && response === 'Errei') {
            setViewerKey(viewerKey + 1);
        }
    } catch (error) {
        console.error('Erro ao registrar resposta:', error);
    }
};

  // Função para calcular o intervalo formatado
  const formatarIntervalo = (dias: any) => {
    if (dias < 30) {
      return ` (${Math.floor(dias)}d)`; // Exibe em dias
    } else if (dias < 365) {
      return ` (${(dias / 30).toFixed(1)}m)`; // Exibe em meses
    } else {
      return ` (${(dias / 365).toFixed(1)}a)`; // Exibe em anos
    }
  };

    // Função para calcular o próximo intervalo com base na resposta
    const calcularProximoIntervalo = (intervaloAtual:any, fatorFacilidade:any, resposta:any) => {
      switch (resposta) {
        case 'Fácil':
          return intervaloAtual * (fatorFacilidade + 0.25)+2;
        case 'Bom':
          return intervaloAtual * fatorFacilidade;
        case 'Difícil':
          return intervaloAtual * 1.2;
        case 'Errei':
        default:
          return 1; // Se errou, o próximo intervalo é 1 dia
      }
    };

  const renderComponente = (componente: any) => {
    if (!componente || !componente.dados) {
        console.error('Componente ou dados do componente não encontrados:', componente);
        return <p>Erro ao carregar componente.</p>;
    }

    const { dados, type } = componente;
    switch (type) {
        case 'CompleteTexto':
            return <CompleteTexto key={componente.id} id={componente.id} title={dados.title || ''} texto={dados.texto || ''} />;
        case 'ImageOcclusion':
            return dados.mode === 'digitada' ? (
                <ImageOcclusion key={componente.id} data={dados} />
            ) : (
                <ImageOcclusionPorClique key={componente.id} data={dados} />
            );
        case 'PreencherQuadro':
            return <PreencherQuadro key={componente.id} data={dados} />;
        case 'SequencingGame':
            return <SequencingGame key={componente.id} data={dados} />;
        case 'PreencherItemCategoria':
            return <PreencherItemCategoria key={componente.id} data={dados} />;
        case 'ArrastarSoltarCategoria':
            return <ArrastarSoltarCategoria key={componente.id} data={dados} />;
        case 'Ordenacao':
            return <Ordenacao key={componente.id} data={dados} />;
        case 'QuizDigitado':
            return <QuizDigitado key={componente.id} data={dados} />;
        case 'QuizMultiplaEscolha':
            return <QuizMultiplaEscolha key={componente.id} data={dados} />;
        case 'TimeLine':
            return <TimeLine key={componente.id} data={dados} />;
        case 'Card':
            return dados.mode === 'flip' ? (
                <FlipCard key={componente.id} id={componente.id} front={dados.front} back={dados.back} />
            ) : (
                <RevealCard key={componente.id} id={componente.id} front={dados.front} back={dados.back} comment={dados.comment} />
            );
        case 'TreinoFlashCard':
            return <TreinoFlashCard key={componente.id} cards={dados.treinoFlashCardsData || []} onComplete={() => {}} />;
        case 'TreinoTermoDefinicao':
            return <TreinoTermoDefinicao key={componente.id} dados={dados} />;
        case 'PerguntaDigitado':
            return <PerguntaDigitado key={componente.id} id={componente.id} pergunta={dados.question} respostaCorreta={dados.respostaCorreta} comment={dados.comment} />;
        case 'FlashCard':
            return <FlashCard key={componente.id} title={dados.title} flashcardData={dados.flashcardData} />;
        case 'QuizMultiploImediato':
            return <QuizMultiploImediato key={componente.id} data={dados.questionData} />;
        case 'DecisionTreeComic':
            return <DecisionTreeComic key={componente.id} data={dados} />;
        case 'Texto':
            return <Texto key={componente.id} html={dados.html} />;
        case 'Excalidraw':
            return <ExcalidrawViewer key={componente.id} elements={dados.elements} appState={dados.appState} />;
        case 'PerguntaMultiplaEscolha':
            return (
                <PerguntaMultiplaEscolha
                    key={componente.id}
                    question={dados.question || ''}
                    options={dados.options || []}
                    questionType={dados.questionType || 'single'}
                    comment={dados.comment || ''}
                />
            );
        default:
            return null;
    }
};


  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (componentes.length === 0) {
    return <p>Nenhum componente disponível para revisão.</p>;
  }

  if (concluido) {
    return (
      <div>
        <Confetti // Componente para gerar os confetes
          width={window.innerWidth}
          height={window.innerHeight}
        />
        <Typography variant="h5">Parabéns! As revisões foram concluídas.</Typography>
      </div>
    );
  }

  const itemRevisao = componentes[currentIndex]?.itemRevisao;

  return (
    <div className="RevisaoEspacadaViewer-container"  key={viewerKey}>
      <div className="RevisaoEspacadaViewer-content">
        {renderComponente(componentes[currentIndex])}
      </div>
      <div className="RevisaoEspacadaViewer-buttons">
        <Button className='RevisaoEspacadaViewer-button' variant="contained" color="error" onClick={() => handleResponse('Errei')}>
          Errei
          <span className="intervalo-span">{'(10min)'}</span>
        </Button>
        <Button className='RevisaoEspacadaViewer-button' variant="contained" color="inherit" onClick={() => handleResponse('Difícil')}>
          Difícil
          <span className="intervalo-span">{itemRevisao ? formatarIntervalo(calcularProximoIntervalo(itemRevisao.intervalo_dias, itemRevisao.fator_facilidade, 'Difícil')) : ''}</span>
        </Button>
        <Button className='RevisaoEspacadaViewer-button' variant="contained" color="primary" onClick={() => handleResponse('Bom')}>
          Bom
          <span className="intervalo-span">{itemRevisao ? formatarIntervalo(calcularProximoIntervalo(itemRevisao.intervalo_dias, itemRevisao.fator_facilidade, 'Bom')) : ''}</span>
        </Button>
        <Button className='RevisaoEspacadaViewer-button' variant="contained" color="success" onClick={() => handleResponse('Fácil')}>
          Fácil 
          <span className="intervalo-span">{itemRevisao ? formatarIntervalo(calcularProximoIntervalo(itemRevisao.intervalo_dias, itemRevisao.fator_facilidade, 'Fácil')) : ''}</span>
        </Button>
      </div>
    </div>
  );
};

export default RevisaoEspacadaViewer;
