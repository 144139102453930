import React from 'react';
import './RevealCard.css';
import { parseContent } from '../../../helpers/parser';
import { Container } from '../../../helpers/StyledComponents';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface RevealCardProps {
  id: number;
  front: string;
  back: string;
  comment?: string;
}

const RevealCard: React.FC<RevealCardProps> = ({ id, front, back, comment = '' }) => {
  const [revealed, setRevealed] = React.useState(false);
  const [frontContent, setFrontContent] = React.useState<React.ReactNode[]>([]);
  const [backContent, setBackContent] = React.useState<React.ReactNode[]>([]);
  const [commentContent, setCommentContent] = React.useState<React.ReactNode[]>([]);
  const [showComment, setShowComment] = React.useState(false);

  React.useEffect(() => {
    const parseFrontContent = async () => {
      const parsedContent = await parseContent(front);
      setFrontContent(parsedContent);
    };
    const parseBackContent = async () => {
      const parsedContent = await parseContent(back);
      setBackContent(parsedContent);
    };
    const parseCommentContent = async () => {
      const parsedContent = await parseContent(comment);
      setCommentContent(parsedContent);
    };

    parseFrontContent();
    parseBackContent();
    parseCommentContent();
  }, [front, back, comment]);

  const handleToggleReveal = () => {
    setRevealed(!revealed);
  };

  const handleToggleComment = () => {
    setShowComment(!showComment);
  };

  return (
    <div className="reveal-card">
      <Container className="reveal-card-front">{frontContent}</Container>
      {revealed && (
        <>
          <Container className="reveal-card-back">{backContent}</Container>
          {comment && (
            <>
              <div className="accordion" onClick={handleToggleComment}>
                  <span>{showComment ? 'Ocultar Comentário' : 'Saiba mais'}</span>
                  {showComment ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {showComment && <Container className="reveal-card-comment">{commentContent}</Container>}
            </>
          )}
        </>
      )}
      <div className='reveal-button-container'>
        <button className="reveal-button" onClick={handleToggleReveal}>
          {revealed ? 'Ocultar Resposta' : 'Mostrar Resposta'}
        </button>
      </div>
    </div>
  );
};

export default RevealCard;
