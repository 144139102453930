import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  Modal,
  Box,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Importando o ícone de expansão
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TreinoFlashCardsEditor.css';
import { TreinoFlashCardData } from '../../../types';

interface TreinoFlashCardsEditorProps {
  initialCards: TreinoFlashCardData[];
  onSave: (cards: TreinoFlashCardData[]) => void;
  onCancel: () => void;
}

type FreezeFieldKey = 'question' | 'answer' | 'comment';

const TreinoFlashCardsEditor: React.FC<TreinoFlashCardsEditorProps> = ({ initialCards, onSave, onCancel }) => {
  const [cards, setCards] = useState<TreinoFlashCardData[]>(initialCards);
  const [currentCard, setCurrentCard] = useState<TreinoFlashCardData>({ id: Date.now(), question: '', answer: '', comment: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editingCard, setEditingCard] = useState<TreinoFlashCardData | null>(null);
  const [freezeFields, setFreezeFields] = useState<{ [key in FreezeFieldKey]: boolean }>({
    question: false,
    answer: false,
    comment: false,
  });
  const [expanded, setExpanded] = useState<boolean>(false);
  const questionQuillRef = useRef<ReactQuill>(null);
  const answerQuillRef = useRef<ReactQuill>(null);
  const commentQuillRef = useRef<ReactQuill>(null);
  const accordionRef = useRef<HTMLDivElement>(null);

  const handleAddCard = () => {
    const newCard = { ...currentCard, id: Date.now() };
    setCards((prevCards) => [...prevCards, newCard]);
    setCurrentCard({
      id: Date.now(),
      question: freezeFields.question ? currentCard.question : '',
      answer: freezeFields.answer ? currentCard.answer : '',
      comment: freezeFields.comment ? currentCard.comment : '',
    });
  };

  const handleSave = () => {
    onSave(cards);
  };

  const handleFieldChange = (field: keyof TreinoFlashCardData, value: string) => {
    setCurrentCard((prevCard) => ({ ...prevCard, [field]: value }));
  };

  const handleRemoveCard = (id: number) => {
    setCards((prevCards) => prevCards.filter(card => card.id !== id));
  };

  const handleEditCard = (card: TreinoFlashCardData) => {
    setEditingCard(card);
    setIsEditing(true);
  };

  const handleModalClose = () => {
    setIsEditing(false);
    setEditingCard(null);
  };

  const handleModalSave = () => {
    if (editingCard) {
      setCards((prevCards) => prevCards.map(card => card.id === editingCard!.id ? editingCard! : card));
    }
    handleModalClose();
  };

  const handleFreezeFieldChange = (field: FreezeFieldKey) => {
    setFreezeFields((prevFreezeFields) => ({ ...prevFreezeFields, [field]: !prevFreezeFields[field] }));
  };

  const handleKeyDown = (event: React.KeyboardEvent, field: FreezeFieldKey) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      if (field === 'question') {
        answerQuillRef.current?.getEditor().focus();
      } else if (field === 'answer') {
        commentQuillRef.current?.getEditor().focus();
      }
    }
  };

  const handleEditingFieldChange = (field: keyof TreinoFlashCardData, value: string) => {
    setEditingCard((prevCard) => {
      if (prevCard) {
        return { ...prevCard, [field]: value };
      }
      return null;
    });
  };

  const handleAccordionChange = (isExpanded: boolean) => {
    setExpanded(isExpanded);
    if (isExpanded && accordionRef.current) {
      setTimeout(() => {
        accordionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300); // tempo para esperar a animação do accordion
    }
  };

  return (
    <div>
      <Card className="TreinoFlashCardsEditor-card">
        <CardContent>
          <Typography variant="h5">Adicionar Novo FlashCard</Typography>
          <div>
            <Typography variant="subtitle1">Pergunta</Typography>
            <ReactQuill
              ref={questionQuillRef}
              value={currentCard.question}
              onChange={(value) => handleFieldChange('question', value)}
              onKeyDown={(e) => handleKeyDown(e, 'question')}
              modules={{
                toolbar: [
                  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['bold', 'italic', 'underline'],
                  [{ 'align': [] }],
                  ['link', 'image'],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['clean']
                ]
              }}
              formats={[
                'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background', 'font', 'align'
              ]}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={freezeFields.question}
                  onChange={() => handleFreezeFieldChange('question')}
                />
              }
              label="Congelar campo"
            />
          </div>
          <div>
            <Typography variant="subtitle1">Resposta</Typography>
            <ReactQuill
              ref={answerQuillRef}
              value={currentCard.answer}
              onChange={(value) => handleFieldChange('answer', value)}
              onKeyDown={(e) => handleKeyDown(e, 'answer')}
              modules={{
                toolbar: [
                  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['bold', 'italic', 'underline'],
                  [{ 'align': [] }],
                  ['link', 'image'],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['clean']
                ]
              }}
              formats={[
                'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background', 'font', 'align'
              ]}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={freezeFields.answer}
                  onChange={() => handleFreezeFieldChange('answer')}
                />
              }
              label="Congelar campo"
            />
          </div>
          <div>
            <Typography variant="subtitle1">Comentário</Typography>
            <ReactQuill
              ref={commentQuillRef}
              value={currentCard.comment || ''}
              onChange={(value) => handleFieldChange('comment', value)}
              modules={{
                toolbar: [
                  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['bold', 'italic', 'underline'],
                  [{ 'align': [] }],
                  ['link', 'image'],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['clean']
                ]
              }}
              formats={[
                'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background', 'font', 'align'
              ]}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={freezeFields.comment}
                  onChange={() => handleFreezeFieldChange('comment')}
                />
              }
              label="Congelar campo"
            />
          </div>
          <Button variant="contained" color="inherit" onClick={handleAddCard} startIcon={<AddIcon />}>
            Adicionar Card
          </Button>
        </CardContent>
      </Card>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" color="inherit" onClick={handleSave} startIcon={<SaveIcon />}>
          Salvar
        </Button>
        <Button variant="contained" color="inherit" onClick={onCancel} startIcon={<CancelIcon />} style={{ marginLeft: 10 }}>
          Cancelar
        </Button>
      </div>
      <Accordion ref={accordionRef} expanded={expanded} onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Histórico de FlashCards</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {cards.map((card) => (
            <Accordion key={card.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${card.id}-content`}
                id={`panel${card.id}-header`}
              >
                <Typography
                  dangerouslySetInnerHTML={{ __html: card.question }}
                  variant="h6"
                  component="div"
                />
              </AccordionSummary>
              <AccordionDetails>
                <div className="TreinoFlashCardsEditor-card-item">
                  <IconButton color="primary" onClick={() => handleEditCard(card)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleRemoveCard(card.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionDetails>
      </Accordion>
      {editingCard && (
        <Modal open={isEditing} onClose={handleModalClose}>
          <Box className="TreinoFlashCardsEditor-modal">
            <Typography variant="h6">Editar FlashCard</Typography>
            <Typography variant="subtitle1">Pergunta</Typography>
            <ReactQuill
              ref={questionQuillRef}
              value={editingCard.question}
              onChange={(value) => handleEditingFieldChange('question', value)}
              onKeyDown={(e) => handleKeyDown(e, 'question')}
              modules={{
                toolbar: [
                  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['bold', 'italic', 'underline'],
                  [{ 'align': [] }],
                  ['link', 'image'],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['clean']
                ]
              }}
              formats={[
                'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background', 'font', 'align'
              ]}
            />
            <Typography variant="subtitle1">Resposta</Typography>
            <ReactQuill
              ref={answerQuillRef}
              value={editingCard.answer}
              onChange={(value) => handleEditingFieldChange('answer', value)}
              onKeyDown={(e) => handleKeyDown(e, 'answer')}
              modules={{
                toolbar: [
                  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['bold', 'italic', 'underline'],
                  [{ 'align': [] }],
                  ['link', 'image'],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['clean']
                ]
              }}
              formats={[
                'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background', 'font', 'align'
              ]}
            />
            <Typography variant="subtitle1">Comentário</Typography>
            <ReactQuill
              ref={commentQuillRef}
              value={editingCard.comment || ''}
              onChange={(value) => handleEditingFieldChange('comment', value)}
              modules={{
                toolbar: [
                  [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['bold', 'italic', 'underline'],
                  [{ 'align': [] }],
                  ['link', 'image'],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['clean']
                ]
              }}
              formats={[
                'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background', 'font', 'align'
              ]}
            />
            <div className="TreinoFlashCardsEditor-modal-buttons">
              <Button variant="contained" color="primary" onClick={handleModalSave}>
                Salvar
              </Button>
              <Button variant="contained" color="inherit" onClick={handleModalClose}>
                Cancelar
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default TreinoFlashCardsEditor;
