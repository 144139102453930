import React from 'react';
import parse from 'html-react-parser';
import CustomAccordion from '../components/utils/CustomAccordion/CustomAccordion';
import Comment from '../components/utils/Comment/Comment';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const parseContent = (html: string) => {
  const regexes = {
    accordion: /\[\{\{([\s\S]*?)\}\}\{\{([\s\S]*?)\}\}\]/g,
    commentType: /<p[^>]*>:::comentario (Alerta|Questão|Informação|Erro|Importante)<\/p><p[^>]*>([\s\S]*?)<\/p><p[^>]*>---<\/p>([\s\S]*?)<p[^>]*>---<\/p>/g,
    commentGeneric: /<p[^>]*>:::comentario\s*\{([\s\S]*?)\}\s*\{([\s\S]*?)\}<\/p><p[^>]*>([\s\S]*?)<\/p><p[^>]*>---<\/p>/g,
  };

  let match;
  const elements = [];
  let lastIndex = 0;

  const addParsedText = (text: string) => {
    if (text.trim()) {
      elements.push(parse(text));
    }
  };

  while ((match = regexes.accordion.exec(html)) !== null) {
    const [fullMatch, frontText, backText] = match;
    const start = match.index;
    const end = regexes.accordion.lastIndex;

    if (start > lastIndex) {
      addParsedText(html.slice(lastIndex, start));
    }

    elements.push(
      <CustomAccordion key={start} defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${start}-content`}
          id={`panel-${start}-header`}
        >
          {parse(frontText)}
        </AccordionSummary>
        <AccordionDetails
          id={`panel-${start}-content`}
          aria-labelledby={`panel-${start}-header`}
        >
          {parse(backText)}
        </AccordionDetails>
      </CustomAccordion>
    );

    lastIndex = end;
  }

  while ((match = regexes.commentType.exec(html)) !== null) {
    const [fullMatch, type, question, answer] = match;
    const start = match.index;
    const end = regexes.commentType.lastIndex;

    if (start > lastIndex) {
      addParsedText(html.slice(lastIndex, start));
    }

    const bgColor = getCommentBackgroundColor(type);

    elements.push(
      <CustomAccordion key={start} defaultExpanded={false} bgColor={bgColor}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${start}-content`}
          id={`panel-${start}-header`}
        >
          <Comment type={type} title={question} text="" />
        </AccordionSummary>
        <AccordionDetails>
          {parse(answer.trim())}
        </AccordionDetails>
      </CustomAccordion>
    );

    lastIndex = end;
  }

  while ((match = regexes.commentGeneric.exec(html)) !== null) {
    const [fullMatch, color, title, text] = match;
    const start = match.index;
    const end = regexes.commentGeneric.lastIndex;

    if (start > lastIndex) {
      addParsedText(html.slice(lastIndex, start));
    }

    elements.push(
      <CustomAccordion key={start} defaultExpanded={false} bgColor={color}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${start}-content`}
          id={`panel-${start}-header`}
        >
          <Comment color={color} title={title} text="" />
        </AccordionSummary>
        <AccordionDetails>
          {parse(text.trim())}
        </AccordionDetails>
      </CustomAccordion>
    );

    lastIndex = end;
  }

  if (lastIndex < html.length) {
    addParsedText(html.slice(lastIndex));
  }

  return elements;
};

const getCommentBackgroundColor = (type: string) => {
  switch (type.toLowerCase()) {
    case 'alerta':
      return '#fff3cd';
    case 'questão':
      return '#d1ecf1';
    case 'informação':
      return '#d4edda';
    case 'erro':
      return '#f8d7da';
    case 'importante':
      return '#fff8e1';
    default:
      return 'yellow'; // Default background color for generic comments
  }
};
