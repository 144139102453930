import React from 'react';

interface ImageUploaderProps {
  onUpload: (imageUrl: string) => void;
  onSaveData?: (imageUrl: string) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onUpload, onSaveData }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('image', file);

    const response = await fetch(`${apiUrl}/upload`, {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    console.log('Uploaded image URL:', data.imageUrl); // Verifique o URL aqui
    onUpload(data.imageUrl);
    
    // Chama onSaveData se foi fornecida
    if (onSaveData) {
      onSaveData(data.imageUrl);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageUpload} />
    </div>
  );
};


export default ImageUploader;
