import React, { useState } from 'react';
import { TextField, Button, Card, CardContent, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './PerguntaDigitadoEditor.css';

interface PerguntaDigitadoEditorProps {
  initialQuestion?: string;
  initialAnswer?: string;
  initialComment?: string;
  onSave: (question: string, answer: string, comment: string, action: 'close' | 'addNew') => void;
  onCancel: () => void;
  mode: 'add' | 'edit';
}

const PerguntaDigitadoEditor: React.FC<PerguntaDigitadoEditorProps> = ({
  initialQuestion = '',
  initialAnswer = '',
  initialComment = '',
  onSave,
  onCancel,
  mode,
}) => {
  const [question, setQuestion] = useState<string>(initialQuestion);
  const [answer, setAnswer] = useState<string>(initialAnswer);
  const [comment, setComment] = useState<string>(initialComment);

  const handleSave = (action: 'close' | 'addNew') => {
    onSave(question, answer, comment, action);
    if (mode === 'add' && action === 'addNew') {
      setQuestion('');
      setAnswer('');
      setComment('');
    }
  };

  return (
    <Card className="PerguntaDigitadoEditor-card">
      <CardContent>
        <Typography variant="h5" component="div">
          {mode === 'add' ? 'Adicionar Nova Pergunta' : 'Editar Pergunta'}
        </Typography>
        <TextField
          label="Pergunta"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Resposta Correta"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          fullWidth
          margin="normal"
        />
        <ReactQuill
          value={comment}
          onChange={setComment}
          modules={{
            toolbar: [
              [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['bold', 'italic', 'underline'],
              [{ 'align': [] }],
              ['link', 'image'],
              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              [{ 'font': [] }],
              [{ 'align': [] }],
              ['clean']
            ]
          }}
          formats={[
            'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background', 'font', 'align'
          ]}
        />
        <div className="PerguntaDigitadoEditor-buttons">
          <Button variant="contained" color="primary" onClick={() => handleSave(mode === 'add' ? 'addNew' : 'close')} startIcon={<SaveIcon />}>
            {mode === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
          <Button variant="contained" onClick={onCancel} startIcon={<CancelIcon />}>
            Cancelar
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default PerguntaDigitadoEditor;
