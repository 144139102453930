import React, { useState, useEffect } from 'react';
import { TreeTable, TreeTableTogglerTemplateOptions } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { TreeNode } from 'primereact/treenode';
import { fetchBaralhos, fetchEstatisticas, fetchItensRevisaoEspacadaByBaralho } from '../services/api';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Dropdown } from 'primereact/dropdown';
import './EstatisticasPage.css';

interface Baralho {
  id: number;
  nome: string;
  subBaralhos: Baralho[];
}

interface EstatisticasPageProps {
  usuarioId: number;
}

const EstatisticasPage: React.FC<EstatisticasPageProps> = ({ usuarioId }) => {
  const [baralhos, setBaralhos] = useState<TreeNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>({});
  const [estatisticas, setEstatisticas] = useState<any>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>('Hoje');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const filterOptions = [
    { label: 'Hoje', value: 'Hoje' },
    { label: 'Última semana', value: 'Última semana' },
    { label: 'Último mês', value: 'Último mês' },
    { label: 'Todos', value: 'Todos' },
  ];

  const mapFilterToDias = (filter: string) => {
    switch (filter) {
      case 'Hoje':
        return 0;
      case 'Última semana':
        return 7;
      case 'Último mês':
        return 30;
      case 'Todos':
        return -1;
      default:
        return -1; // Retornar todos os dados por padrão
    }
  };


  const fetchData = async () => {
    try {
      const baralhosResponse = await fetchBaralhos();
      const baralhosData = await transformData(baralhosResponse.data);
      setBaralhos(baralhosData);
    
      const dias = mapFilterToDias(selectedFilter);
      const estatisticasResponse = await fetchEstatisticas(usuarioId, dias);
      setEstatisticas(estatisticasResponse.data);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [usuarioId, selectedFilter]);

  const transformData = async (data: Baralho[]): Promise<TreeNode[]> => {
    return Promise.all(data.map(async (item): Promise<TreeNode> => {
      const { data: itensRevisao } = await fetchItensRevisaoEspacadaByBaralho(item.id);
      
      if (!Array.isArray(itensRevisao)) {
        console.error(`Expected an array but received:`, itensRevisao);
        return {
          key: item.id.toString(),
          data: {
            id: item.id,
            nome: item.nome,
            totalItens: 0,
            maduros: 0,
            aprendendo: 0,
            novos: 0,
          },
          children: [],
        };
      }
  
      const maduros = itensRevisao.filter((item: any) => item.repeticoes >= 5).length;
      const aprendendo = itensRevisao.filter((item: any) => item.repeticoes >= 1 && item.repeticoes < 5).length;
      const novos = itensRevisao.filter((item: any) => item.repeticoes === 0 || item.status === 'novo').length;
      const totalItens = itensRevisao.length;
  
      return {
        key: item.id.toString(),
        data: {
          id: item.id,
          nome: item.nome,
          totalItens,
          maduros,
          aprendendo,
          novos,
        },
        children: item.subBaralhos ? await transformData(item.subBaralhos) : []
      };
    }));
  };

  const calculateTotals = (node: TreeNode) => {
    let totalItens = node.data.totalItens || 0;
    let maduros = node.data.maduros || 0;
    let aprendendo = node.data.aprendendo || 0;
    let novos = node.data.novos || 0;
  
    if (node.children && node.children.length > 0) {
      node.children.forEach((childNode: TreeNode) => {
        const subTotals = calculateTotals(childNode);
        totalItens += subTotals.totalItens;
        maduros += subTotals.maduros;
        aprendendo += subTotals.aprendendo;
        novos += subTotals.novos;
      });
    }
  
    return { totalItens, maduros, aprendendo, novos };
  };

  const onToggle = (event: { value: any }) => {
    setExpandedKeys(event.value);
  };

  const togglerTemplate = (node: TreeNode, options: TreeTableTogglerTemplateOptions) => {
    if (!node) {
      return null;
    }

    const expanded = expandedKeys[node.key];
    return (
      <button type="button" className="EstatisticasPage-togglerButton" style={options.buttonStyle} tabIndex={-1} onClick={options.onClick}>
        {expanded ? '▼' : '►'}
      </button>
    );
  };

  const folderTemplate = (node: TreeNode) => {
    const expanded = expandedKeys[node.key];
    return (
      <>
        {expanded ? '📂' : '📁'}
        <span className="EstatisticasPage-baralhoNome">{node.data.nome}</span>
      </>
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row className='EstatisticasPage-LinhaFooter'>
        <Column footer="Totais:" colSpan={1} className="EstatisticasPage-footerLabel" />
        <Column footer={baralhos.reduce((sum, node) => sum + calculateTotals(node).totalItens, 0)} className="EstatisticasPage-totalItens" />
        <Column footer={baralhos.reduce((sum, node) => sum + calculateTotals(node).maduros, 0)} />
        <Column footer={baralhos.reduce((sum, node) => sum + calculateTotals(node).aprendendo, 0)} />
        <Column footer={baralhos.reduce((sum, node) => sum + calculateTotals(node).novos, 0)} />
      </Row>
    </ColumnGroup>
  );

  const revisoesCategories = estatisticas ? [
    { name: 'Errei', value: estatisticas.totalErros },
    { name: 'Difícil', value: estatisticas.respostasDificeis },
    { name: 'Bom', value: estatisticas.respostasBoas },
    { name: 'Fácil', value: estatisticas.respostasFaceis },
  ] : [];

  const totalRevisoes = revisoesCategories.reduce((total, category) => total + category.value, 0);

  const acertosErrosData = estatisticas ? [
    { name: 'Acertos', value: estatisticas.totalAcertos },
    { name: 'Erros', value: estatisticas.totalErros },
  ] : [];

  const colors = ['#008800', '#FF0000', '#FFBB28', '#FF8042'];

  return (
    <div className="EstatisticasPage-container">
      <h2>Estatísticas de Revisão</h2>

      {baralhos.length === 0 ? (
        <p className="EstatisticasPage-noBaralhosMessage">Nenhum baralho disponível.</p>
      ) : (
        <div className="EstatisticasPage-tabelaWrapper">
          <TreeTable
            className="EstatisticasPage-tabelaBaralhos"
            value={baralhos}
            expandedKeys={expandedKeys}
            onToggle={onToggle}
            togglerTemplate={togglerTemplate}
            footerColumnGroup={footerGroup}
            showGridlines={true}
            resizableColumns={true}
            sortMode="multiple"
          >
            <Column
              className="EstatisticasPage-baralhoNomeColumn"
              field="nome"
              header="Baralho"
              sortable
              body={folderTemplate}
            />
            <Column
              field="totalItens"
              header={isMobile?'':"Total de Itens"}
              sortable
              className="EstatisticasPage-columnCenter"
              body={(node: TreeNode) => (
                <span>{calculateTotals(node).totalItens}</span>
              )}
            />
            <Column
              field="maduros"
              header={isMobile?'':'Maduros'}
              sortable
              className="EstatisticasPage-columnCenter"
              body={(node: TreeNode) => (
                <span>{calculateTotals(node).maduros}</span>
              )}
            />
            <Column
              field="aprendendo"
              header={isMobile?'':"Aprendendo"}
              sortable
              className="EstatisticasPage-columnCenter"
              body={(node: TreeNode) => (
                <span>{calculateTotals(node).aprendendo}</span>
              )}
            />
            <Column
              field="novos"
              header={isMobile?'':"Novos"}
              sortable
              className="EstatisticasPage-columnCenter"
              body={(node: TreeNode) => (
                <span>{calculateTotals(node).novos}</span>
              )}
            />
          </TreeTable>
        </div>
      )}
      <div className='EstatisticasPage-revisoes'>
        <div className='EstatisticasPage-Filtro'>
          <p className='titulo-estatistica-filtro'>Escolha o período:</p> <br />
          <Dropdown 
            value={selectedFilter} 
            options={filterOptions} 
            onChange={(e) => setSelectedFilter(e.value)} 
            placeholder="Selecione um filtro" 
            className="estatísticas-filtro-selecione"
            checkmark={true}
            highlightOnSelect={false}
          />
        </div>
        <div className='EstatisticasPage-revisoes-graficos'>
          <div className='EstatisticasPage-revisoesPorCategoria'>
            <h3>Resumo das Revisões</h3>
            <table className="EstatisticasPage-revisoesTable">
              <thead>
                <tr>
                  <th>Categoria</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {revisoesCategories.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
                <tr className="EstatisticasPage-totalRow">
                  <td>Total</td>
                  <td>{totalRevisoes}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='EstatisticasPage-reviesoesAcertosErros'>
            <h3>Percentual de Acertos/Erros</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie data={acertosErrosData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50}>
                  {acertosErrosData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstatisticasPage;
