import React, { useState, useRef, useEffect } from 'react';
import { IconButton, Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

interface Category {
  [key: string]: string[];
}

interface ArrastarSoltarCategoriaEditorProps {
  title: string;
  question: string;
  categories: Category;
  onSave: (title: string, question: string, categories: Category) => void;
  onCancel: () => void;
}

const ArrastarSoltarCategoriaEditor: React.FC<ArrastarSoltarCategoriaEditorProps> = ({
  title: initialTitle,
  question: initialQuestion,
  categories: initialCategories,
  onSave,
  onCancel,
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [question, setQuestion] = useState(initialQuestion);
  const [categories, setCategories] = useState<Category>(initialCategories);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const [focusItemIndex, setFocusItemIndex] = useState<{ categoryIndex: number, itemIndex: number } | null>(null);
  const categoryRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());
  const itemRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());

  useEffect(() => {
    if (focusIndex !== null) {
      const ref = categoryRefs.current.get(focusIndex);
      if (ref) {
        ref.focus();
        setFocusIndex(null);
      }
    }
  }, [focusIndex]);

  useEffect(() => {
    if (focusItemIndex !== null) {
      const key = `${focusItemIndex.categoryIndex}-${focusItemIndex.itemIndex}`;
      const ref = itemRefs.current.get(key);
      if (ref) {
        ref.focus();
        setFocusItemIndex(null);
      }
    }
  }, [focusItemIndex]);

  const handleAddCategory = () => {
    const newCategoryName = '';
    const newCategories = { ...categories, [newCategoryName]: [''] };
    setCategories(newCategories);
    setFocusIndex(Object.keys(newCategories).length - 1);
  };

  const handleEditCategoryName = (oldName: string, newName: string) => {
    if (newName && newName !== oldName) {
      const updatedCategories = { ...categories };
      const items = updatedCategories[oldName];
      delete updatedCategories[oldName];
      updatedCategories[newName] = items;
      setCategories(updatedCategories);
    }
  };

  const handleRemoveCategory = (categoryName: string) => {
    const updatedCategories = { ...categories };
    delete updatedCategories[categoryName];
    setCategories(updatedCategories);
  };

  const handleAddItem = (categoryName: string, categoryIndex: number) => {
    const updatedCategories = { ...categories };
    updatedCategories[categoryName].push('');
    setCategories(updatedCategories);
    setFocusItemIndex({ categoryIndex, itemIndex: updatedCategories[categoryName].length - 1 });
  };

  const handleEditItem = (categoryName: string, itemIndex: number, newItem: string) => {
    const updatedCategories = { ...categories };
    updatedCategories[categoryName][itemIndex] = newItem;
    setCategories(updatedCategories);
  };

  const handleRemoveItem = (categoryName: string, itemIndex: number) => {
    const updatedCategories = { ...categories };
    updatedCategories[categoryName].splice(itemIndex, 1);
    setCategories(updatedCategories);
  };

  const handleSaveAll = () => {
    const filteredCategories = Object.fromEntries(Object.entries(categories).filter(([key]) => key.trim() !== ''));
    onSave(title, question, filteredCategories);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>, type: 'category' | 'item', categoryIndex: number, itemIndex?: number) => {
    const newValue = e.target.innerText.trim();
    if (type === 'category') {
      const oldCategoryName = Object.keys(categories)[categoryIndex];
      handleEditCategoryName(oldCategoryName, newValue);
    } else if (type === 'item' && itemIndex !== undefined) {
      const categoryName = Object.keys(categories)[categoryIndex];
      handleEditItem(categoryName, itemIndex, newValue);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, type: 'category' | 'item', categoryIndex: number, itemIndex?: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (type === 'category') {
        handleAddCategory();
      } else if (type === 'item' && itemIndex !== undefined) {
        const categoryName = Object.keys(categories)[categoryIndex];
        handleAddItem(categoryName, categoryIndex);
      }
    }
  };

  return (
    <div className="arrastar-soltar-categoria-editor">
      <h2>Editor de Arrastar Soltar Categoria</h2>
      <Box>
        <label>Título</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ width: '100%', padding: '8px', marginBottom: '16px' }}
        />
        <label>Questão</label>
        <input
          type="text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          style={{ width: '100%', padding: '8px', marginBottom: '16px' }}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleAddCategory}>
        Adicionar Categoria
      </Button>

      <h3>Categorias</h3>
      <ul>
        {Object.entries(categories).map(([category, items], index) => (
          <li key={index}>
            <Box display="flex" alignItems="center">
              <div
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => handleBlur(e, 'category', index)}
                onKeyPress={(e) => handleKeyPress(e, 'category', index)}
                ref={(el) => categoryRefs.current.set(index, el)}
                style={{ borderBottom: '1px dashed #ccc', cursor: 'text', padding: '5px', flexGrow: 1 }}
              >
                {category}
              </div>
              <IconButton onClick={() => handleRemoveCategory(category)}><DeleteIcon /></IconButton>
            </Box>
            <ul>
              {items.map((item, itemIndex) => (
                <li key={itemIndex}>
                  <Box display="flex" alignItems="center">
                    <div
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={(e) => handleBlur(e, 'item', index, itemIndex)}
                      onKeyPress={(e) => handleKeyPress(e, 'item', index, itemIndex)}
                      ref={(el) => itemRefs.current.set(`${index}-${itemIndex}`, el)}
                      style={{ borderBottom: '1px dashed #ccc', cursor: 'text', padding: '5px', flexGrow: 1 }}
                    >
                      {item}
                    </div>
                    <IconButton onClick={() => handleRemoveItem(category, itemIndex)}><DeleteIcon /></IconButton>
                  </Box>
                </li>
              ))}
              <li>
                <IconButton onClick={() => handleAddItem(category, index)}><AddIcon /></IconButton>
              </li>
            </ul>
          </li>
        ))}
      </ul>

      <Button variant="contained" color="primary" onClick={handleSaveAll}>
        Salvar Tudo
      </Button>
      <Button variant="contained" color="secondary" onClick={onCancel}>
        Cancelar
      </Button>
    </div>
  );
};

export default ArrastarSoltarCategoriaEditor;
