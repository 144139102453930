import React, { useState, useRef, useEffect } from 'react';
import { IconButton, Button, Box, Checkbox, FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface Phase {
  name: string;
  items: string[];
}

interface PreencherItemFasesEditorProps {
  title: string;
  phases: Phase[];
  considerarOrdem?: boolean;
  onSave: (title: string, phases: Phase[], considerarOrdem: boolean) => void;
  onCancel: () => void;
}

const PreencherItemFasesEditor: React.FC<PreencherItemFasesEditorProps> = ({
  title: initialTitle,
  phases: initialPhases,
  considerarOrdem: initialConsiderarOrdem = false,
  onSave,
  onCancel,
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [phases, setPhases] = useState<Phase[]>(initialPhases);
  const [considerarOrdem, setConsiderarOrdem] = useState(initialConsiderarOrdem);
  const [focusPhaseIndex, setFocusPhaseIndex] = useState<number | null>(null);
  const [focusItemIndex, setFocusItemIndex] = useState<{ phaseIndex: number, itemIndex: number } | null>(null);
  const phaseRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());
  const itemRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());

  useEffect(() => {
    if (focusPhaseIndex !== null) {
      const ref = phaseRefs.current.get(focusPhaseIndex);
      if (ref) {
        ref.focus();
        setFocusPhaseIndex(null);
      }
    }
  }, [focusPhaseIndex]);

  useEffect(() => {
    if (focusItemIndex !== null) {
      const key = `${focusItemIndex.phaseIndex}-${focusItemIndex.itemIndex}`;
      const ref = itemRefs.current.get(key);
      if (ref) {
        ref.focus();
        setFocusItemIndex(null);
      }
    }
  }, [focusItemIndex]);

  const handleAddPhase = () => {
    const newPhase: Phase = { name: '', items: [''] };
    setPhases([...phases, newPhase]);
    setFocusPhaseIndex(phases.length);
  };

  const handleEditPhaseName = (index: number, name: string) => {
    const updatedPhases = [...phases];
    updatedPhases[index].name = name;
    setPhases(updatedPhases);
  };

  const handleAddItem = (phaseIndex: number) => {
    const updatedPhases = [...phases];
    updatedPhases[phaseIndex].items.push('');
    setPhases(updatedPhases);
    setFocusItemIndex({ phaseIndex, itemIndex: updatedPhases[phaseIndex].items.length - 1 });
  };

  const handleEditItem = (phaseIndex: number, itemIndex: number, newItem: string) => {
    const updatedPhases = [...phases];
    updatedPhases[phaseIndex].items[itemIndex] = newItem;
    setPhases(updatedPhases);
  };

  const handleRemovePhase = (index: number) => {
    const updatedPhases = phases.filter((_, i) => i !== index);
    setPhases(updatedPhases);
  };

  const handleRemoveItem = (phaseIndex: number, itemIndex: number) => {
    const updatedPhases = [...phases];
    updatedPhases[phaseIndex].items.splice(itemIndex, 1);
    setPhases(updatedPhases);
  };

  const handleMovePhase = (index: number, direction: 'up' | 'down') => {
    const newPhases = [...phases];
    const [movedPhase] = newPhases.splice(index, 1);
    newPhases.splice(direction === 'up' ? index - 1 : index + 1, 0, movedPhase);
    setPhases(newPhases);
  };

  const handleSaveAll = () => {
    onSave(title, phases, considerarOrdem);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>, phaseIndex: number, itemIndex?: number) => {
    const newValue = e.target.innerText.trim();
    if (itemIndex !== undefined) {
      handleEditItem(phaseIndex, itemIndex, newValue);
    } else {
      handleEditPhaseName(phaseIndex, newValue);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, phaseIndex: number, itemIndex?: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (itemIndex !== undefined) {
        handleAddItem(phaseIndex);
      } else {
        handleAddPhase();
      }
    }
  };

  return (
    <div className="preencher-item-fases-editor">
      <h2>Editor de Preencher Item Fases</h2>
      <Box>
        <label>Título</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ width: '100%', padding: '8px', marginBottom: '16px' }}
        />
      </Box>
      <FormControlLabel
        control={<Checkbox checked={considerarOrdem} onChange={(e) => setConsiderarOrdem(e.target.checked)} />}
        label="Considerar Ordem dos Itens"
      />
      <Button variant="contained" color="primary" onClick={handleAddPhase}>
        Adicionar Fase
      </Button>

      <h3>Fases</h3>
      <ul>
        {phases.map((phase, phaseIndex) => (
          <li key={phaseIndex}>
            <Box display="flex" alignItems="center">
              <div
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => handleBlur(e, phaseIndex)}
                onKeyPress={(e) => handleKeyPress(e, phaseIndex)}
                ref={(el) => phaseRefs.current.set(phaseIndex, el)}
                style={{ borderBottom: '1px dashed #ccc', cursor: 'text', padding: '5px', flexGrow: 1 }}
              >
                {phase.name}
              </div>
              <IconButton onClick={() => handleRemovePhase(phaseIndex)}><DeleteIcon /></IconButton>
              <IconButton
                onClick={() => handleMovePhase(phaseIndex, 'up')}
                disabled={phaseIndex === 0}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => handleMovePhase(phaseIndex, 'down')}
                disabled={phaseIndex === phases.length - 1}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
            <ul>
              {phase.items.map((item, itemIndex) => (
                <li key={itemIndex}>
                  <Box display="flex" alignItems="center">
                    <div
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={(e) => handleBlur(e, phaseIndex, itemIndex)}
                      onKeyPress={(e) => handleKeyPress(e, phaseIndex, itemIndex)}
                      ref={(el) => itemRefs.current.set(`${phaseIndex}-${itemIndex}`, el)}
                      style={{ borderBottom: '1px dashed #ccc', cursor: 'text', padding: '5px', flexGrow: 1 }}
                    >
                      {item}
                    </div>
                    <IconButton onClick={() => handleRemoveItem(phaseIndex, itemIndex)}><DeleteIcon /></IconButton>
                  </Box>
                </li>
              ))}
              <li>
                <IconButton onClick={() => handleAddItem(phaseIndex)}><AddIcon /></IconButton>
              </li>
            </ul>
          </li>
        ))}
      </ul>

      <Button variant="contained" color="primary" onClick={handleSaveAll}>
        Salvar Tudo
      </Button>
      <Button variant="contained" color="secondary" onClick={onCancel}>
        Cancelar
      </Button>
    </div>
  );
};

export default PreencherItemFasesEditor;
