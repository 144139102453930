import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { FaQuestionCircle, FaInfoCircle, FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';
import './PerguntaRapida.css';

interface PerguntaRapidaProps {
  id: number;
  question: string;
  answer: string;
  type: 'question' | 'information' | 'warning' | 'error';
}

const PerguntaRapida: React.FC<PerguntaRapidaProps> = ({ id, question, answer, type }) => {

  const renderIcon = () => {
    switch (type) {
      case 'information':
        return <FaInfoCircle className="icon" style={{ color: 'white' }} />;
      case 'warning':
        return <FaExclamationTriangle className="icon" style={{ color: 'white' }} />;
      case 'error':
        return <FaTimesCircle className="icon" style={{ color: 'white' }} />;
      default:
        return <FaQuestionCircle className="icon" style={{ color: 'white' }} />;
    }
  };

  return (
    <Accordion className={`pergunta-rapida ${type}`}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
        className="pergunta-rapida-header"
      >
        <div className="pergunta-rapida-icon">{renderIcon()}</div>
        <Typography className="pergunta-rapida-question">{question}</Typography>
      </AccordionSummary>
      <AccordionDetails className="pergunta-rapida-answer">
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default PerguntaRapida;
