import React, { useState } from 'react';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

interface PerguntaRapidaEditorProps {
  initialQuestion?: string;
  initialAnswer?: string;
  initialType?: string;
  onSave: (question: string, answer: string, type: string, action: 'close' | 'addNew') => void;
  onCancel: () => void;
}

const PerguntaRapidaEditor: React.FC<PerguntaRapidaEditorProps> = ({
  initialQuestion = '',
  initialAnswer = '',
  initialType = 'question',
  onSave,
  onCancel,
}) => {
  const [question, setQuestion] = useState(initialQuestion);
  const [answer, setAnswer] = useState(initialAnswer);
  const [type, setType] = useState(initialType);

  const handleSave = (action: 'close' | 'addNew') => {
    onSave(question, answer, type, action);
    if (action === 'addNew') {
      setQuestion('');
      setAnswer('');
      setType('question');
    }
  };

  return (
    <div className="PerguntaRapidaEditor-container">
      <TextField
        label="Pergunta"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Resposta"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Tipo</InputLabel>
        <Select value={type} onChange={(e) => setType(e.target.value)}>
          <MenuItem value="question">Pergunta</MenuItem>
          <MenuItem value="information">Informação</MenuItem>
          <MenuItem value="warning">Aviso</MenuItem>
          <MenuItem value="error">Erro</MenuItem>
        </Select>
      </FormControl>
      <div className="PerguntaRapidaEditor-buttons">
        <Button variant="contained" color="primary" onClick={() => handleSave('close')}>
          Salvar e Fechar
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleSave('addNew')}>
          Salvar e Adicionar Novo
        </Button>
        <Button variant="contained" onClick={onCancel}>
          Cancelar
        </Button>
      </div>
    </div>
  );
};

export default PerguntaRapidaEditor;
