import React, { useState } from 'react';
import {
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Card,
  CardContent,
  Typography,
  Checkbox,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

interface TermoDefinicao {
  termo: string;
  definicao: string;
  comentario: string;
}

interface TermoDefinicaoEditorProps {
  onSave: (
    termosDefinicoes: TermoDefinicao[],
    tiposComponentes: string[],
    termoComo: 'pergunta' | 'resposta' | 'ambos',
    flipOrReveal?: 'flip' | 'reveal'
  ) => void;
  onCancel: () => void;
}

const TermoDefinicaoEditor: React.FC<TermoDefinicaoEditorProps> = ({ onSave, onCancel }) => {
  const [termosDefinicoes, setTermosDefinicoes] = useState<TermoDefinicao[]>([
    { termo: '', definicao: '', comentario: '' },
    { termo: '', definicao: '', comentario: '' },
    { termo: '', definicao: '', comentario: '' },
  ]);
  const [tiposComponentes, setTiposComponentes] = useState<string[]>(['PerguntaDigitado']);
  const [termoComo, setTermoComo] = useState<'pergunta' | 'resposta' | 'ambos'>('pergunta');
  const [flipOrReveal, setFlipOrReveal] = useState<'flip' | 'reveal'>('flip');

  const handleTermoDefinicaoChange = (index: number, field: 'termo' | 'definicao' | 'comentario', value: string) => {
    const newTermosDefinicoes = [...termosDefinicoes];
    newTermosDefinicoes[index][field] = value;
    setTermosDefinicoes(newTermosDefinicoes);
  };

  const handleAddTermoDefinicao = () => {
    setTermosDefinicoes([...termosDefinicoes, { termo: '', definicao: '', comentario: '' }]);
  };

  const handleRemoveTermoDefinicao = (index: number) => {
    const newTermosDefinicoes = termosDefinicoes.filter((_, i) => i !== index);
    setTermosDefinicoes(newTermosDefinicoes);
  };

  const handleSave = () => {
    onSave(termosDefinicoes, tiposComponentes, termoComo, tiposComponentes.includes('Card') ? flipOrReveal : undefined);
  };

  const handleTipoComponenteChange = (tipo: string) => {
    setTiposComponentes((prev) =>
      prev.includes(tipo) ? prev.filter((item) => item !== tipo) : [...prev, tipo]
    );
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          Editor de Termos e Definições
        </Typography>
        {termosDefinicoes.map((td, index) => (
          <div key={index} style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
            <TextField
              label={`Termo ${index + 1}`}
              value={td.termo}
              onChange={(e) => handleTermoDefinicaoChange(index, 'termo', e.target.value)}
              fullWidth
              margin="normal"
              style={{ marginRight: 10 }}
            />
            <TextField
              label={`Definição ${index + 1}`}
              value={td.definicao}
              onChange={(e) => handleTermoDefinicaoChange(index, 'definicao', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label={`Comentário ${index + 1}`}
              value={td.comentario}
              onChange={(e) => handleTermoDefinicaoChange(index, 'comentario', e.target.value)}
              fullWidth
              margin="normal"
            />
            <IconButton color="secondary" onClick={() => handleRemoveTermoDefinicao(index)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <Button variant="contained" color="primary" onClick={handleAddTermoDefinicao} startIcon={<AddIcon />}>
          Adicionar Termo/Definição
        </Button>
        <FormControl component="fieldset" style={{ marginTop: 20 }}>
          <Typography variant="subtitle1">Tipos de Componentes</Typography>
          <FormControlLabel
            control={<Checkbox checked={tiposComponentes.includes('PerguntaDigitado')} onChange={() => handleTipoComponenteChange('PerguntaDigitado')} />}
            label="Pergunta Digitada"
          />
          <FormControlLabel
            control={<Checkbox checked={tiposComponentes.includes('Card')} onChange={() => handleTipoComponenteChange('Card')} />}
            label="Card"
          />
          <FormControlLabel
            control={<Checkbox checked={tiposComponentes.includes('PerguntaMultiplaEscolha')} onChange={() => handleTipoComponenteChange('PerguntaMultiplaEscolha')} />}
            label="Pergunta Multipla Escolha"
          />
        </FormControl>
        {tiposComponentes.includes('Card') && (
          <FormControl component="fieldset" style={{ marginTop: 10 }}>
            <Typography variant="subtitle1">Modo de Exibição do Card</Typography>
            <RadioGroup
              row
              value={flipOrReveal}
              onChange={(e) => setFlipOrReveal(e.target.value as 'flip' | 'reveal')}
            >
              <FormControlLabel value="flip" control={<Radio />} label="Flip" />
              <FormControlLabel value="reveal" control={<Radio />} label="Reveal" />
            </RadioGroup>
          </FormControl>
        )}
        <FormControl component="fieldset" style={{ marginTop: 20 }}>
          <Typography variant="subtitle1">Termos Como</Typography>
          <RadioGroup
            row
            value={termoComo}
            onChange={(e) => setTermoComo(e.target.value as 'pergunta' | 'resposta' | 'ambos')}
          >
            <FormControlLabel value="pergunta" control={<Radio />} label="Pergunta" />
            <FormControlLabel value="resposta" control={<Radio />} label="Resposta" />
            <FormControlLabel value="ambos" control={<Radio />} label="Ambos" />
          </RadioGroup>
        </FormControl>
        <div style={{ marginTop: 20 }}>
          <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
            Salvar
          </Button>
          <Button variant="contained" onClick={onCancel} startIcon={<CancelIcon />} style={{ marginLeft: 10 }}>
            Cancelar
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default TermoDefinicaoEditor;
