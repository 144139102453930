import React, { useState, useEffect, useRef } from 'react';
import { Button, IconButton, Box, TextField, FormControlLabel, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface TimeLinePhase {
  name: string;
  order: number;
  items: string[];
}

interface TimeLineEditorProps {
  title?: string;
  phases: TimeLinePhase[];
  considerarOrdem?: boolean;
  onSave: (title: string, phases: TimeLinePhase[], considerarOrdem: boolean) => void;
  onCancel: () => void;
}

const TimeLineEditor: React.FC<TimeLineEditorProps> = ({ title: initialTitle = '', phases: initialPhases, considerarOrdem: initialConsiderarOrdem = false, onSave, onCancel }) => {
  const [title, setTitle] = useState<string>(initialTitle);
  const [phases, setPhases] = useState<TimeLinePhase[]>(initialPhases);
  const [considerarOrdem, setConsiderarOrdem] = useState<boolean>(initialConsiderarOrdem);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const [focusPhaseIndex, setFocusPhaseIndex] = useState<number | null>(null);
  const itemRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());
  const phaseRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());

  useEffect(() => {
    if (focusIndex !== null) {
      const ref = itemRefs.current.get(focusIndex);
      if (ref) {
        ref.focus();
        setFocusIndex(null);
      }
    }
  }, [focusIndex]);

  useEffect(() => {
    if (focusPhaseIndex !== null) {
      const ref = phaseRefs.current.get(focusPhaseIndex);
      if (ref) {
        ref.focus();
        setFocusPhaseIndex(null);
      }
    }
  }, [focusPhaseIndex]);

  const handleAddPhase = () => {
    const newPhase: TimeLinePhase = { name: '', order: phases.length + 1, items: [''] };
    setPhases([...phases, newPhase]);
    setFocusPhaseIndex(phases.length);
  };

  const handleEditPhaseName = (index: number, name: string) => {
    const updatedPhases = [...phases];
    updatedPhases[index].name = name;
    setPhases(updatedPhases);
  };

  const handleAddItem = (phaseIndex: number) => {
    const newItem: string = '';
    const updatedPhases = [...phases];
    updatedPhases[phaseIndex].items.push(newItem);
    setPhases(updatedPhases);
    setFocusIndex(updatedPhases[phaseIndex].items.length - 1);
  };

  const handleEditItemName = (phaseIndex: number, itemIndex: number, name: string) => {
    const updatedPhases = [...phases];
    updatedPhases[phaseIndex].items[itemIndex] = name;
    setPhases(updatedPhases);
  };

  const handleRemovePhase = (index: number) => {
    const newPhases = phases.filter((_, i) => i !== index);
    setPhases(newPhases);
  };

  const handleRemoveItem = (phaseIndex: number, itemIndex: number) => {
    const updatedPhases = [...phases];
    updatedPhases[phaseIndex].items.splice(itemIndex, 1);
    setPhases(updatedPhases);
  };

  const handleMovePhase = (index: number, direction: 'up' | 'down') => {
    const newPhases = [...phases];
    const [movedPhase] = newPhases.splice(index, 1);
    newPhases.splice(direction === 'up' ? index - 1 : index + 1, 0, movedPhase);
    setPhases(newPhases);
  };

  const handleSaveAll = () => {
    const updatedPhases = phases.map((phase, index) => ({ ...phase, order: index + 1 }));
    onSave(title, updatedPhases, considerarOrdem);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>, phaseIndex: number, itemIndex?: number) => {
    const newValue = e.target.innerText.trim();
    if (itemIndex !== undefined) {
      handleEditItemName(phaseIndex, itemIndex, newValue);
    } else {
      handleEditPhaseName(phaseIndex, newValue);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, phaseIndex: number, itemIndex?: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (itemIndex !== undefined) {
        handleAddItem(phaseIndex);
      } else {
        handleAddPhase();
      }
    }
  };

  return (
    <div className="timeline-editor">
      <h2>TimeLine Editor</h2>
      <TextField
        label="Título"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleAddPhase}>
        Adicionar Fase
      </Button>
      <FormControlLabel
        label="Considerar ordem dos itens"
        control={
          <Checkbox
            checked={considerarOrdem}
            onChange={(e) => setConsiderarOrdem(e.target.checked)}
            color="primary"
          />
        }
      />
      <h3>Fases</h3>
      <ul>
        {phases.map((phase, phaseIndex) => (
          <li key={phaseIndex}>
            <Box display="flex" alignItems="center">
              <div
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => handleBlur(e, phaseIndex)}
                onKeyPress={(e) => handleKeyPress(e, phaseIndex)}
                ref={(el) => phaseRefs.current.set(phaseIndex, el)}
                style={{ borderBottom: '1px dashed #ccc', cursor: 'text', padding: '5px', flexGrow: 1 }}
              >
                {phase.name}
              </div>
              <IconButton onClick={() => handleRemovePhase(phaseIndex)}><DeleteIcon /></IconButton>
              <IconButton
                onClick={() => handleMovePhase(phaseIndex, 'up')}
                disabled={phaseIndex === 0}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => handleMovePhase(phaseIndex, 'down')}
                disabled={phaseIndex === phases.length - 1}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
            <ul>
              {phase.items.map((item, itemIndex) => (
                <li key={itemIndex}>
                  <Box display="flex" alignItems="center">
                    <div
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={(e) => handleBlur(e, phaseIndex, itemIndex)}
                      onKeyPress={(e) => handleKeyPress(e, phaseIndex, itemIndex)}
                      ref={(el) => itemRefs.current.set(itemIndex, el)}
                      style={{ borderBottom: '1px dashed #ccc', cursor: 'text', padding: '5px', flexGrow: 1 }}
                    >
                      {item}
                    </div>
                    <IconButton onClick={() => handleRemoveItem(phaseIndex, itemIndex)}><DeleteIcon /></IconButton>
                  </Box>
                </li>
              ))}
              <li>
                <IconButton onClick={() => handleAddItem(phaseIndex)}><AddIcon /></IconButton>
              </li>
            </ul>
          </li>
        ))}
      </ul>

      <Button variant="contained" color="primary" onClick={handleSaveAll}>
        Salvar Tudo
      </Button>
      <Button variant="contained" color="secondary" onClick={onCancel}>
        Cancelar
      </Button>
    </div>
  );
};

export default TimeLineEditor;
