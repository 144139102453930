import React, { useState, useRef, useEffect } from 'react';
import './ImageOcclusionEditor.css';
import { Button, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel, TextField } from '@mui/material';
import ImageUploader from './ImageUploader';
import { Occlusion } from '../../../types';

interface ImageOcclusionEditorProps {
  initialData: {
    imageUrl: string;
    occlusions: Occlusion[];
    imageWidth?: number;
    imageHeight?: number;
    mode?: 'digitada' | 'clicada';
    withText?: boolean;
    title?: string;
  };
  onSave: (data: { imageUrl: string; occlusions: Occlusion[], imageWidth: number, imageHeight: number, mode: 'digitada' | 'clicada', withText: boolean, title: string }) => void;
  onCancel: () => void;
}

const ImageOcclusionEditor: React.FC<ImageOcclusionEditorProps> = ({ initialData, onSave, onCancel }) => {
  const [imageUrl, setImageUrl] = useState(initialData.imageUrl);
  const [localOcclusions, setLocalOcclusions] = useState<Occlusion[]>(initialData.occlusions);
  const [newOcclusion, setNewOcclusion] = useState<Occlusion | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [mode, setMode] = useState<'digitada' | 'clicada'>(initialData.mode || 'digitada');
  const [withText, setWithText] = useState(initialData.withText ?? true);
  const [imageDimensions, setImageDimensions] = useState<{ width: number, height: number }>({ width: initialData.imageWidth || 800, height: initialData.imageHeight || 600 });
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [title, setTitle] = useState<string>(initialData.title);


  useEffect(() => {
    const img = imageRef.current;
    if (img) {
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    }
  }, [imageUrl]);

  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      if (e.clipboardData) {
        const items = e.clipboardData.items;
        for (const item of items) {
          if (item.type.startsWith('image/')) {
            const file = item.getAsFile();
            if (file) {
              const reader = new FileReader();
              reader.onload = (event) => {
                const result = event.target?.result as string;
                setImageUrl(result);
              };
              reader.readAsDataURL(file);
            }
          }
        }
      }
    };

    

    const handlePasteEvent = handlePaste as EventListener;

    window.addEventListener('paste', handlePasteEvent);
    return () => {
      window.removeEventListener('paste', handlePasteEvent);
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const startX = e.clientX - rect.left;
    const startY = e.clientY - rect.top;
    setNewOcclusion({ top: startY, left: startX, width: 0, height: 0, label: '' });
    setIsDrawing(true);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing || !newOcclusion) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const endX = e.clientX - rect.left;
    const endY = e.clientY - rect.top;
    setNewOcclusion((prev) => ({
      ...prev!,
      width: endX - prev!.left,
      height: endY - prev!.top,
    }));
  };

  const handleMouseUp = () => {
    if (newOcclusion && !withText) {
      setLocalOcclusions((prev) => [...prev, newOcclusion]);
      setNewOcclusion(null);
    }
    setIsDrawing(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewOcclusion((prev) => ({
      ...prev!,
      label: value,
    }));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newOcclusion) {
      if (newOcclusion.label.trim() !== '' || !withText) {
        setLocalOcclusions((prev) => [...prev, newOcclusion]);
        setNewOcclusion(null);
      }
    }
  };

  const saveOcclusions = () => {
    onSave({ imageUrl, occlusions: localOcclusions, imageWidth: imageDimensions.width, imageHeight: imageDimensions.height, mode, withText, title });
  };

  const clearOcclusions = () => {
    setLocalOcclusions([]);
  };

  const editOcclusion = (index: number) => {
    setNewOcclusion(localOcclusions[index]);
    setLocalOcclusions(localOcclusions.filter((_, i) => i !== index));
  };

  return (
    <div>
      <TextField
        label="Título"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <ImageUploader onUpload={(uploadedImageUrl) => setImageUrl(uploadedImageUrl)} onSaveData={() => {}} />
      <FormControl fullWidth style={{ margin: '10px 0' }}>
        <InputLabel>Modo</InputLabel>
        <Select value={mode} onChange={(e) => setMode(e.target.value as 'digitada' | 'clicada')}>
          <MenuItem value="digitada">Digitada</MenuItem>
          <MenuItem value="clicada">Clicada</MenuItem>
        </Select>
      </FormControl>
      {mode === 'clicada' && (
        <FormControlLabel
          control={<Checkbox checked={withText} onChange={(e) => setWithText(e.target.checked)} />}
          label="Inserção de texto"
        />
      )}
      <div className="ImageOcclusionEditor-container" style={{ position: 'relative' }}>
        <img ref={imageRef} src={imageUrl} alt="To be occluded" className="ImageOcclusionEditor-image" />
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          className="ImageOcclusionEditor-canvas"
        />
        {localOcclusions.map((occlusion, index) => (
          <div
            key={index}
            className="ImageOcclusionEditor-occlusion"
            style={{
              position: 'absolute',
              top: `${occlusion.top}px`,
              left: `${occlusion.left}px`,
              width: `${occlusion.width}px`,
              height: `${occlusion.height}px`,
              backgroundColor: withText ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 128, 0, 0.5)',
            }}
            onDoubleClick={() => editOcclusion(index)}
          >
            {withText && <span>{occlusion.label}</span>}
          </div>
        ))}
        {newOcclusion && (
          <div
            className="ImageOcclusionEditor-occlusion"
            style={{
              position: 'absolute',
              top: `${newOcclusion.top}px`,
              left: `${newOcclusion.left}px`,
              width: `${newOcclusion.width}px`,
              height: `${newOcclusion.height}px`,
              backgroundColor: withText ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 128, 0, 0.5)',
            }}
          >
            {withText && (
              <input
                type="text"
                value={newOcclusion.label}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder="Label"
                className="ImageOcclusionEditor-input"
              />
            )}
          </div>
        )}
      </div>
      <Button variant="contained" color="primary" onClick={saveOcclusions}>Salvar Occlusions</Button>
      <Button variant="contained" color="secondary" onClick={clearOcclusions}>Limpar Occlusions</Button>
      <Button variant="contained" onClick={onCancel}>Cancelar</Button>
    </div>
  );
};

export default ImageOcclusionEditor;
