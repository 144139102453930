import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api';
import CompleteTexto from '../components/organisms/CompleteTexto/CompleteTexto';
import ImageOcclusion from '../components/organisms/ImageOcclusion/ImageOcclusion';
import ImageOcclusionPorClique from '../components/organisms/ImageOcclusionPorClique/ImageOcclusionPorClique';
import PerguntaRapida from '../components/organisms/PerguntaRapida/PerguntaRapida';
import SequencingGame from '../components/organisms/SequencingGame/SequencingGame';
import PreencherQuadro from '../components/organisms/PreencherQuadro/PreencherQuadro';
import PreencherItemCategoria from '../components/organisms/PreencherItemCategoria/PreencherItemCategoria';
import ArrastarSoltarCategoria from '../components/organisms/ArrastarSoltarCategoria/ArrastarSoltarCategoria';
import Ordenacao from '../components/organisms/Ordenacao/Ordenacao';
import PerguntaDigitado from '../components/organisms/PerguntaDigitado/PerguntaDigitado';
import PerguntaMultiplaEscolha from '../components/organisms/PerguntaMultiplaEscolha/PerguntaMultiplaEscolha';
import FlashCard from '../components/organisms/FlashCard/FlashCard';
import QuizMultiploImediato from '../components/organisms/QuizMultiploImediato/QuizMultiploImediato';
import DecisionTreeComic from '../components/organisms/DecisionTreeComic/DecisionTreeComic';
import Texto from '../components/organisms/Texto/Texto';
import ExcalidrawViewer from '../components/organisms/ExcalidrawViewer/ExcalidrawViewer';
import TreinoFlashCard from '../components/organisms/treino/TreinoFlashCard/TreinoFlashCard';
import TreinoTermoDefinicao from '../components/organisms/treino/TreinoTermoDefinicao/TreinoTermoDefinicao';
import FlipCard from '../components/organisms/FlipCard/FlipCard';
import RevealCard from '../components/organisms/RevealCard/RevealCard';
import PreencherItemFases from '../components/organisms/PreencherItemFases/PreencherItemFases';
import TimeLine from '../components/organisms/TimeLine/TimeLine';
import { Phase, Activity } from '../types';

interface Componente {
  id: number;
  type: string;
  dados: any;
}

const ComponenteViewer: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Pegando o ID do componente via parâmetros da rota
  const [componente, setComponente] = useState<Componente | null>(null);

  // Função para buscar os dados do componente
  useEffect(() => {
    const fetchComponente = async () => {
      try {
        const response = await api.get(`/componentes/${id}`);
        setComponente(response.data);
      } catch (error) {
        console.error('Erro ao buscar componente:', error);
      }
    };
    fetchComponente();
  }, [id]);

  // Função que renderiza o componente com base no seu tipo
  const renderComponente = () => {
    if (!componente) {
      return <p>Carregando...</p>;
    }

    const { dados } = componente;

    switch (componente.type) {
      case 'CompleteTexto':
        return <CompleteTexto id={componente.id} title={dados.title || ''} texto={dados.texto || ''} />;
      case 'ImageOcclusion':
        return dados.mode === 'digitada' ? (
          <ImageOcclusion data={dados} />
        ) : (
          <ImageOcclusionPorClique data={dados} />
        );
      case 'PerguntaRapida':
        return <PerguntaRapida id={componente.id} question={dados.question || ''} answer={dados.answer || ''} type={dados.type || 'question'} />;
      case 'SequencingGame':
        return (
          <SequencingGame
            data={{
              title: dados.title,
              phases: dados.phases as Phase[],
              activities: dados.activities as Activity[],
              considerarOrdem: dados.considerarOrdem,
            }}
          />
        );
      case 'PreencherQuadro':
        return <PreencherQuadro data={dados} />;
      case 'PreencherItemCategoria':
        return <PreencherItemCategoria data={dados} />;
      case 'ArrastarSoltarCategoria':
        return <ArrastarSoltarCategoria data={dados} />;
      case 'Ordenacao':
        return <Ordenacao data={dados} />;
      case 'PerguntaDigitado':
        return <PerguntaDigitado id={componente.id} pergunta={dados.question} respostaCorreta={dados.respostaCorreta} comment={dados.comment} />;
      case 'PerguntaMultiplaEscolha':
        return <PerguntaMultiplaEscolha question={dados.question} options={dados.options} questionType={dados.questionType} comment={dados.comment} />;
      case 'FlashCard':
        return <FlashCard title={dados.title} flashcardData={dados.flashcardData} />;
      case 'QuizMultiploImediato':
        return <QuizMultiploImediato data={dados.questionData} />;
      case 'DecisionTreeComic':
        return <DecisionTreeComic data={dados} />;
      case 'Texto':
        return <Texto html={dados.html} />;
      case 'Excalidraw':
        return <ExcalidrawViewer elements={dados.elements} appState={dados.appState} />;
      case 'TreinoFlashCard':
        return (
          <TreinoFlashCard
            cards={dados.treinoFlashCardsData}
            onComplete={(result) => {
              console.log('Resultado do treino:', result);
            }}
          />
        );
      case 'TreinoTermoDefinicao':
        return <TreinoTermoDefinicao dados={dados} />;
      case 'FlipCard':
        return <FlipCard id={componente.id} front={dados.front} back={dados.back} />;
      case 'RevealCard':
        return <RevealCard id={componente.id} front={dados.front} back={dados.back} comment={dados.comment} />;
      case 'PreencherItemFases':
        return <PreencherItemFases data={dados} />;
      case 'TimeLine':
        return <TimeLine data={dados} />;
      default:
        return <p>Componente não suportado</p>;
    }
  };

  return (
    <div>
      {renderComponente()}
    </div>
  );
};

export default ComponenteViewer;
