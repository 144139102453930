import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Tooltip, Button } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import ReplayIcon from '@mui/icons-material/Replay';
import './TreinoFlashCard.css';

interface FlashCard {
  question: string;
  answer: string;
  comment?: string;
}

interface TreinoFlashCardProps {
  cards: FlashCard[];
  onComplete: (result: { easy: number; difficult: number; wrong: number }) => void;
}

const TreinoFlashCard: React.FC<TreinoFlashCardProps> = ({ cards, onComplete }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [results, setResults] = useState({ easy: 0, difficult: 0, wrong: 0 });
  const [showComment, setShowComment] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [allCards, setAllCards] = useState([...cards]);

  const handleToggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  const handleResponse = (response: 'easy' | 'difficult' | 'wrong') => {
    setResults((prev) => ({
      ...prev,
      [response]: prev[response] + 1,
    }));

    if (response === 'easy') {
      allCards.splice(currentIndex, 1);
    } else {
      const card = allCards.splice(currentIndex, 1)[0];
      allCards.push(card);
    }

    if (allCards.length === 0) {
      setCompleted(true);
      onComplete(results);
    } else {
      setShowAnswer(false);
      setShowComment(false);
      setCurrentIndex(0);
    }
  };

  const handleRestart = () => {
    setResults({ easy: 0, difficult: 0, wrong: 0 });
    setCompleted(false);
    setCurrentIndex(0);
    setShowAnswer(false);
    setShowComment(false);
    setAllCards([...cards]);
  };

  const handleToggleComment = () => {
    setShowComment(!showComment);
  };

  if (completed) {
    return (
      <Card className="TreinoFlashCard-card">
        <CardContent>
          <Typography variant="h5" component="div">
            Parabéns! Você completou os cards.
          </Typography>
          <Typography variant="body1">
            Fácil: {results.easy}
          </Typography>
          <Typography variant="body1">
            Difícil: {results.difficult}
          </Typography>
          <Typography variant="body1">
            Erros: {results.wrong}
          </Typography>
          <IconButton onClick={handleRestart} color="primary">
            <ReplayIcon />
          </IconButton>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={`TreinoFlashCard-card ${showAnswer ? 'TreinoFlashCard-answer-face' : 'TreinoFlashCard-question-face'}`}>
      <CardContent>
        <div className="TreinoFlashCard-main-content" onClick={handleToggleAnswer}>
          <Typography
            variant="h5"
            component="div"
            className="TreinoFlashCard-question"
            dangerouslySetInnerHTML={{ __html: allCards[currentIndex]?.question || '' }}
          />
          {showAnswer && (
            <Typography
              variant="body1"
              className="TreinoFlashCard-answer"
              dangerouslySetInnerHTML={{ __html: allCards[currentIndex]?.answer || '' }}
            />
          )}
        </div>
        {showAnswer && (
          <div className="TreinoFlashCard-response-buttons">
            <Tooltip title="Ver Comentário">
              <IconButton color="default" onClick={handleToggleComment}>
                <CommentIcon />
              </IconButton>
            </Tooltip>
            <Button onClick={() => handleResponse('wrong')} variant="contained" color="secondary">
              Errei
            </Button>
            <Button onClick={() => handleResponse('difficult')} variant="contained" color="info">
              Difícil
            </Button>
            <Button onClick={() => handleResponse('easy')} variant="contained" color="primary">
              Fácil
            </Button>
          </div>
        )}
        {showComment && allCards[currentIndex]?.comment && (
          <Typography
            variant="body1"
            className="TreinoFlashCard-comment"
            dangerouslySetInnerHTML={{ __html: allCards[currentIndex]?.comment || '' }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default TreinoFlashCard;
