import React from 'react';
import './FlipCard.css';
import { parseContent } from '../../../helpers/parser';
import { Container } from '../../../helpers/StyledComponents';

interface FlipCardProps {
  id: number;
  front: string;
  back: string;
}

const FlipCard: React.FC<FlipCardProps> = ({ id, front, back }) => {
  const [flipped, setFlipped] = React.useState(false);
  const [frontContent, setFrontContent] = React.useState<React.ReactNode[]>([]);
  const [backContent, setBackContent] = React.useState<React.ReactNode[]>([]);

  React.useEffect(() => {
    const parseFrontContent = async () => {
      const parsedContent = await parseContent(front);
      setFrontContent(parsedContent);
    };
    const parseBackContent = async () => {
      const parsedContent = await parseContent(back);
      setBackContent(parsedContent);
    };

    parseFrontContent();
    parseBackContent();
  }, [front, back]);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <div className={`flip-card ${flipped ? 'flipped' : ''}`} onClick={handleFlip}>
      <Container className="flip-card-front">{frontContent}</Container>
      <Container className="flip-card-back">{backContent}</Container>
    </div>
  );
};

export default FlipCard;
