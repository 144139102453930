import React, { useState, useEffect } from 'react';
import './TreinoTermoDefinicao.css';
import FlashCardTreino from './FlashCardTreino/FlashCardTreino';
import PerguntaMultiplaEscolhaTreino from './PerguntaMultiplaEscolhaTreino/PerguntaMultiplaEscolhaTreino';
import PerguntaDigitadaTreino from './PerguntaDigitadaTreino/PerguntaDigitadaTreino';
import { shuffleArray, Queue } from '../../../../utils/utils';

interface TermoDefinicao {
  termo: string;
  definicao: string;
  comentario: string;
}

interface PerguntaMultiplaEscolha {
  question: string;
  options: { text: string; correct: boolean }[];
  comment: string;
}

interface PerguntaDigitada {
  question: string;
  answer: string;
  comment: string;
}

interface TreinoTermoDefinicaoProps {
  dados: {
    assunto: string;
    termosDefinicoes: TermoDefinicao[];
    tiposComponentes: string[];
    termoComo: 'pergunta' | 'resposta' | 'ambos';
  };
}

const TreinoTermoDefinicao: React.FC<TreinoTermoDefinicaoProps> = ({ dados }) => {
  const [nivel, setNivel] = useState<number>(0);
  const [flashCardQueue, setFlashCardQueue] = useState<Queue<TermoDefinicao>>(new Queue());
  const [multiplaEscolhaQueue, setMultiplaEscolhaQueue] = useState<Queue<PerguntaMultiplaEscolha>>(new Queue());
  const [perguntasDigitadasQueue, setPerguntasDigitadasQueue] = useState<Queue<PerguntaDigitada>>(new Queue());
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [errosNivel, setErrosNivel] = useState<number>(0);
  const [acertosNivel, setAcertosNivel] = useState<number>(0);
  const [erros, setErros] = useState<number>(0);
  const [acertos, setAcertos] = useState<number>(0);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [errouME, setErrouME] = useState<boolean>(false);
  const [currentMultiplaEscolha, setCurrentMultiplaEscolha] = useState<PerguntaMultiplaEscolha | null>(null);


  useEffect(() => {
    const flashCardQueue = new Queue<TermoDefinicao>();
    const multiplaEscolhaQueue = new Queue<PerguntaMultiplaEscolha>();
    const perguntasDigitadasQueue = new Queue<PerguntaDigitada>();

    dados.termosDefinicoes.forEach(td => {
      flashCardQueue.enqueue(td);

      const options = shuffleArray(
        dados.termosDefinicoes
          .filter(opcao => opcao.termo !== td.termo)
          .map(opcao => ({
            text: dados.termoComo === 'pergunta' || dados.termoComo === 'ambos' ? opcao.definicao : opcao.termo,
            correct: false,
          }))
      ).slice(0, 3);

      const correctOption = {
        text: dados.termoComo === 'pergunta' || dados.termoComo === 'ambos' ? td.definicao : td.termo,
        correct: true,
      };

      const allOptions = shuffleArray([...options, correctOption]);

      multiplaEscolhaQueue.enqueue({
        question: dados.termoComo === 'pergunta' || dados.termoComo === 'ambos' ? td.termo : td.definicao,
        options: allOptions,
        comment: td.comentario,
      });

      perguntasDigitadasQueue.enqueue({
        question: td.definicao,
        answer: td.termo,
        comment: td.comentario,
      });
    });

    setFlashCardQueue(flashCardQueue);
    setMultiplaEscolhaQueue(multiplaEscolhaQueue);
    setPerguntasDigitadasQueue(perguntasDigitadasQueue);
    setCurrentMultiplaEscolha(multiplaEscolhaQueue.peek() || null);
  }, [dados]);

  const handleAcertou = () => {
    setAcertos(acertos + 1);
    setAcertosNivel(acertosNivel + 1);
  };

  const handleErrou = () => {
    setErros(erros + 1);
    setErrosNivel(errosNivel + 1);
  };

  const handleProximoNivel = () => {
    setNivel(nivel + 1);
    setCurrentQuestionIndex(0);
    setAcertosNivel(0);
    setErrosNivel(0);
  };

  const handleProximaPergunta = () => {
    setShowFeedback(false);
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handleAcertouFlashCard = () => {
    handleAcertou();
    flashCardQueue.dequeue();
    handleProximaPergunta();
  };

  const handleErrouFlashCard = () => {
    handleErrou();
    flashCardQueue.enqueue(flashCardQueue.dequeue()!);
    handleProximaPergunta();
  };

  const handleAcertouMultiplaEscolha = () => {
    handleAcertou();
    setShowFeedback(true);
    setErrouME(false);
  };

  const handleErrouMultiplaEscolha = () => {
    handleErrou();
    setShowFeedback(true);
    setErrouME(true);
  };

  const handleProximaPerguntaMultiplaEscolha = () => {
    const current = multiplaEscolhaQueue.dequeue();
    if (current && errouME) {
      multiplaEscolhaQueue.enqueue(current);
    }
    setCurrentMultiplaEscolha(multiplaEscolhaQueue.peek() || null);
    setShowFeedback(false);
    handleProximaPergunta();
  };

  const handleAcertouPerguntaDigitada = () => {
    handleAcertou();
    perguntasDigitadasQueue.dequeue();
    handleProximaPergunta();
  };

  const handleErrouPerguntaDigitada = () => {
    handleErrou();
    perguntasDigitadasQueue.enqueue(perguntasDigitadasQueue.dequeue()!);
    handleProximaPergunta();
  };

  if (nivel === 0) {
    return (
      <div className="TreinoTermoDefinicao-container">
        <div className="TreinoTermoDefinicao-start">
          <h1>Vamos treinar agora sobre o assunto {dados.assunto}</h1>
          <button onClick={handleProximoNivel}>Iniciar</button>
        </div>
      </div>
    );
  }

  if (nivel === 1) {
    return (
      <div className="TreinoTermoDefinicao-container">
        <h2>Nível 1 - FlashCards</h2>
        {flashCardQueue.size() > 0 ? (
          <FlashCardTreino
            key={currentQuestionIndex}
            id={currentQuestionIndex}
            front={dados.termoComo === 'pergunta' || dados.termoComo === 'ambos' ? flashCardQueue.peek()!.termo : flashCardQueue.peek()!.definicao}
            back={dados.termoComo === 'pergunta' || dados.termoComo === 'ambos' ? flashCardQueue.peek()!.definicao : flashCardQueue.peek()!.termo}
            comment={flashCardQueue.peek()!.comentario}
            onAcertou={handleAcertouFlashCard}
            onErrou={handleErrouFlashCard}
          />
        ) : (
          <div>
            <p>Acertos: {acertosNivel}</p>
            <p>Erros: {errosNivel}</p>
            <button onClick={handleProximoNivel}>Próximo Nível</button>
          </div>
        )}
      </div>
    );
  }

  if (nivel === 2) {
    return (
      <div className="TreinoTermoDefinicao-container">
        <h2>Nível 2 - Múltipla Escolha</h2>
        {multiplaEscolhaQueue.size() > 0 ? (
          <PerguntaMultiplaEscolhaTreino
            key={currentQuestionIndex}
            question={currentMultiplaEscolha!.question}
            options={currentMultiplaEscolha!.options}
            comment={currentMultiplaEscolha!.comment}
            onAcertou={handleAcertouMultiplaEscolha}
            onErrou={handleErrouMultiplaEscolha}
            onProxima={handleProximaPerguntaMultiplaEscolha}
            showFeedback={showFeedback}
          />
        ) : (
          <div>
            <p>Acertos: {acertosNivel}</p>
            <p>Erros: {errosNivel}</p>
            <button onClick={handleProximoNivel}>Próximo Nível</button>
          </div>
        )}
      </div>
    );
  }

  if (nivel === 3) {
    return (
      <div className="TreinoTermoDefinicao-container">
        <h2>Nível 3 - Pergunta Digitada</h2>
        {perguntasDigitadasQueue.size() > 0 ? (
          <PerguntaDigitadaTreino
            key={currentQuestionIndex}
            pergunta={perguntasDigitadasQueue.peek()!.question}
            respostaCorreta={perguntasDigitadasQueue.peek()!.answer}
            comment={perguntasDigitadasQueue.peek()!.comment}
            onAcertou={handleAcertouPerguntaDigitada}
            onErrou={handleErrouPerguntaDigitada}
          />
        ) : (
          <div>
            <p>Acertos: {acertosNivel}</p>
            <p>Erros: {errosNivel}</p>
            <p>Treino Concluído!</p>
            <p>Total de Acertos: {acertos}</p>
            <p>Total de Erros: {erros}</p>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default TreinoTermoDefinicao;
