import React, { useState, useEffect } from 'react';
import './FlashCardTreino.css';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FlashCardTreinoProps {
  id: number;
  front: string;
  back: string;
  comment: string;
  onAcertou: () => void;
  onErrou: () => void;
}

const FlashCardTreino: React.FC<FlashCardTreinoProps> = ({ id, front, back, comment, onAcertou, onErrou }) => {
  const [revealAnswer, setRevealAnswer] = useState(false);

  useEffect(() => {
    setRevealAnswer(false);
  }, [id]);

  return (
    <div className="FlashCardTreino-container">
      <div className="FlashCardTreino-card">
        <div className="FlashCardTreino-front">{front}</div>
        {revealAnswer && (
          <div className="FlashCardTreino-back reveal">
            <hr className="FlashCardTreino-divider" />
            {back}
          </div>
        )}
      </div>
      <div className="FlashCardTreino-buttons">
        {!revealAnswer && (
          <button onClick={() => setRevealAnswer(true)}>Mostrar Resposta</button>
        )}
        {revealAnswer && (
          <>
            <button onClick={onAcertou}>Acertei</button>
            <button onClick={onErrou}>Errei</button>
          </>
        )}
      </div>
      {revealAnswer && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Mostrar Comentário
          </AccordionSummary>
          <AccordionDetails>
            <div className="FlashCardTreino-comment">{comment}</div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default FlashCardTreino;
