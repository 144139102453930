import React, { useEffect, useState } from 'react';
import './QuizMultiplaEscolha.css';

interface MultipleChoiceQuestion {
  type: 'multipleChoice';
  question: string;
  answer: string;
  options: string[];
  justification: string;
}

interface MultipleChoiceQuizData {
  title: string;
  quizData: MultipleChoiceQuestion[];
}

interface QuizMultiplaEscolhaProps {
  data: MultipleChoiceQuizData;
}

const QuizMultiplaEscolha: React.FC<QuizMultiplaEscolhaProps> = ({ data }) => {
  const [quizData, setQuizData] = useState<MultipleChoiceQuestion[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (data && data.quizData) {
      setQuizData(data.quizData);
      setUserAnswers(new Array(data.quizData.length).fill(''));
    }
  }, [data]);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestionIndex] = e.target.value;
    setUserAnswers(updatedAnswers);
  };

  const handleNext = () => {
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = () => {
    setShowResults(true);
  };

  const handleRestart = () => {
    setShowResults(false);
    setCurrentQuestionIndex(0);
    setUserAnswers(new Array(quizData.length).fill(''));
  };

  if (!quizData.length) {
    return <div>Loading...</div>;
  }

  if (showResults) {
    const score = userAnswers.filter((answer, index) => 
      answer.toLowerCase().trim() === quizData[index].answer.toLowerCase().trim()
    ).length;

    return (
      <div className="QuizMultiplaEscolha-container">
        <h1>Resultados do Quiz</h1>
        <p>Pontuação: {score} de {quizData.length}</p>
        <div className="QuizMultiplaEscolha-results">
          {quizData.map((question, index) => {
            const userAnswer = userAnswers[index] || '';
            const isCorrect = userAnswer.toLowerCase().trim() === question.answer.toLowerCase().trim();
            return (
              <div key={index} className="QuizMultiplaEscolha-result-item">
                <p><strong>Pergunta:</strong> {question.question}</p>
                <p><strong>Sua Resposta:</strong> <span className={isCorrect ? 'QuizMultiplaEscolha-correct' : 'QuizMultiplaEscolha-incorrect'}>{userAnswer}</span></p>
                <p><strong>Resposta Correta:</strong> {question.answer}</p>
                <p><strong>Justificativa:</strong> {question.justification}</p>
              </div>
            );
          })}
        </div>
        <button onClick={handleRestart} className="QuizMultiplaEscolha-button">Reiniciar Quiz</button>
      </div>
    );
  }

  const { question, options } = quizData[currentQuestionIndex];

  return (
    <div className="QuizMultiplaEscolha-container">
      <div className="QuizMultiplaEscolha-header">
        <h1>{data.title}</h1>
      </div>
      <div className="QuizMultiplaEscolha-question">
        <p>{question}</p>
        <ul className="QuizMultiplaEscolha-options">
          {options.map((option, index) => (
            <li key={index}>
              <label>
                <input
                  type="radio"
                  name={`question-${currentQuestionIndex}`}
                  value={option}
                  checked={userAnswers[currentQuestionIndex] === option}
                  onChange={handleOptionChange}
                />
                {option}
              </label>
            </li>
          ))}
        </ul>
      </div>
      <div className="QuizMultiplaEscolha-navigation">
        <button onClick={handlePrev} disabled={currentQuestionIndex === 0} className="QuizMultiplaEscolha-button">Anterior</button>
        {currentQuestionIndex < quizData.length - 1 ? (
          <button onClick={handleNext} className="QuizMultiplaEscolha-button">Próxima</button>
        ) : (
          <button onClick={handleSubmit} className="QuizMultiplaEscolha-button">Ver Resultado</button>
        )}
      </div>
    </div>
  );
};

export default QuizMultiplaEscolha;
