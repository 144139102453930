import React, { useEffect, useState, useRef } from 'react';
import { exportToBlob, exportToSvg } from '@excalidraw/excalidraw';
import { FaDownload } from 'react-icons/fa';
import './ExcalidrawViewer.css'

interface ExcalidrawViewerProps {
  elements: any[];
  appState: any;
}

const ExcalidrawViewer: React.FC<ExcalidrawViewerProps> = ({ elements, appState }) => {
  const [svgContent, setSvgContent] = useState<string | null>(null);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const renderSvg = async () => {
      const svg = await exportToSvg({
        elements,
        appState: {
          ...appState,
          viewBackgroundColor: '#fff', // Background color for the exported image
        },
        files: {},
      });

      const svgString = new XMLSerializer().serializeToString(svg);
      setSvgContent(svgString);
    };

    renderSvg();
  }, [elements, appState]);

  const downloadImage = async (format: 'png' | 'jpg' | 'svg') => {
    let blob;
    if (format === 'svg') {
      const svg = await exportToSvg({
        elements,
        appState: {
          ...appState,
          viewBackgroundColor: '#fff',
        },
        files: {},
      });
      blob = new Blob([new XMLSerializer().serializeToString(svg)], { type: 'image/svg+xml' });
    } else {
      blob = await exportToBlob({
        elements,
        appState: {
          ...appState,
          viewBackgroundColor: '#fff',
        },
        files: {},
        mimeType: format === 'png' ? 'image/png' : 'image/jpeg',
      });
    }

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `excalidraw-image.${format}`;
    a.click();
    URL.revokeObjectURL(url);
    setShowMenu(false); // Close menu after download
  };

  // Close menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className='excalidraw-viewer-container'>
      {svgContent ? (
        <div
          dangerouslySetInnerHTML={{ __html: svgContent }}
          className='excalidraw-viewer-content'
        />
      ) : (
        'Loading...'
      )}
      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        <FaDownload onClick={() => setShowMenu(!showMenu)} style={{ cursor: 'pointer' }} />
        {showMenu && (
          <div ref={menuRef} style={{ position: 'absolute', top: '20px', right: '0', background: 'white', border: '1px solid #ccc', borderRadius: '4px', zIndex: 10 }}>
            <button onClick={() => downloadImage('png')} style={{ display: 'block', width: '100%', padding: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>Download PNG</button>
            <button onClick={() => downloadImage('jpg')} style={{ display: 'block', width: '100%', padding: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>Download JPG</button>
            <button onClick={() => downloadImage('svg')} style={{ display: 'block', width: '100%', padding: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>Download SVG</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExcalidrawViewer;
