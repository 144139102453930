import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, IconButton, Tooltip } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RedoIcon from '@mui/icons-material/Redo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import './PerguntaDigitado.css';

interface PerguntaDigitadoProps {
  id: number;
  pergunta: string;
  respostaCorreta: string;
  comment: string;
}

const PerguntaDigitado: React.FC<PerguntaDigitadoProps> = ({ id, pergunta, respostaCorreta, comment }) => {
  const [resposta, setResposta] = useState('');
  const [resultado, setResultado] = useState('');
  const [mostrarResposta, setMostrarResposta] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResposta(e.target.value);
  };

  const handleSubmit = () => {
    const normalizeString = (str: any) => {
      return str
        .normalize("NFD") // Normaliza para decompor caracteres acentuados
        .replace(/[\u0300-\u036f]/g, "") // Remove os acentos
        .trim() // Remove espaços em branco no início e no fim
        .toLowerCase(); // Converte para minúsculas
    };
    const isCorrectAnswer = normalizeString(resposta) === normalizeString(respostaCorreta);
    setIsCorrect(isCorrectAnswer);
    setIsAnswered(true);
    setResultado(isCorrectAnswer ? 'Correto!' : 'Incorreto.');
  };

  const handleShowAnswer = () => {
    setMostrarResposta(true);
  };

  const handleShowComment = () => {
    setShowComment(!showComment);
  };

  const handleReset = () => {
    setResposta('');
    setIsAnswered(false);
    setIsCorrect(null);
    setResultado('');
    setMostrarResposta(false);
    setShowComment(false);
  };

  return (
    <Card className="PerguntaDigitado-card">
      <CardContent>
        <Typography variant="h5" component="div" className="PerguntaDigitado-question-text">
          {pergunta}
        </Typography>
        <div className="PerguntaDigitado-input-container">
          <input 
            type="text" 
            value={resposta} 
            onChange={handleChange} 
            className={`PerguntaDigitado-input ${isAnswered && isCorrect ? 'PerguntaDigitado-correct-input' : ''} ${isAnswered && !isCorrect ? 'PerguntaDigitado-incorrect-input' : ''}`} 
          />
          {isAnswered && isCorrect && <CheckCircleIcon className="PerguntaDigitado-correct-icon" />}
          {isAnswered && !isCorrect && <CancelIcon className="PerguntaDigitado-incorrect-icon" />}
        </div>
        <div className="PerguntaDigitado-actions-container">
          <div className="PerguntaDigitado-button-group">
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isAnswered}>
              <CheckCircleIcon />
              <span>Verificar</span>
            </Button>
            <Button variant="contained" color="secondary" onClick={handleShowAnswer}>
              <VisibilityIcon />
              <span>Mostrar Resposta</span>
            </Button>
            <Tooltip title="Ver Comentário">
              <IconButton color="default" onClick={handleShowComment}>
                <CommentIcon />
              </IconButton>
            </Tooltip>
            <IconButton color="default" onClick={handleReset}>
              <RedoIcon />
            </IconButton>
          </div>
          <div className="PerguntaDigitado-icon-group">
            <IconButton color="primary" onClick={handleSubmit} disabled={isAnswered}>
              <CheckCircleIcon />
            </IconButton>
            <IconButton color="secondary" onClick={handleShowAnswer}>
              <VisibilityIcon />
            </IconButton>
            <Tooltip title="Ver Comentário">
              <IconButton color="default" onClick={handleShowComment}>
                <CommentIcon />
              </IconButton>
            </Tooltip>
            <IconButton color="default" onClick={handleReset}>
              <RestartAltIcon />
            </IconButton>
          </div>
        </div>
        <Typography variant="body1" className="PerguntaDigitado-resultado">
          {resultado}
        </Typography>
        {mostrarResposta && (
          <Typography variant="body1" className="PerguntaDigitado-resposta-correta">
            Resposta Correta: {respostaCorreta}
          </Typography>
        )}
        {showComment && (
          <div className="PerguntaDigitado-comment-section">
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: comment }} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PerguntaDigitado;
