import React, { useState, useEffect } from 'react';
import './DecisionTreeComic.css';

interface Decision {
  text: string;
  nextFrameId: number;
  isCorrect: boolean;
  feedback: string;
}

interface Frame {
  id: number;
  image?: string;
  text: string;
  decisions: Decision[];
}

interface Story {
  title: string;
  frames: Frame[];
}

interface DecisionTreeComicProps {
  data: Story;
}

const DecisionTreeComic: React.FC<DecisionTreeComicProps> = ({ data }) => {
  const [currentFrameId, setCurrentFrameId] = useState(0);
  const [feedback, setFeedback] = useState<string | null>(null);

  useEffect(() => {
    console.log("Dados recebidos:", data);
    if (!data || !data.frames) {
      console.error("Dados não disponíveis ou estrutura inválida", data);
    }
  }, [data]);

  if (!data || !data.frames) {
    return <div>Dados não disponíveis</div>;
  }

  const currentFrame = data.frames.find(frame => frame.id === currentFrameId);

  const handleDecision = (decision: Decision) => {
    setFeedback(decision.feedback);
    setCurrentFrameId(decision.nextFrameId);
  };

  return (
    <div className="decision-tree-comic-container">
      <h1>{data.title}</h1>
      {currentFrame ? (
        <div className="frame">
          {currentFrame.image ? (
            <img src={currentFrame.image} alt="comic frame" />
          ) : (
            <div className="no-image">Sem imagem disponível</div>
          )}
          <p>{currentFrame.text}</p>
          <div className="decisions">
            {currentFrame.decisions.map((decision, index) => (
              <button key={index} onClick={() => handleDecision(decision)}>
                {decision.text}
              </button>
            ))}
          </div>
          {feedback && <p className="feedback">{feedback}</p>}
        </div>
      ) : (
        <div>Quadro não encontrado</div>
      )}
    </div>
  );
};

export default DecisionTreeComic;
