import React, { useState } from 'react';
import { Card, CardContent, Typography, FormControlLabel, Checkbox, Button, IconButton, Tooltip, Radio } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './PerguntaMultiplaEscolha.css';

interface Option {
  text: string;
  correct: boolean;
}

interface PerguntaMultiplaEscolhaProps {
  question: string;
  options: Option[];
  questionType: 'single' | 'multiple';
  comment: string;
}

const PerguntaMultiplaEscolha: React.FC<PerguntaMultiplaEscolhaProps> = ({ question, options, questionType, comment }) => {
  const [selectedOptions, setSelectedOptions] = useState<boolean[]>(new Array(options.length).fill(false));
  const [showComment, setShowComment] = useState<boolean>(false);
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

  const handleOptionChange = (index: number) => {
    const newSelectedOptions = [...selectedOptions];
    if (questionType === 'single') {
      newSelectedOptions.fill(false);
    }
    newSelectedOptions[index] = !newSelectedOptions[index];
    setSelectedOptions(newSelectedOptions);
  };

  const handleSubmit = () => {
    const isCorrectAnswer = options.every((option, index) => option.correct === selectedOptions[index]);
    setIsCorrect(isCorrectAnswer);
    setIsAnswered(true);
  };

  const handleShowComment = () => {
    setShowComment(!showComment);
  };

  return (
    <Card className="PerguntaMultiplaEscolha-card">
      <CardContent>
        <Typography variant="h5" component="div" className="PerguntaMultiplaEscolha-question-text">
          {question}
        </Typography>
        {options.map((option, index) => (
          <div key={index} className={`PerguntaMultiplaEscolha-option-container ${isAnswered && option.correct ? 'PerguntaMultiplaEscolha-correct-option' : ''} ${isAnswered && !option.correct && selectedOptions[index] ? 'PerguntaMultiplaEscolha-incorrect-option' : ''}`}>
            <FormControlLabel
              control={
                questionType === 'single' ? (
                  <Radio
                    checked={selectedOptions[index]}
                    onChange={() => handleOptionChange(index)}
                    disabled={isAnswered}
                  />
                ) : (
                  <Checkbox
                    checked={selectedOptions[index]}
                    onChange={() => handleOptionChange(index)}
                    disabled={isAnswered}
                  />
                )
              }
              label={option.text}
            />
            {isAnswered && option.correct && <CheckCircleIcon className="PerguntaMultiplaEscolha-correct-icon" />}
            {isAnswered && !option.correct && selectedOptions[index] && <CancelIcon className="PerguntaMultiplaEscolha-incorrect-icon" />}
          </div>
        ))}
        <div className="PerguntaMultiplaEscolha-actions-container">
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isAnswered}>
            Responder
          </Button>
          <Tooltip title="Ver Comentário">
            <IconButton color="default" onClick={handleShowComment}>
              <CommentIcon />
            </IconButton>
          </Tooltip>
        </div>
        {isAnswered && (
          <Typography variant="body1" className={isCorrect ? 'PerguntaMultiplaEscolha-correct-answer-text' : 'PerguntaMultiplaEscolha-incorrect-answer-text'}>
            {isCorrect ? 'Correto!' : 'Incorreto!'}
          </Typography>
        )}
        {showComment && (
          <div className="PerguntaMultiplaEscolha-comment-section">
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: comment }} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PerguntaMultiplaEscolha;
