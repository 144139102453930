import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api';
import {updateComponenteOrder} from '../services/api'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { GoArrowBoth } from "react-icons/go";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ListIcon from '@mui/icons-material/ViewList';
import CompleteTexto from '../components/organisms/CompleteTexto/CompleteTexto';
import ImageOcclusion from '../components/organisms/ImageOcclusion/ImageOcclusion';
import ImageOcclusionPorClique from '../components/organisms/ImageOcclusionPorClique/ImageOcclusionPorClique';
import ImageOcclusionEditor from '../components/edicao/ImageOcclusionEditor/ImageOcclusionEditor';
import PreencherQuadro from '../components/organisms/PreencherQuadro/PreencherQuadro';
import PreencherQuadroEditor from '../components/edicao/PreencherQuadroEditor/PreencherQuadroEditor';
import PerguntaRapida from '../components/organisms/PerguntaRapida/PerguntaRapida';
import PerguntaRapidaEditor from '../components/edicao/PerguntaRapidaEditor/PerguntaRapidaEditor';
import SequencingGame from '../components/organisms/SequencingGame/SequencingGame';
import PreencherItemCategoria from '../components/organisms/PreencherItemCategoria/PreencherItemCategoria';
import ArrastarSoltarCategoria from '../components/organisms/ArrastarSoltarCategoria/ArrastarSoltarCategoria';
import Ordenacao from '../components/organisms/Ordenacao/Ordenacao';
import OrdenacaoEditor from '../components/edicao/OrdenacaoEditor/OrdenacaoEditor';
import QuizDigitado from '../components/organisms/QuizDigitado/QuizDigitado';
import QuizMultiplaEscolha from '../components/organisms/QuizMultiplaEscolha/QuizMultiplaEscolha';
import TimeLine from '../components/organisms/TimeLine/TimeLine';
import TimeLineEditor from '../components/edicao/TimeLineEditor/TimeLineEditor';
import FlipCard from '../components/organisms/FlipCard/FlipCard';
import RevealCard from '../components/organisms/RevealCard/RevealCard';
import CardEditor from '../components/edicao/CardEditor/CardEditor';
import TreinoFlashCard from '../components/organisms/treino/TreinoFlashCard/TreinoFlashCard';
import TreinoFlashCardsEditor from '../components/edicao/TreinoFlashCardsEditor/TreinoFlashCardsEditor';
import TermoDefinicaoEditor from '../components/edicao/TermoDefinicaoEditor/TermoDefinicaoEditor';
import PerguntaDigitado from '../components/organisms/PerguntaDigitado/PerguntaDigitado';
import PerguntaDigitadoEditor from '../components/edicao/PerguntaDigitadoEditor/PerguntaDigitadoEditor';
import PerguntaMultiplaEscolha from '../components/organisms/PerguntaMultiplaEscolha/PerguntaMultiplaEscolha';
import PerguntaMultiplaEscolhaEditor from '../components/edicao/PerguntaMultiplaEscolhaEditor/PerguntaMultiplaEscolhaEditor';
import FlashCard from '../components/organisms/FlashCard/FlashCard';
import QuizMultiploImediato from '../components/organisms/QuizMultiploImediato/QuizMultiploImediato';
import DecisionTreeComic from '../components/organisms/DecisionTreeComic/DecisionTreeComic';
import Texto from '../components/organisms/Texto/Texto';
import TextoEditor from '../components/edicao/TextoEditor/TextoEditor';
import ExcalidrawViewer from '../components/organisms/ExcalidrawViewer/ExcalidrawViewer';
import ExcalidrawEditor from '../components/edicao/ExcalidrawEditor/ExcalidrawEditor';
import TreinoTermoDefinicao from '../components/organisms/treino/TreinoTermoDefinicao/TreinoTermoDefinicao';
import TreinoTermoDefinicaoEditor from '../components/edicao/TreinoTermoDefinicaoEditor/TreinoTermoDefinicaoEditor';
import { Question, TextQuestion, MultipleChoiceQuestion } from '../types/QuestionTypes';
import SequencingGameEditor from '../components/edicao/SequencingGameEditor/SequencingGameEditor';
import PreencherItemCategoriaEditor from '../components/edicao/PreencherItemCategoriaEditor/PreencherItemCategoriaEditor';
import ArrastarSoltarCategoriaEditor from '../components/edicao/ArrastarSoltarCategoriaEditor/ArrastarSoltarCategoriaEditor';
import PreencherItemFasesEditor from '../components/edicao/PreencherItemFasesEditor/PreencherItemFasesEditor';
import PreencherItemFases from '../components/organisms/PreencherItemFases/PreencherItemFases';
import { Phase, Activity, Occlusion, TreinoFlashCardData } from '../types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './UsuarioPage.css';

interface PreencherItemCategoriaCategory {
  [key: string]: string[];
}

interface ArrastarSoltarCategory {
  [key: string]: string;
}

interface Stage {
  order: number;
  value: string;
}

interface FlashCardData {
  question: string;
  answer: string;
  saiba_mais?: string;
}

interface QuestionData {
  ano: string;
  banca: string;
  orgao: string;
  prova: string;
  question: string;
  options: string[];
  answer: string;
  justification: string;
}

interface Decision {
  text: string;
  nextFrameId: number;
  isCorrect: boolean;
  feedback: string;
}

interface TimeLinePhase {
  name: string;
  order: number;
  items: string[];
}

interface Frame {
  id: number;
  image?: string;
  text: string;
  decisions: Decision[];
}

interface Story {
  title: string;
  frames: Frame[];
}

interface TermoDefinicao {
  termo: string;
  definicao: string;
  comentario: string;
}

interface ProcessData {
  title: string;
  processes: {
    [key: string]: {
      [key: string]: string[];
    };
  };
  processGroups: string[];
  knowledgeAreas: string[];
}


interface Componente {
  id: number;
  type: string;
  dados: {
      html?: string;
      texto?: string;
      imageUrl?: string;
      occlusions?: any[];
      imageWidth?: number;
      imageHeight?: number;
      headers?: string[];
      rows?: { header: string; values: string[] }[];
      correctAnswers?: { [key: string]: string };
      phases?: { name: string; order: number; items: string[] }[];
      activities?: { name: string; phase: string }[];
      title?: string;
      categories?: PreencherItemCategoriaCategory | ArrastarSoltarCategory;
      question?: string;
      answer?: string;
      type?: "question" | "error" | "warning" | "information";
      options?: { text: string; correct: boolean }[];
      questionType?: 'single' | 'multiple';
      stages?: { order: number; value: string }[];
      quizData?: Question[];
      front?: string;
      back?: string;
      respostaCorreta?: string;
      saiba_mais?: string;
      flashcardData?: FlashCardData[];
      questionData?: QuestionData[];
      story?: Story;
      elements?: any[];
      appState?: any;
      links?: any[];
      nodes?: any[];
      considerarOrdem?: boolean;
      mode?: any;
      withText?: boolean;
      comment?: string;
      treinoFlashCardsData?: TreinoFlashCardData[];
      assunto?: string;
      termosDefinicoes?: TermoDefinicao[];
      tiposComponentes?: string[];
      termoComo?: 'pergunta' | 'resposta' | 'ambos';
      pdfUrl?: string;               // Adicionando atributos específicos para PDF
      annotations?: any[];           // Adicionando atributos específicos para PDF
  };
  ordem: number;
  key?: number;
}


interface PaginaUsuario {
  id: number;
  titulo: string;
  paginaUsuarioComponentes: { componente: Componente }[];
  pasta: { id: number, nome: string };
}

interface UsuarioPageProps {
  pageId: number;
}

const UsuarioPage: React.FC<UsuarioPageProps> = ({ pageId }) => {
  const [paginaUsuario, setPaginaUsuario] = useState<PaginaUsuario | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [componenteToDelete, setComponenteToDelete] = useState<Componente | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [editingComponente, setEditingComponente] = useState<Componente | null>(null);
  const [insertAfterPosition, setInsertAfterPosition] = useState<number | null>(null);
  const [viewMode, setViewMode] = useState<'list' | 'sequential'>('list');
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const [updateKey, setUpdateKey] = useState<number>(Date.now());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchPaginaUsuario = async () => {
    try {
      const response = await api.get(`/paginasUsuario/${pageId}`);
      const orderedComponents = response.data.paginaUsuarioComponentes
        .sort((a: { componente: Componente }, b: { componente: Componente }) => a.componente.ordem - b.componente.ordem)
        .map((item: { componente: Componente }, index: number) => ({
          ...item,
          componente: {
            ...item.componente,
            ordem: index + 1,
          },
        }));
      setPaginaUsuario({ ...response.data, paginaUsuarioComponentes: orderedComponents });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchPaginaUsuario();
  }, [pageId]);

  useEffect(() => {
    if (paginaUsuario && paginaUsuario.paginaUsuarioComponentes.length === 0 && viewMode === 'sequential') {
      setViewMode('list');
    }
  }, [paginaUsuario, viewMode]);

  const handleDelete = (componente: Componente) => {
    setComponenteToDelete(componente);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (componenteToDelete) {
      try {
        await api.delete(`/componentes/${componenteToDelete.id}`);

        setPaginaUsuario((prevState) => {
          if (!prevState) return prevState;

          const filteredComponentes = prevState.paginaUsuarioComponentes.filter(
            ({ componente }) => componente.id !== componenteToDelete.id
          );

          const updatedComponentes = filteredComponentes.map((comp, index) => ({
            ...comp,
            componente: {
              ...comp.componente,
              ordem: index + 1,
            },
          }));

          return {
            ...prevState,
            paginaUsuarioComponentes: updatedComponentes,
          };
        });

        if (paginaUsuario) {
          const updatedComponentes = paginaUsuario.paginaUsuarioComponentes
            .filter(({ componente }) => componente.id !== componenteToDelete.id)
            .map((comp, index) => ({
              ...comp,
              componente: {
                ...comp.componente,
                ordem: index + 1,
              },
            }));

          await Promise.all(
            updatedComponentes.map((comp) =>
              api.put(`/componentes/${comp.componente.id}`, {
                ...comp.componente,
                dados: comp.componente.dados,
                ordem: comp.componente.ordem,
              })
            )
          );
        }
      } catch (error) {
        console.error('Error deleting component:', error);
      } finally {
        setDeleteDialogOpen(false);
        setComponenteToDelete(null);
      }
    }
  };

  const handleAddComponent = (position: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setInsertAfterPosition(position);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectComponent = (type: string) => {
    setAnchorEl(null);
    setIsEditorOpen(true);
    let initialData: any = {};
    if (type === 'TermoDefinicao') {
      initialData = {};
    } else if (type === 'SequencingGame') {
      initialData = {
        phases: [{ name: '', order: 1, items: [] }],
        activities: [],
        considerarOrdem: false,
      };
    } else if (type === 'ArrastarSoltarCategoria') {
      initialData = {
        title: '',
        question: '',
        categories: {},
      };
    } else if (type === 'TimeLine') {
      initialData = {
        title: '',
        phases: [],
        considerarOrdem: false,
      };
    } else if (type === 'PreencherQuadro') {
      initialData = {
        title: '',
        processes: {},
        processGroups: [],
        knowledgeAreas: [],
      };
    } else if (type === 'PerguntaMultiplaEscolha') {
      initialData = {
        question: '',
        options: [{ text: '', correct: false }],
        questionType: 'single',
        comment: '',
      };
    } else if (type === 'PerguntaRapida') {
      initialData = { question: '', answer: '', type: 'question' };
    } else {
      initialData = { html: '' };
    }
    setEditingComponente({ id: 0, type, dados: initialData, ordem: insertAfterPosition! + 1 });
  };

  const copyIframeToClipboard = (componente: Componente) => {
    const iframeCode = `<iframe src="${window.location.origin}/visualizar/componente/${componente.id}" width="100%" height="100%" frameborder="0"></iframe>`;
    navigator.clipboard.writeText(iframeCode)
      .then(() => {
        alert('Iframe copiado para a área de transferência!');
      })
      .catch((err) => {
        console.error('Erro ao copiar o iframe: ', err);
      });
  };
  

  const handleSaveComponent = async (html: string) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { ...editingComponente.dados },
      };

      if (editingComponente.type === 'CompleteTexto') {
        newComponente.dados.texto = html;
      } else {
        newComponente.dados.html = html;
      }

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveTermoDefinicao = async (
    termosDefinicoes: { termo: string; definicao: string; comentario: string }[],
    tiposComponentes: string[],
    termoComo: 'pergunta' | 'resposta' | 'ambos',
    flipOrReveal?: 'flip' | 'reveal'
  ) => {
    if (paginaUsuario) {
      const novosComponentes = termosDefinicoes.flatMap((td, index) => {
        const componentes: Componente[] = [];

        tiposComponentes.forEach((tipoComponente) => {
          if (tipoComponente === 'PerguntaDigitado') {
            componentes.push({
              id: 0,
              type: tipoComponente,
              dados: {
                question: td.definicao,
                respostaCorreta: td.termo,
                comment: td.comentario,
              },
              ordem: insertAfterPosition! + 1 + index,
            });
          } else if (tipoComponente === 'PerguntaMultiplaEscolha') {
            const options = termosDefinicoes.map((optionTd) => ({
              text: optionTd.termo,
              correct: optionTd.termo === td.termo,
            }));
            componentes.push({
              id: 0,
              type: tipoComponente,
              dados: {
                question: td.definicao,
                options,
                questionType: 'single',
                comment: td.comentario,
              },
              ordem: insertAfterPosition! + 1 + index,
            });
          } else if (tipoComponente === 'Card') {
            componentes.push({
              id: 0,
              type: tipoComponente,
              dados: {
                front: td.termo,
                back: td.definicao,
                mode: flipOrReveal,
              },
              ordem: insertAfterPosition! + 1 + index,
            });
          }

          if (termoComo === 'ambos' && tipoComponente !== 'PerguntaDigitado') {
            if (tipoComponente === 'PerguntaMultiplaEscolha') {
              const options = termosDefinicoes.map((optionTd) => ({
                text: optionTd.definicao,
                correct: optionTd.definicao === td.definicao,
              }));
              componentes.push({
                id: 0,
                type: tipoComponente,
                dados: {
                  question: td.termo,
                  options,
                  questionType: 'single',
                  comment: td.comentario,
                },
                ordem: insertAfterPosition! + 1 + index,
              });
            } else if (tipoComponente === 'Card') {
              componentes.push({
                id: 0,
                type: tipoComponente,
                dados: {
                  front: td.definicao,
                  back: td.termo,
                  mode: flipOrReveal,
                },
                ordem: insertAfterPosition! + 1 + index,
              });
            }
          }
        });

        return componentes;
      });

      try {
        for (const novoComponente of novosComponentes) {
          await api.post('/componentes', {
            ...novoComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving components:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentSequencingGame = async (
    title: string,
    phases: Phase[],
    activities: Activity[],
    considerarOrdem: boolean
  ) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { title, phases, activities, considerarOrdem },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();

        setPaginaUsuario((prevState) => {
          if (!prevState) return prevState;

          return {
            ...prevState,
            paginaUsuarioComponentes: prevState.paginaUsuarioComponentes.map((comp) =>
              comp.componente.id === editingComponente.id
                ? { ...comp, componente: { ...comp.componente, key: new Date().getTime() } }
                : comp
            ),
          };
        });
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };


  const handleSaveComponentPreencherQuadro = async (data: ProcessData) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: data,
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentPreencherItemFases = async (
    title: string,
    phases: { name: string; items: string[] }[],
    considerarOrdem: boolean
  ) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { title, phases, considerarOrdem },
      };
  
      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }
  
        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };
  

  const handleSaveComponentTreinoTermoDefinicao = async (
    assunto: string,
    termosDefinicoes: TermoDefinicao[],
    tiposComponentes: string[],
    termoComo: 'pergunta' | 'resposta' | 'ambos'
  ) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { assunto, termosDefinicoes, tiposComponentes, termoComo },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentTimeLine = async (
    title: string,
    phases: TimeLinePhase[],
    considerarOrdem: boolean
  ) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { title, phases, considerarOrdem },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentOrdenacao = async (question: string, stages: Stage[]) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { ...editingComponente.dados, question, stages },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentPreencherItemCategoria = async (
    title: string,
    categories: PreencherItemCategoriaCategory,
    considerarOrdem: boolean
  ) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { title, categories, considerarOrdem },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentImageOcclusion = async (data: {
    imageUrl: string;
    occlusions: Occlusion[];
    imageWidth: number;
    imageHeight: number;
    mode: 'digitada' | 'clicada';
    withText: boolean;
    title?: string;
  }) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: {
          imageUrl: data.imageUrl,
          occlusions: data.occlusions,
          imageWidth: data.imageWidth,
          imageHeight: data.imageHeight,
          mode: data.mode,
          withText: data.withText,
          title: data.title,
        },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentArrastarSoltarCategoria = async (
    title: string,
    question: string,
    categories: PreencherItemCategoriaCategory
  ) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { title, question, categories },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const moveComponent = async (currentPosition: number, newPosition: number) => {
    if (!paginaUsuario) return;

    if (newPosition < 0 || newPosition >= paginaUsuario.paginaUsuarioComponentes.length) return;

    const updatedComponentes = [...paginaUsuario.paginaUsuarioComponentes];
    const [movedItem] = updatedComponentes.splice(currentPosition, 1);
    updatedComponentes.splice(newPosition, 0, movedItem);

    const reorderedComponentes = updatedComponentes.map((comp, index) => ({
      ...comp,
      componente: {
        ...comp.componente,
        ordem: index + 1, // Atualize a ordem dos componentes
      },
    }));

    try {
      // Atualizar a ordem no frontend
      setPaginaUsuario({
        ...paginaUsuario,
        paginaUsuarioComponentes: reorderedComponentes,
      });

      // Chamar o backend para atualizar a ordem no banco de dados
      const componentsToUpdate = reorderedComponentes.map(comp => ({
        id: comp.componente.id,
        ordem: comp.componente.ordem,
      }));

      await updateComponenteOrder(pageId, componentsToUpdate);
    } catch (error) {
      console.error('Error moving component:', error);
    }
  };


  const renderComponente = (componente: Componente, position: number) => {
    const { dados } = componente;
    if (!dados) return null;
    const paginaUsuarioLength = paginaUsuario?.paginaUsuarioComponentes.length ?? 0;

    return (
        <div key={componente.id} className="UsuarioPage-componente">
            {componente.type === 'CompleteTexto' && <CompleteTexto id={componente.id} title={dados.title || ''} texto={dados.texto || ''} />}
            {componente.type === 'ImageOcclusion' && dados.mode === 'digitada' && (
                <ImageOcclusion
                    data={{
                        imageUrl: dados.imageUrl!,
                        occlusions: dados.occlusions!,
                        imageWidth: dados.imageWidth!,
                        imageHeight: dados.imageHeight!,
                        title: dados.title,
                    }}
                />
            )}

            {componente.type === 'PerguntaRapida' && (
              <PerguntaRapida
                id={componente.id}
                question={dados.question || ''}
                answer={dados.answer || ''}
                type={dados.type || 'question'}
              />
            )}


            {componente.type === 'ImageOcclusion' && dados.mode === 'clicada' && (
                <ImageOcclusionPorClique
                    data={{
                        imageUrl: dados.imageUrl!,
                        occlusions: dados.occlusions!,
                        imageWidth: dados.imageWidth!,
                        imageHeight: dados.imageHeight!,
                        withText: dados.withText!,
                        title: dados.title,
                    }}
                />
            )}
            {componente.type === 'PreencherQuadro' && <PreencherQuadro data={dados as ProcessData} />}
            {componente.type === 'SequencingGame' && (
                <SequencingGame
                    key={componente.id + '-' + (componente.key || '')}
                    data={{
                        title: dados.title,
                        phases: dados.phases!,
                        activities: dados.activities!,
                        considerarOrdem: dados.considerarOrdem,
                    }}
                />
            )}
            {componente.type === 'PreencherItemCategoria' && <PreencherItemCategoria data={dados as any} />}
            {componente.type === 'ArrastarSoltarCategoria' && <ArrastarSoltarCategoria data={dados as any} />}
            {componente.type === 'Ordenacao' && <Ordenacao data={dados as any} />}
            {componente.type === 'QuizDigitado' && <QuizDigitado data={{ title: dados.title!, quizData: dados.quizData as TextQuestion[] }} />}
            {componente.type === 'QuizMultiplaEscolha' && <QuizMultiplaEscolha data={{ title: dados.title!, quizData: dados.quizData as MultipleChoiceQuestion[] }} />}
            {componente.type === 'TimeLine' && <TimeLine data={{ title: dados.title, phases: dados.phases!, considerarOrdem: dados.considerarOrdem }} />}
            {componente.type === 'Card' && dados.mode === 'flip' && <FlipCard id={componente.id} front={dados.front!} back={dados.back!} />}
            {componente.type === 'Card' && dados.mode === 'reveal' && <RevealCard id={componente.id} front={dados.front!} back={dados.back!} comment={dados.comment!} />}
            {componente.type === 'PerguntaDigitado' && <PerguntaDigitado id={componente.id} pergunta={dados.question!} respostaCorreta={dados.respostaCorreta!} comment={dados.comment!} />}
            {componente.type === 'FlashCard' && <FlashCard title={dados.title!} flashcardData={dados.flashcardData!} />}
            {componente.type === 'QuizMultiploImediato' && <QuizMultiploImediato data={dados.questionData!} />}
            {componente.type === 'DecisionTreeComic' && <DecisionTreeComic data={dados as Story} />}
            {componente.type === 'Texto' && <Texto html={dados.html || ''} />}
            {componente.type === 'Excalidraw' && (
                <ExcalidrawViewer elements={dados.elements ?? []} appState={dados.appState ?? {}} />
            )}
            {componente.type === 'TreinoFlashCard' && (
                <TreinoFlashCard
                    key={updateKey}
                    cards={componente.dados.treinoFlashCardsData || []}
                    onComplete={(result) => {
                        console.log('Resultado do treino:', result);
                        // Aqui você pode adicionar lógica para exibir o relatório final
                    }}
                />
            )}
            {componente.type === 'PreencherItemFases' && (
              <PreencherItemFases
                data={{
                  title: dados.title || '',
                  phases: dados.phases || [],
                  considerarOrdem: dados.considerarOrdem || false,
                }}
              />
            )}

            {componente.type === 'TreinoTermoDefinicao' && (
                <TreinoTermoDefinicao
                    dados={{
                        assunto: dados.assunto!,
                        termosDefinicoes: dados.termosDefinicoes!,
                        tiposComponentes: dados.tiposComponentes!,
                        termoComo: dados.termoComo!,
                    }}
                />
            )}
            {componente.type === 'PerguntaMultiplaEscolha' && (
                <PerguntaMultiplaEscolha
                    question={dados.question || ''}
                    options={dados.options || []}
                    questionType={dados.questionType || 'single'}
                    comment={dados.comment || ''}
                />
            )}
            {isEditMode && (
                <div className="UsuarioPage-edit-buttons">
                    <IconButton
                        color="primary"
                        onClick={() => {
                            setIsEditorOpen(true);
                            setEditingComponente(componente);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(componente)}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton color="default" onClick={handleAddComponent(position)}>
                        <AddIcon />
                    </IconButton>
                    <IconButton color="default" onClick={() => copyIframeToClipboard(componente)}>
                        <ContentCopyIcon />
                    </IconButton>  {/* Botão para copiar o iframe */}
                    {viewMode === 'list' ? (
                        <>
                            <IconButton
                                color="default"
                                onClick={() => moveComponent(position - 1, position - 2)}
                                disabled={position === 1}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton
                                color="default"
                                onClick={() => moveComponent(position - 1, position)}
                                disabled={position === paginaUsuarioLength}
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <IconButton
                                color="default"
                                onClick={() => moveComponent(position - 1, position - 2)}
                                disabled={position === 1}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <IconButton
                                color="default"
                                onClick={() => moveComponent(position - 1, position)}
                                disabled={position === paginaUsuarioLength}
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};


  const renderEditor = () => {
    if (!editingComponente) return null;

    if (editingComponente.type === 'Card') {
      return (
        <CardEditor
          initialFront={editingComponente.dados.front || ''}
          initialBack={editingComponente.dados.back || ''}
          initialComment={editingComponente.dados.comment || ''}
          initialMode={editingComponente.dados.mode || ''}
          onSave={handleSaveComponentCard}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'PerguntaRapida') {
      return (
        <PerguntaRapidaEditor
          initialQuestion={editingComponente.dados.question || ''}
          initialAnswer={editingComponente.dados.answer || ''}
          initialType={editingComponente.dados.type || 'question'}
          onSave={handleSaveComponentPerguntaRapida}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }
    

    if (editingComponente.type === 'TermoDefinicao') {
      return (
        <TermoDefinicaoEditor
          onSave={handleSaveTermoDefinicao}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'PreencherItemFases') {
      return (
        <PreencherItemFasesEditor
          title={editingComponente.dados.title || ''}
          phases={editingComponente.dados.phases || []}
          considerarOrdem={editingComponente.dados.considerarOrdem ?? false}
          onSave={handleSaveComponentPreencherItemFases}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }
    

    if (editingComponente.type === 'TreinoTermoDefinicao') {
      return (
        <TreinoTermoDefinicaoEditor
          onSave={handleSaveComponentTreinoTermoDefinicao}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'TreinoFlashCard') {
      return (
        <TreinoFlashCardsEditor
          initialCards={editingComponente.dados.treinoFlashCardsData || []}
          onSave={handleSaveComponentTreinoFlashCard}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'TimeLine') {
      return (
        <TimeLineEditor
          title={editingComponente.dados.title}
          phases={editingComponente.dados.phases || []}
          considerarOrdem={editingComponente.dados.considerarOrdem ?? false}
          onSave={handleSaveComponentTimeLine}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'Ordenacao') {
      return (
        <OrdenacaoEditor
          initialStages={editingComponente.dados.stages || []}
          question={editingComponente.dados.question || ''}
          onSave={handleSaveComponentOrdenacao}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }
    if (editingComponente.type === 'SequencingGame') {
      return (
        <SequencingGameEditor
          title={editingComponente.dados.title}
          phases={editingComponente.dados.phases || []}
          activities={editingComponente.dados.activities || []}
          considerarOrdem={editingComponente.dados.considerarOrdem ?? false}
          onSave={handleSaveComponentSequencingGame}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'ImageOcclusion') {
      return (
        <ImageOcclusionEditor
          initialData={{
            imageUrl: editingComponente.dados.imageUrl || '',
            occlusions: editingComponente.dados.occlusions || [],
            imageWidth: editingComponente.dados.imageWidth || 800,
            imageHeight: editingComponente.dados.imageHeight || 600,
            mode: editingComponente.dados.mode || 'digitada',
            withText: editingComponente.dados.withText ?? true,
            title: editingComponente.dados.title || '',
          }}
          onSave={handleSaveComponentImageOcclusion}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'PreencherItemCategoria') {
      return (
        <PreencherItemCategoriaEditor
          title={editingComponente.dados.title || ''}
          categories={editingComponente.dados.categories as PreencherItemCategoriaCategory || {}}
          considerarOrdem={editingComponente.dados.considerarOrdem ?? false}
          onSave={handleSaveComponentPreencherItemCategoria}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'ArrastarSoltarCategoria') {
      return (
        <ArrastarSoltarCategoriaEditor
          title={editingComponente.dados.title || ''}
          question={editingComponente.dados.question || ''}
          categories={editingComponente.dados.categories as PreencherItemCategoriaCategory || {}}
          onSave={handleSaveComponentArrastarSoltarCategoria}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'Excalidraw') {
      return (
        <ExcalidrawEditor
          initialData={{
            elements: editingComponente.dados.elements ?? [],
            appState: editingComponente.dados.appState ?? {},
          }}
          onSave={(data) => handleSaveComponentExcalidraw(data)}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'PreencherQuadro') {
      return (
        <PreencherQuadroEditor
          initialData={editingComponente.dados as ProcessData}
          onSave={handleSaveComponentPreencherQuadro}
          onCancel={() => setIsEditorOpen(false)}
        />
      );
    }

    if (editingComponente.type === 'PerguntaMultiplaEscolha') {
      return (
        <PerguntaMultiplaEscolhaEditor
          initialQuestion={editingComponente.dados.question || ''}
          initialOptions={editingComponente.dados.options || [{ text: '', correct: false }]}
          initialQuestionType={editingComponente.dados.questionType || 'single'}
          initialComment={editingComponente.dados.comment || ''}
          onSave={handleSaveComponentPerguntaMultiplaEscolha}
          onCancel={() => setIsEditorOpen(false)}
          mode={editingComponente.id === 0 ? 'add' : 'edit'}
        />
      );
    }

    if (editingComponente.type === 'PerguntaDigitado') {
      return (
        <PerguntaDigitadoEditor
          initialQuestion={editingComponente.dados.question || ''}
          initialAnswer={editingComponente.dados.respostaCorreta || ''}
          initialComment={editingComponente.dados.comment || ''}
          onSave={handleSaveComponentPerguntaDigitado}
          onCancel={() => setIsEditorOpen(false)}
          mode={editingComponente.id === 0 ? 'add' : 'edit'}
        />
      );
    }

    let initialContent = '';
    if (editingComponente.type === 'CompleteTexto') {
      initialContent = editingComponente.dados.texto || '';
    } else {
      initialContent = editingComponente.dados.html || '';
    }

    return (
      <TextoEditor initialContent={initialContent} onSave={handleSaveComponent} onCancel={() => setIsEditorOpen(false)} />
    );
  };

  const handleSaveComponentTreinoFlashCard = async (cards: TreinoFlashCardData[]) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { treinoFlashCardsData: cards },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();

        setUpdateKey(Date.now());
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentPerguntaRapida = async (
    question: string,
    answer: string,
    type: string,
    action: 'close' | 'addNew'
  ) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { question, answer, type },
      };
  
      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }
  
        await fetchPaginaUsuario();
  
        if (action === 'addNew') {
          setEditingComponente({
            id: 0,
            type: 'PerguntaRapida',
            dados: { question: '', answer: '', type: 'question' },
            ordem: insertAfterPosition! + 1,
          });
        } else {
          setIsEditorOpen(false);
          setEditingComponente(null);
          setInsertAfterPosition(null);
        }
      } catch (error) {
        console.error('Error saving component:', error);
      }
    }
  };
  

  const handleSaveComponentCard = async (
    front: string,
    back: string,
    mode: 'flip' | 'reveal',
    comment: string,
    action: 'close' | 'addNew'
  ) => {
    console.log(`handleSaveComponentCard: action=${action}, front=${front}, back=${back}, mode=${mode}`);
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { ...editingComponente.dados, front, back, mode, ...(mode === 'reveal' && { comment }) },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();

        if (action === 'addNew') {
          setEditingComponente({
            id: 0,
            type: 'Card',
            dados: { front: '', back: '', mode: 'reveal', comment: '' },
            ordem: insertAfterPosition! + 1,
          });
        } else {
          setIsEditorOpen(false);
          setEditingComponente(null);
          setInsertAfterPosition(null);
        }
      } catch (error) {
        console.error('Error saving component:', error);
      }
    }
  };

  const handleSaveComponentExcalidraw = async (data: { elements: any[]; appState: any }) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { elements: data.elements, appState: data.appState },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentPerguntaMultiplaEscolha = async (
    question: string,
    options: { text: string; correct: boolean }[],
    questionType: 'single' | 'multiple',
    comment: string
  ) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { ...editingComponente.dados, question, options, questionType, comment },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();
      } catch (error) {
        console.error('Error saving component:', error);
      } finally {
        setIsEditorOpen(false);
        setEditingComponente(null);
        setInsertAfterPosition(null);
      }
    }
  };

  const handleSaveComponentPerguntaDigitado = async (
    question: string,
    answer: string,
    comment: string,
    action: 'close' | 'addNew'
  ) => {
    if (editingComponente && paginaUsuario) {
      const newComponente = {
        ...editingComponente,
        dados: { ...editingComponente.dados, question, respostaCorreta: answer, comment },
      };

      try {
        if (editingComponente.id === 0) {
          await api.post('/componentes', {
            ...newComponente,
            pagina_usuario_id: paginaUsuario.id,
            pasta_id: paginaUsuario.pasta?.id,
            posicao: insertAfterPosition,
          });
        } else {
          await api.put(`/componentes/${editingComponente.id}`, newComponente);
        }

        await fetchPaginaUsuario();

        if (action === 'addNew') {
          setEditingComponente({
            id: 0,
            type: 'PerguntaDigitado',
            dados: { question: '', respostaCorreta: '', comment: '' },
            ordem: insertAfterPosition! + 1,
          });
        } else {
          setIsEditorOpen(false);
          setEditingComponente(null);
          setInsertAfterPosition(null);
        }
      } catch (error) {
        console.error('Error saving component:', error);
      }
    }
  };

  const handleViewModeChange = (mode: 'list' | 'sequential') => {
    setViewMode(mode);
    setCurrentComponentIndex(0);
  };

  const handleViewModeToggle = () => {
    setViewMode((prevMode) => (prevMode === 'list' ? 'sequential' : 'list'));
  };

  const handleNextComponent = () => {
    if (currentComponentIndex < (paginaUsuario?.paginaUsuarioComponentes.length ?? 0) - 1) {
      setCurrentComponentIndex(currentComponentIndex + 1);
    }
  };

  const handlePreviousComponent = () => {
    if (currentComponentIndex > 0) {
      setCurrentComponentIndex(currentComponentIndex - 1);
    }
  };

  const handleSelectComponentFromDropdown = (event: SelectChangeEvent<number>) => {
    setCurrentComponentIndex(event.target.value as number);
  };

  const renderComponentList = () => (
    <>
      {paginaUsuario?.paginaUsuarioComponentes.map(({ componente }, index) => renderComponente(componente, index + 1))}
    </>
  );

  const renderSequentialView = () => {
    if (!paginaUsuario || paginaUsuario.paginaUsuarioComponentes.length === 0) {
      return (
        <div className="UsuarioPage-empty">
          <p>Nenhum componente para exibir.</p>
        </div>
      );
    }
  
    return (
      <>
        {renderComponente(paginaUsuario.paginaUsuarioComponentes[currentComponentIndex].componente, currentComponentIndex + 1)}
        <div className="UsuarioPage-sequential-navigation">
          <IconButton onClick={handlePreviousComponent} disabled={currentComponentIndex === 0}>
            <ArrowBackIcon />
          </IconButton>
          <FormControl variant="outlined" className="UsuarioPage-dropdown">
            <InputLabel id="component-dropdown-label">Componentes</InputLabel>
            <Select
              labelId="component-dropdown-label"
              value={currentComponentIndex}
              onChange={handleSelectComponentFromDropdown}
              label="Componentes"
            >
              {paginaUsuario?.paginaUsuarioComponentes.map(({componente}, index) => (
                <MenuItem key={index} value={index}>
                  {componente.dados.title || componente.type || `Componente ${index + 1}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton onClick={handleNextComponent} disabled={currentComponentIndex >= (paginaUsuario?.paginaUsuarioComponentes.length ?? 0) - 1}>
            <ArrowForwardIcon />
          </IconButton>
        </div>
      </>
    );
  };
  

  if (!paginaUsuario) {
    return <p>Loading...</p>;
  }

  return (
    <div className="UsuarioPage-container">
      <div className="UsuarioPage-edit-mode-toggle">
        <IconButton className='botao-editar-visualizar-componente' onClick={() => setIsEditMode(!isEditMode)} color="primary">
          {isEditMode ? <VisibilityIcon /> : <EditIcon />}
        </IconButton>
        <IconButton onClick={handleViewModeToggle} color="primary">
          {viewMode === 'list' ? <GoArrowBoth /> : <ListIcon />}
        </IconButton>
      </div>
      <div className="UsuarioPage-content">
      <h1>{paginaUsuario.titulo}</h1>
      {isEditMode && !paginaUsuario.paginaUsuarioComponentes.length && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddComponent(0)}
          style={{ marginBottom: '20px' }}
        >
          Adicionar Componente
        </Button>
      )}
      {viewMode === 'list' ? renderComponentList() : renderSequentialView()}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem onClick={() => handleSelectComponent('Texto')}>Texto</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('CompleteTexto')}>Complete o Texto</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('Card')}>Card</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('Ordenacao')}>Ordenação</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('SequencingGame')}>Fases e Itens</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('PreencherItemCategoria')}>Preencher Item por Categoria</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('PreencherItemFases')}>Preencher Item por Fases</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('ArrastarSoltarCategoria')}>Arrastar Soltar por Categoria</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('Excalidraw')}>Diagramas e Desenhos Livres</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('ImageOcclusion')}>Oclusão de Imagens</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('TimeLine')}>Fases e Itens - Digitado</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('PreencherQuadro')}>Preencher Quadro</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('PerguntaMultiplaEscolha')}>Pergunta Multipla Escolha</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('PerguntaDigitado')}>Pergunta Digitada</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('TermoDefinicao')}>Termo/Definição</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('TreinoFlashCard')}>Treino FlashCard</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('TreinoTermoDefinicao')}>Treino Termo Definição</MenuItem>
        <MenuItem onClick={() => handleSelectComponent('PerguntaRapida')}>Pergunta Rápida</MenuItem>
      </Menu>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza de que deseja excluir este componente?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isEditorOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setIsEditorOpen(false);
          }
        }}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            resize: 'both',
            overflow: 'auto',
          },
        }}
      >
        <DialogTitle>{editingComponente?.id === 0 ? 'Adicionar Componente' : 'Editar Componente'}</DialogTitle>
        <DialogContent>{renderEditor()}</DialogContent>
      </Dialog>
      </div>
    </div>
  );
};

export default UsuarioPage;
