import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

interface AddPastaModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (nome: string) => void;
}

const AddPastaModal: React.FC<AddPastaModalProps> = ({ open, onClose, onSave }) => {
  const [nome, setNome] = useState<string>('');

  const handleSave = () => {
    onSave(nome);
    setNome('');
    onClose(); // Fecha o modal após salvar
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6" component="h2">
          Adicionar Nova Pasta
        </Typography>
        <TextField
          label="Nome da Pasta"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        />
        <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }}>
          Salvar
        </Button>
      </Box>
    </Modal>
  );
};

export default AddPastaModal;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
