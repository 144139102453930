import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { CheckCircleOutline, Replay } from '@mui/icons-material';
import { FaThLarge, FaThList, FaCheck, FaEye, FaEraser, FaTrashAlt, FaArrowRight, FaArrowDown } from 'react-icons/fa';
import './SequencingGame.css';

const isMobile = window.innerWidth <= 768;

interface Phase {
  name: string;
  order: number;
}

interface Activity {
  name: string;
  phase: string;
}

interface SequencingGameProps {
  data: {
    title?: string;
    phases: Phase[];
    activities: Activity[];
    considerarOrdem?: boolean;
  };
}

const SequencingGame: React.FC<SequencingGameProps> = ({ data }) => {
  const [phases, setPhases] = useState<Phase[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [assignedActivities, setAssignedActivities] = useState<{ [key: string]: Activity[] }>({});
  const [result, setResult] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adiciona o controle de largura de tela


  const shuffle = useCallback((array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }, []);

  useEffect(() => {
    if (data) {
      setPhases(shuffle([...data.phases]));
      const shuffledActivities = shuffle([...data.activities]);
      setActivities(shuffledActivities);

      const initialAssignedActivities: { [key: string]: Activity[] } = {};
      data.phases.forEach(phase => {
        initialAssignedActivities[phase.name] = [];
      });
      setAssignedActivities(initialAssignedActivities);
    }
  }, [data, shuffle]);

  // Efeito para monitorar redimensionamento da janela e ajustar a visualização da seta
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    if (source.droppableId === 'activities') {
      if (!destination.droppableId) {
        return;
      }
      const sourceActivities = Array.from(activities);
      const [movedActivity] = sourceActivities.splice(source.index, 1);

      if (!assignedActivities[destination.droppableId]) {
        return;
      }

      const destActivities = Array.from(assignedActivities[destination.droppableId]);
      destActivities.splice(destination.index, 0, movedActivity);

      setActivities(sourceActivities);
      setAssignedActivities(prev => ({
        ...prev,
        [destination.droppableId]: destActivities,
      }));
    } else if (source.droppableId in assignedActivities) {
      if (source.droppableId === destination.droppableId) {
        const phaseActivities = Array.from(assignedActivities[source.droppableId]);
        const [movedActivity] = phaseActivities.splice(source.index, 1);
        phaseActivities.splice(destination.index, 0, movedActivity);

        setAssignedActivities(prev => ({
          ...prev,
          [source.droppableId]: phaseActivities,
        }));
      } else if (destination.droppableId in assignedActivities) {
        const sourceActivities = Array.from(assignedActivities[source.droppableId]);
        const [movedActivity] = sourceActivities.splice(source.index, 1);

        const destActivities = Array.from(assignedActivities[destination.droppableId]);
        destActivities.splice(destination.index, 0, movedActivity);

        setAssignedActivities(prev => ({
          ...prev,
          [source.droppableId]: sourceActivities,
          [destination.droppableId]: destActivities,
        }));
      }
    } else if (source.droppableId === 'phases') {
      const reorderedPhases = Array.from(phases);
      const [movedPhase] = reorderedPhases.splice(source.index, 1);
      reorderedPhases.splice(destination.index, 0, movedPhase);

      setPhases(reorderedPhases);
    }
  };

  const checkOrder = () => {
    let correctPhaseCount = 0;
    let correctActivityCount = 0;
    let totalActivitiesCount = 0;

    phases.forEach((phase, index) => {
      const phaseElement = document.querySelector(`[data-rfd-draggable-id="phase-${phase.name}"]`);
      const phaseLiElement = phaseElement?.querySelector('li');
      const correct = phase.order === index + 1;

      if (correct) {
        correctPhaseCount++;
      }

      updateElementClass(phaseElement, 'faseCorpo', correct, false, !correct);
      updateElementClass(phaseLiElement, 'faseHeader', correct, false, !correct);
    });

    for (const phase in assignedActivities) {
      const activitiesInPhase = assignedActivities[phase];
      if (!activitiesInPhase) continue;

      totalActivitiesCount += activitiesInPhase.length;
      const correctOrder = data.activities.filter(act => act.phase === phase).map(act => act.name);

      const phaseElement = document.querySelector(`[data-rfd-droppable-id="${phase}"]`);
      const activityElements = phaseElement?.querySelectorAll<HTMLLIElement>(".activity-item");

      activitiesInPhase.forEach((activity, index) => {
        const element = activityElements?.[index];

        const correct = correctOrder[index] && correctOrder[index] === activity.name;
        const outOfOrder = correctOrder.includes(activity.name) && !correct;
        const incorrect = !correctOrder.includes(activity.name);

        if (correct) {
          correctActivityCount++;
        }

        updateElementClass(element, 'atividade', correct, outOfOrder, incorrect);
      });
    }

    const totalPhases = phases.length;
    const phaseScore = (correctPhaseCount / totalPhases) * 100;
    const activityScore = (correctActivityCount / totalActivitiesCount) * 100;

    setResult(`Fases: ${phaseScore}% corretas, Atividades: ${activityScore}% corretas`);
  };

  const showAnswers = () => {
    // Ordena as fases corretamente
    setPhases([...data.phases]);

    // Move as atividades para suas respectivas fases de forma ordenada
    const correctAssignedActivities: { [key: string]: Activity[] } = {};
    data.phases.forEach(phase => {
      correctAssignedActivities[phase.name] = data.activities.filter(activity => activity.phase === phase.name);
    });

    setAssignedActivities(correctAssignedActivities);
    setActivities([]); // Esvazia a lista de atividades restantes
  };

  const resetColors = () => {
    // Limpa as cores de todas as fases
    phases.forEach((phase) => {
      const phaseElement = document.querySelector(`[data-rfd-draggable-id="phase-${phase.name}"]`);
      const phaseLiElement = phaseElement?.querySelector('li');
      
      // Remove as classes das fases
      phaseElement?.classList.remove('sequencing-game-fase-correct', 'sequencing-game-fase-incorrect');
      phaseLiElement?.classList.remove('sequencing-game-correct', 'sequencing-game-incorrect', 'sequencing-game-out-of-order');
    });
  
    // Limpa as cores de todas as atividades
    for (const phase in assignedActivities) {
      const phaseElement = document.querySelector(`[data-rfd-droppable-id="${phase}"]`);
      const activityElements = phaseElement?.querySelectorAll<HTMLLIElement>(".activity-item");
  
      activityElements?.forEach((element) => {
        element.classList.remove('sequencing-game-correct', 'sequencing-game-incorrect', 'sequencing-game-out-of-order');
      });
    }
  };
  

  const updateElementClass = (
    element: Element | null,
    elementType: string,
    correct: boolean,
    outOfOrder: boolean,
    incorrect: boolean
  ) => {
    if (!element) return;
    if (elementType === 'atividade' || elementType === 'faseHeader') {
      if (correct) {
        element.classList.add('sequencing-game-correct');
        element.classList.remove('sequencing-game-incorrect', 'sequencing-game-out-of-order');
      } else if (outOfOrder) {
        element.classList.add('sequencing-game-out-of-order');
        element.classList.remove('sequencing-game-correct', 'sequencing-game-incorrect');
      } else if (incorrect) {
        element.classList.add('sequencing-game-incorrect');
        element.classList.remove('sequencing-game-correct', 'sequencing-game-out-of-order');
      }
    } else if (elementType === 'faseCorpo') {
      if (correct) {
        element.classList.add('sequencing-game-fase-correct');
        element.classList.remove('sequencing-game-fase-incorrect');
      } else if (incorrect) {
        element.classList.add('sequencing-game-fase-incorrect');
        element.classList.remove('sequencing-game-fase-correct');
      }
    }
  };

  const resetGame = () => {
    resetColors(); // Reseta as cores antes de reiniciar o jogo
    setPhases(shuffle([...data.phases]));
    setActivities(shuffle([...data.activities]));
    setResult('');
    const resetAssignedActivities: { [key: string]: Activity[] } = {};
    data.phases.forEach(phase => {
      resetAssignedActivities[phase.name] = [];
    });
    setAssignedActivities(resetAssignedActivities);
  };

  return (
    <div className="sequencing-game">
      {data.title && <h3>{data.title}</h3>}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='sequencing-game-layout'>
          <Droppable droppableId="phases" direction={isMobile ? "vertical" : "horizontal"} type="PHASE">
            {(provided: any) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="sequencing-game-phases-container"
                style={{ flexDirection: isMobile ? 'column' : 'row' }}
              >
                {phases.map((phase, index) => (
                  <Draggable draggableId={`phase-${phase.name}`} index={index} key={`phase-${phase.name}`}>
                    {(provided: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="sequencing-game-phase-column"
                      >
                        <li>{phase.name}</li>
                        <Droppable droppableId={phase.name} type="ACTIVITY">
                          {(provided: any) => (
                            <ul
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className="sequencing-game-drop-zone"
                            >
                              {assignedActivities[phase.name]?.map((activity, index) => (
                                <Draggable key={activity.name} draggableId={`activity-${activity.name}`} index={index}>
                                  {(provided: any, snapshot: any) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="activity-item"
                                      style={{
                                        ...provided.draggableProps.style,
                                        backgroundColor: snapshot.isDragging ? 'lightgreen' : 'white',
                                      }}
                                    >
                                      {activity.name}
                                    </li>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                        {!isMobile && index < phases.length - 1 && <FaArrowRight className="sequencing-game-arrow between-phases" />}
                        {isMobile && index < phases.length - 1 && <FaArrowDown className="sequencing-game-arrow" />}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="activities" direction={isMobile ? "vertical" : "horizontal"} type="ACTIVITY">
            {(provided: any) => (
              <ul
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="sequencing-game-sortable-activities"
              >
                {activities.map((activity, index) => (
                  <Draggable key={activity.name} draggableId={`activity-${activity.name}`} index={index}>
                    {(provided: any, snapshot: any) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="activity-item"
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: snapshot.isDragging ? 'lightgreen' : 'white',
                        }}
                      >
                        {activity.name}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          </div>
            <div className='sequencing-game-buttons'>
            <button className="sequencing-game-button" onClick={checkOrder}>
              <FaCheck /> <span>Verificar Ordem</span>
            </button>
            <button className="sequencing-game-button" onClick={showAnswers}>
              <FaEye /> <span>Mostrar Respostas</span>
            </button>
            <button className="sequencing-game-button" onClick={resetGame}>
              <Replay /> <span>Reiniciar</span>
            </button>
          </div>
        <div className="sequencing-game-result">{result}</div>
      </DragDropContext>
    </div>
  );
};

export default SequencingGame;
