import React, { useState } from 'react';
import './PerguntaMultiplaEscolhaTreino.css';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Option {
  text: string;
  correct: boolean;
}

interface PerguntaMultiplaEscolhaTreinoProps {
  question: string;
  options: Option[];
  comment: string;
  onAcertou: () => void;
  onErrou: () => void;
  onProxima: () => void;
  showFeedback: boolean;
}

const PerguntaMultiplaEscolhaTreino: React.FC<PerguntaMultiplaEscolhaTreinoProps> = ({
  question,
  options,
  comment,
  onAcertou,
  onErrou,
  onProxima,
  showFeedback,
}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);

  const handleOptionClick = (index: number) => {
    if (!showFeedback) {
      setSelectedOptionIndex(index);
    }
  };

  const handleResponderClick = () => {
    if (selectedOptionIndex !== null) {
      if (options[selectedOptionIndex].correct) {
        onAcertou();
      } else {
        onErrou();
      }
    }
  };

  return (
    <div className="PerguntaMultiplaEscolhaTreino-container">
      <div className="PerguntaMultiplaEscolhaTreino-question">{question}</div>
      <div className="PerguntaMultiplaEscolhaTreino-options">
        {options.map((option, index) => (
          <div
            key={index}
            className={`PerguntaMultiplaEscolhaTreino-option ${
              showFeedback
                ? option.correct
                  ? 'correct'
                  : selectedOptionIndex === index
                  ? 'wrong'
                  : ''
                : selectedOptionIndex === index
                ? 'selected'
                : ''
            }`}
            onClick={() => handleOptionClick(index)}
          >
            {option.text}
            {showFeedback && option.correct && <span className="correct-mark">✓</span>}
            {showFeedback && selectedOptionIndex === index && !option.correct && <span className="wrong-mark">✗</span>}
          </div>
        ))}
      </div>
      <div className="PerguntaMultiplaEscolhaTreino-buttons">
        {!showFeedback && <button onClick={handleResponderClick}>Responder</button>}
        {showFeedback && (
          <>
            <button onClick={onProxima}>Próxima</button>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Mostrar Comentário
              </AccordionSummary>
              <AccordionDetails>
                <div className="PerguntaMultiplaEscolhaTreino-comment">{comment}</div>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
    </div>
  );
};

export default PerguntaMultiplaEscolhaTreino;
