import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FaThLarge, FaThList, FaCheck, FaEye, FaEraser, FaTrashAlt } from 'react-icons/fa';
import { PiSquareSplitHorizontalFill, PiSquareSplitVerticalFill  } from "react-icons/pi";

import './PreencherItemCategoria.css';

interface Category {
  [key: string]: string[];
}

interface PreencherItemCategoriaProps {
  data: {
    title: string;
    categories: Category;
    considerarOrdem?: boolean;
  };
}

const PreencherItemCategoria: React.FC<PreencherItemCategoriaProps> = ({ data }) => {
  const { categories, considerarOrdem = false } = data;
  const uniqueId = useRef(Math.random().toString(36).substring(2, 15)).current;
  const [view, setView] = useState<'horizontal' | 'vertical'>('horizontal');
  

  const renderCategories = useCallback(() => {
    const categoriesContainer = document.getElementById(`preencher-item-categoria-categoriesContainer-${uniqueId}`);
    if (!categoriesContainer) return;
    categoriesContainer.innerHTML = '';
    if (view === 'horizontal') {
      categoriesContainer.className = 'preencher-item-categoria-horizontal-view';

      Object.entries(categories).forEach(([category, items]) => {
        const categoryContainer = document.createElement('div');
        categoryContainer.className = 'preencher-item-categoria-category-container';

        const headerCell = document.createElement('div');
        headerCell.className = 'preencher-item-categoria-grid-header-cell';
        headerCell.textContent = category;
        categoryContainer.appendChild(headerCell);

        const itemCell = document.createElement('div');
        itemCell.className = 'preencher-item-categoria-grid-item-cell';

        const inputContainer = document.createElement('div');
        inputContainer.className = considerarOrdem?'preencher-item-categoria-item-input-container preencher-item-categoria-item-input-container-em-ordem':'preencher-item-categoria-item-input-container';

        items.forEach((item, index) => {
          const textarea = document.createElement('textarea');
          textarea.className =  considerarOrdem?'preencher-item-categoria-item-input preencher-item-categoria-item-input-em-ordem':'preencher-item-categoria-item-input';
          textarea.dataset.category = category;
          textarea.dataset.index = index.toString();
          textarea.dataset.correctAnswer = item;
          textarea.rows = 1;
          textarea.addEventListener('input', autoResizeTextarea);
          inputContainer.appendChild(textarea);
        });

        itemCell.appendChild(inputContainer);
        categoryContainer.appendChild(itemCell);

        categoriesContainer.appendChild(categoryContainer);
      });
    } else {
      categoriesContainer.className = 'preencher-item-categoria-vertical-view';
      Object.entries(categories).forEach(([category, items]) => {
        const categoryDiv = document.createElement('div');
        categoryDiv.className = 'preencher-item-categoria-category';
        const categoryTitle = document.createElement('h2');
        categoryTitle.textContent = category;
        categoryDiv.appendChild(categoryTitle);

        const inputContainer = document.createElement('div');
        inputContainer.className = 'preencher-item-categoria-item-input-container';

        items.forEach((item, index) => {
          const textarea = document.createElement('textarea');
          textarea.className = 'preencher-item-categoria-item-input';
          textarea.dataset.category = category;
          textarea.dataset.index = index.toString();
          textarea.dataset.correctAnswer = item;
          textarea.rows = 1;
          textarea.addEventListener('input', autoResizeTextarea);
          inputContainer.appendChild(textarea);
          inputContainer.appendChild(document.createElement('br'));
        });
        categoryDiv.appendChild(inputContainer);
        categoriesContainer.appendChild(categoryDiv);
      });
    }
  }, [categories, uniqueId, view]);

  useEffect(() => {
    if (data) {
      renderCategories();
    }
  }, [data, renderCategories]);

  const autoResizeTextarea = (e: Event) => {
    const target = e.target as HTMLTextAreaElement;
    target.style.height = 'auto';
    if (target.scrollHeight > target.clientHeight) {
      target.style.height = target.scrollHeight + 'px';
    }
  };

  const normalizeString = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  const clearFormatting = (input: HTMLTextAreaElement) => {
    input.style.borderColor = '';
    input.style.color = '';
    input.classList.remove('preencher-item-categoria-correct', 'preencher-item-categoria-incorrect', 'preencher-item-categoria-partial');
  };

  const evaluateItemsWithoutOrder = () => {
    const inputs = document.querySelectorAll(`#preencher-item-categoria-categoriesContainer-${uniqueId} .preencher-item-categoria-item-input`) as NodeListOf<HTMLTextAreaElement>;
    const resultsContainer = document.getElementById(`preencher-item-categoria-results-${uniqueId}`);
    if (!resultsContainer) return;
    resultsContainer.innerHTML = '';
    let correctCount = 0;
    let totalCount = 0;

    inputs.forEach(input => {
      clearFormatting(input);

      const category = input.dataset.category!;
      const correctItems = categories[category].map(item => normalizeString(item));
      const userItem = normalizeString(input.value.trim());

      if (correctItems.includes(userItem)) {
        input.style.borderColor = 'green';
        input.style.color = 'green';
        input.classList.add('preencher-item-categoria-correct');
        input.classList.remove('preencher-item-categoria-incorrect');
        correctCount++;
      } else {
        input.style.borderColor = 'red';
        input.style.color = 'red';
        input.classList.add('preencher-item-categoria-incorrect');
        input.classList.remove('preencher-item-categoria-correct');
      }
      totalCount++;
    });

    const summary = document.createElement('div');
    summary.innerHTML = `<strong>Total Correct: ${correctCount}</strong> out of ${totalCount} (${((correctCount / totalCount) * 100).toFixed(2)}%)`;
    resultsContainer.appendChild(summary);
  };

  const evaluateItemsWithOrder = () => {
    const inputs = document.querySelectorAll(`#preencher-item-categoria-categoriesContainer-${uniqueId} .preencher-item-categoria-item-input`) as NodeListOf<HTMLTextAreaElement>;
    const resultsContainer = document.getElementById(`preencher-item-categoria-results-${uniqueId}`);
    if (!resultsContainer) return;
    resultsContainer.innerHTML = '';
    let correctCount = 0;
    let totalCount = 0;

    inputs.forEach(input => {
      clearFormatting(input);

      const category = input.dataset.category!;
      const correctItems = categories[category].map(item => normalizeString(item));
      const userItem = normalizeString(input.value.trim());

      const correctIndex = correctItems.indexOf(userItem);

      if (correctIndex !== -1) {
        if (correctIndex === parseInt(input.dataset.index!)) {
          input.style.borderColor = 'green';
          input.style.color = 'green';
          input.classList.add('preencher-item-categoria-correct');
          input.classList.remove('preencher-item-categoria-incorrect', 'preencher-item-categoria-partial');
          correctCount++;
        } else {
          input.style.borderColor = 'blue';
          input.style.color = 'blue';
          input.classList.add('preencher-item-categoria-partial');
          input.classList.remove('preencher-item-categoria-correct', 'preencher-item-categoria-incorrect');
        }
      } else {
        input.style.borderColor = 'red';
        input.style.color = 'red';
        input.classList.add('preencher-item-categoria-incorrect');
        input.classList.remove('preencher-item-categoria-correct', 'preencher-item-categoria-partial');
      }
      totalCount++;
    });

    const summary = document.createElement('div');
    summary.innerHTML = `<strong>Total Correct: ${correctCount}</strong> out of ${totalCount} (${((correctCount / totalCount) * 100).toFixed(2)}%)`;
    resultsContainer.appendChild(summary);
  };

  const evaluateItems = () => {
    if (considerarOrdem) {
      evaluateItemsWithOrder();
    } else {
      evaluateItemsWithoutOrder();
    }
  };

  const showAnswers = () => {
    const inputs = document.querySelectorAll(`#preencher-item-categoria-categoriesContainer-${uniqueId} .preencher-item-categoria-item-input`) as NodeListOf<HTMLTextAreaElement>;
    inputs.forEach(input => {
      clearFormatting(input);
      input.value = input.dataset.correctAnswer!;
      input.style.height = 'auto';
      input.style.height = input.scrollHeight + 'px';
    });
  };

  const clearFields = () => {
    const inputs = document.querySelectorAll(`#preencher-item-categoria-categoriesContainer-${uniqueId} .preencher-item-categoria-item-input`) as NodeListOf<HTMLTextAreaElement>;
    inputs.forEach(input => {
      input.value = '';
      input.style.height = 'auto';
      clearFormatting(input);
    });
    const resultsContainer = document.getElementById(`preencher-item-categoria-results-${uniqueId}`);
    if (resultsContainer) resultsContainer.innerHTML = '';
  };

  const removeIncorrects = () => {
    const inputs = document.querySelectorAll(`#preencher-item-categoria-categoriesContainer-${uniqueId} .preencher-item-categoria-item-input`) as NodeListOf<HTMLTextAreaElement>;
    inputs.forEach(input => {
      if (input.classList.contains('preencher-item-categoria-incorrect')) {
        input.value = '';
        input.style.height = 'auto';
        clearFormatting(input);
      }
    });
  };

  return (
    <div className="preencher-item-categoria-container">
      <h1>{data.title}</h1>
      <div className="preencher-item-categoria-view-options">
        <button onClick={() => setView('horizontal')} className={view === 'horizontal' ? 'active' : ''}>
          <PiSquareSplitHorizontalFill /> 
        </button>
        <button onClick={() => setView('vertical')} className={view === 'vertical' ? 'active' : ''}>
          <PiSquareSplitVerticalFill /> 
        </button>
      </div>
      <div id={`preencher-item-categoria-categoriesContainer-${uniqueId}`} className={`preencher-item-categoria-${view}-view`}></div>
      <div className="preencher-item-categoria-actions">
        <button className='PreencherItemCategoriaBtAvaliar' onClick={evaluateItems}>
          <FaCheck /> <span>Avaliar</span>
        </button>
        <button onClick={showAnswers}>
          <FaEye /> <span>Ver respostas</span>
        </button>
        <button onClick={clearFields}>
          <FaEraser /> <span>Limpar</span>
        </button>
        <button onClick={removeIncorrects}>
          <FaTrashAlt /> <span>Remover Incorretos</span>
        </button>
      </div>
      <div id={`preencher-item-categoria-results-${uniqueId}`}></div>
    </div>
  );
};

export default PreencherItemCategoria;
