import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

interface AddPaginaModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (titulo: string) => void;
}

const AddPaginaModal: React.FC<AddPaginaModalProps> = ({ open, onClose, onSave }) => {
  const [titulo, setTitulo] = useState<string>('');

  const handleSave = () => {
    onSave(titulo);
    setTitulo('');
    onClose(); // Fecha o modal após salvar
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar Nova Página</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Título"
          fullWidth
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancelar</Button>
        <Button onClick={handleSave} color="primary">Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPaginaModal;
