import Accordion from '@mui/material/Accordion';
import styled from 'styled-components';

const lightenColor = (color: string, amount: number) => {
  const colorWithoutHash = color.slice(1);
  const num = parseInt(colorWithoutHash, 16);
  const r = (num >> 16) + amount;
  const g = ((num >> 8) & 0x00ff) + amount;
  const b = (num & 0x0000ff) + amount;
  return `#${(
    0x1000000 +
    (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000 +
    (g < 255 ? (g < 1 ? 0 : g) : 255) * 0x100 +
    (b < 255 ? (b < 1 ? 0 : b) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

const CustomAccordion = styled(Accordion)<{ bgColor?: string }>`
  margin: 10px 0;
  background-color: ${({ bgColor }) => bgColor || '#fff'};

  .MuiAccordionSummary-root {
    background-color: ${({ bgColor }) => bgColor || '#f7f7f7'};
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s;
    &:hover {
      background-color: ${({ bgColor }) => (bgColor ? bgColor : '#eee')};
    }
  }

  .MuiAccordionDetails-root {
    padding: 16px;
    background-color: ${({ bgColor }) => lightenColor(bgColor || '#fff', 20)};
    transition: max-height 0.3s ease;
  }
`;

export default CustomAccordion;
