// src/utils/utils.ts

// Função para embaralhar um array
export function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  
  // Classe de fila (Queue)
  export class Queue<T> {
    private elements: T[];
  
    constructor() {
      this.elements = [];
    }
  
    enqueue(element: T) {
      this.elements.push(element);
    }
  
    dequeue(): T | undefined {
      return this.elements.shift();
    }
  
    peek(): T | undefined {
      return this.elements[0];
    }
  
    length(): number {
      return this.elements.length;
    }

    size(): number {
        return this.elements.length;
      }
  
    isEmpty(): boolean {
      return this.elements.length === 0;
    }
  
    toArray(): T[] {
      return this.elements;
    }
  }
  