import React, { useEffect, useState } from 'react';
import './QuizDigitado.css';

interface TextQuestion {
  type: 'text';
  question: string;
  answer: string;
  justification: string;
}

interface QuizData {
  title: string;
  quizData: TextQuestion[];
}

interface QuizDigitadoProps {
  data: QuizData;
}

const QuizDigitado: React.FC<QuizDigitadoProps> = ({ data }) => {
  const [quizData, setQuizData] = useState<TextQuestion[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (data && data.quizData) {
      setQuizData(data.quizData);
      setUserAnswers(new Array(data.quizData.length).fill(''));
    }
  }, [data]);

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestionIndex] = e.target.value;
    setUserAnswers(updatedAnswers);
  };

  const handleNext = () => {
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = () => {
    setShowResults(true);
  };

  const handleRestart = () => {
    setShowResults(false);
    setCurrentQuestionIndex(0);
    setUserAnswers(new Array(quizData.length).fill(''));
  };

  if (!quizData.length) {
    return <div>Loading...</div>;
  }

  if (showResults) {
    const score = userAnswers.filter((answer, index) => 
      answer.toLowerCase().trim() === quizData[index].answer.toLowerCase().trim()
    ).length;

    return (
      <div className="QuizDigitado-quiz-container">
        <h1>Resultados do Quiz</h1>
        <p>Pontuação: {score} de {quizData.length}</p>
        <div className="QuizDigitado-quiz-results">
          {quizData.map((question, index) => {
            const userAnswer = userAnswers[index] || '';
            const isCorrect = userAnswer.toLowerCase().trim() === question.answer.toLowerCase().trim();
            return (
              <div key={index} className="QuizDigitado-quiz-result-item">
                <p><strong>Pergunta:</strong> {question.question}</p>
                <p><strong>Sua Resposta:</strong> <span className={isCorrect ? 'QuizDigitado-correct' : 'QuizDigitado-incorrect'}>{userAnswer}</span></p>
                <p><strong>Resposta Correta:</strong> {question.answer}</p>
                <p><strong>Justificativa:</strong> {question.justification}</p>
              </div>
            );
          })}
        </div>
        <button onClick={handleRestart} className="QuizDigitado-button">Reiniciar Quiz</button>
      </div>
    );
  }

  const { question } = quizData[currentQuestionIndex];

  return (
    <div className="QuizDigitado-quiz-container">
      <div className="QuizDigitado-quiz-header">
        <h1>{data.title}</h1>
      </div>
      <div className="QuizDigitado-quiz-question">
        <p>{question}</p>
        <input 
          type="text" 
          value={userAnswers[currentQuestionIndex]} 
          onChange={handleAnswerChange} 
          placeholder="Digite sua resposta aqui..." 
        />
      </div>
      <div className="QuizDigitado-quiz-navigation">
        <button quiz-digitado-button onClick={handlePrev} disabled={currentQuestionIndex === 0} className="QuizDigitado-button">Anterior</button>
        {currentQuestionIndex < quizData.length - 1 ? (
          <button  onClick={handleNext} className="QuizDigitado-button">Próxima</button>
        ) : (
          <button  onClick={handleSubmit} className="QuizDigitado-button">Ver Resultado</button>
        )}
      </div>
    </div>
  );
};

export default QuizDigitado;
