import React from 'react';
import './HomePage.css';

const HomePage: React.FC = () => {
  return (
    <div className='homepage'>
        <h1>Bem-vindo ao <strong>MemoNow</strong></h1>
        <p>Descubra o poder da sua mente e alcance o máximo do seu potencial com o MemoNow. Memorize AGORA, lembre SEMPRE com nosso método inovador de treinamento mental.</p>
        <p>No MemoNow, acreditamos que qualquer pessoa pode ter uma memória excepcional. Nossa plataforma combina técnicas avançadas de memorização com exercícios interativos e divertidos, projetados para fortalecer sua capacidade cerebral de maneira eficiente e prazerosa.</p>
        <ul>
            <li>💡 <strong>Desafie-se com jogos de memória personalizados.</strong></li>
            <li>📚 <strong>Domine novos conhecimentos com técnicas de aprendizagem acelerada.</strong></li>
            <li>🏅 <strong>Acompanhe seu progresso e supere seus limites a cada dia.</strong></li>
        </ul>
        <p>Junte-se à nossa comunidade de mentes brilhantes e comece sua jornada rumo a uma memória infalível. <strong>MemoNow: Memorize Agora, Lembre Sempre.</strong> – porque seu potencial é ilimitado!</p>
    </div>
  );
};

export default HomePage;
