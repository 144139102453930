import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Container, Grid, Link, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [nome, setNome] = useState(''); // Adiciona estado para o nome
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Estado para mensagem de erro
  const { login, user } = useAuth();
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Redireciona para a página inicial se o usuário já estiver logado
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleLogin = async () => {
    try {
      
      const response = await axios.post(`${apiUrl}/auth/login`, { email, senha });

      // Armazena o token no sessionStorage
      sessionStorage.setItem('token', response.data.token);

      // Chama a função login do contexto (opcional, depende da implementação)
      login(response.data.usuario, response.data.token);

      // Redirecionar para a página principal após login bem-sucedido
      navigate('/');
    } catch (error) {
      setErrorMessage('Login failed: Incorrect email or password');
    }
  };

  const handleRegister = async () => {
    try {
      await axios.post(`${apiUrl}/auth/register`, { nome, email, senha });
      handleLogin();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage('Registration failed: ' + error.response.data);
      } else {
        setErrorMessage('Registration failed: An unexpected error occurred');
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Previne o comportamento padrão de submissão de formulário
      isRegistering ? handleRegister() : handleLogin();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {isRegistering ? 'Register' : 'Login'}
        </Typography>
        <Box component="form" sx={{ mt: 1 }} onKeyPress={handleKeyPress}>
          {isRegistering && (
            <TextField
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Name"
              name="nome"
              autoComplete="nome"
              autoFocus
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Password"
            type="password"
            id="senha"
            autoComplete="current-password"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={isRegistering ? handleRegister : handleLogin}
          >
            {isRegistering ? 'Register' : 'Login'}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={() => setIsRegistering(!isRegistering)}>
                {isRegistering ? 'Already have an account? Login' : "Don't have an account? Register"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage(null)}>
        <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginForm;
