import React, { useState } from 'react';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from '@mui/material';
import './TreinoTermoDefinicaoEditor.css';

interface TermoDefinicao {
  termo: string;
  definicao: string;
  comentario: string;
}

interface TreinoTermoDefinicaoEditorProps {
  onSave: (assunto: string, termosDefinicoes: TermoDefinicao[], tiposComponentes: string[], termoComo: 'pergunta' | 'resposta' | 'ambos') => void;
  onCancel: () => void;
}

const TreinoTermoDefinicaoEditor: React.FC<TreinoTermoDefinicaoEditorProps> = ({ onSave, onCancel }) => {
  const [assunto, setAssunto] = useState('');
  const [termosDefinicoes, setTermosDefinicoes] = useState<TermoDefinicao[]>([]);
  const [tiposComponentes, setTiposComponentes] = useState<string[]>([]);
  const [termoComo, setTermoComo] = useState<'pergunta' | 'resposta' | 'ambos'>('pergunta');

  const handleAddTermoDefinicao = () => {
    setTermosDefinicoes([...termosDefinicoes, { termo: '', definicao: '', comentario: '' }]);
  };

  const handleChangeTermoDefinicao = (index: number, field: string, value: string) => {
    const newTermosDefinicoes = [...termosDefinicoes];
    newTermosDefinicoes[index] = { ...newTermosDefinicoes[index], [field]: value };
    setTermosDefinicoes(newTermosDefinicoes);
  };

  const handleSave = () => {
    onSave(assunto, termosDefinicoes, tiposComponentes, termoComo);
  };

  return (
    <div className="treino-termo-definicao-editor">
      <TextField
        label="Assunto"
        value={assunto}
        onChange={(e) => setAssunto(e.target.value)}
        fullWidth
        margin="normal"
      />
      {termosDefinicoes.map((td, index) => (
        <div key={index} className="treino-termo-definicao-termo-definicao">
          <TextField
            label="Termo"
            value={td.termo}
            onChange={(e) => handleChangeTermoDefinicao(index, 'termo', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Definição"
            value={td.definicao}
            onChange={(e) => handleChangeTermoDefinicao(index, 'definicao', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Comentário"
            value={td.comentario}
            onChange={(e) => handleChangeTermoDefinicao(index, 'comentario', e.target.value)}
            fullWidth
            margin="normal"
          />
        </div>
      ))}
      <Button onClick={handleAddTermoDefinicao}>Adicionar Termo/Definição</Button>
      <div>
        <h4>Tipos de Componentes</h4>
        <FormControlLabel
          control={<Checkbox checked={tiposComponentes.includes('FlashCardTreino')} onChange={(e) => {
            if (e.target.checked) {
              setTiposComponentes([...tiposComponentes, 'FlashCardTreino']);
            } else {
              setTiposComponentes(tiposComponentes.filter((tipo) => tipo !== 'FlashCardTreino'));
            }
          }} />}
          label="FlashCards"
        />
        <FormControlLabel
          control={<Checkbox checked={tiposComponentes.includes('PerguntaMultiplaEscolhaTreino')} onChange={(e) => {
            if (e.target.checked) {
              setTiposComponentes([...tiposComponentes, 'PerguntaMultiplaEscolhaTreino']);
            } else {
              setTiposComponentes(tiposComponentes.filter((tipo) => tipo !== 'PerguntaMultiplaEscolhaTreino'));
            }
          }} />}
          label="Pergunta Múltipla Escolha"
        />
        <FormControlLabel
          control={<Checkbox checked={tiposComponentes.includes('PerguntaDigitadaTreino')} onChange={(e) => {
            if (e.target.checked) {
              setTiposComponentes([...tiposComponentes, 'PerguntaDigitadaTreino']);
            } else {
              setTiposComponentes(tiposComponentes.filter((tipo) => tipo !== 'PerguntaDigitadaTreino'));
            }
          }} />}
          label="Pergunta Digitada"
        />
      </div>
      <div>
        <h4>Termo como:</h4>
        <Select
          value={termoComo}
          onChange={(e) => setTermoComo(e.target.value as 'pergunta' | 'resposta' | 'ambos')}
        >
          <MenuItem value="pergunta">Pergunta</MenuItem>
          <MenuItem value="resposta">Resposta</MenuItem>
          <MenuItem value="ambos">Ambos</MenuItem>
        </Select>
      </div>
      <Button onClick={handleSave} color="primary" variant="contained">
        Salvar
      </Button>
      <Button onClick={onCancel} color="secondary" variant="contained">
        Cancelar
      </Button>
    </div>
  );
};

export default TreinoTermoDefinicaoEditor;
