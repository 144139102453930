import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NovaPagina from './pages/NovaPagina';
import EstudoPersonalizado from './pages/EstudoPersonalizado';
import AlterarEmail from './pages/AlterarEmail';
import AlterarSenha from './pages/AlterarSenha';
import Header from './components/organisms/Header/Header';
import MenuPastas from './pages/MenuPastas';
import UsuarioPage from './pages/UsuarioPage';
import LoginForm from './components/LoginForm';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';
import './global.css';
import RevisaoEspacadaPage from './pages/RevisaoEspacadaPage';
import RevisaoEspacadaViewer from './pages/RevisaoEspacadaViewer';
import ComponenteViewer from './pages/ComponenteViewer';
const { pathname } = window.location;


declare global {
  interface Window {
    EXCALIDRAW_ASSET_PATH: string;
  }
}

window.EXCALIDRAW_ASSET_PATH = "/fonts";

const AppContent: React.FC = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState<string>('home');

  useEffect(() => {
    if (!user && !pathname.startsWith('/visualizar/componente')) {
      navigate('/login');
    }
  }, [user, navigate]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  };

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const handleSelectPage = (pageId: number) => {
    navigate(`/estudo-guiado/pagina/${pageId}`);
    closeMenu();
  };

  const toggleMenuVisibility = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className={`app-container ${menuVisible ? 'menu-visible' : 'menu-hidden'}`}>
      {/* Renderiza o Header apenas se o usuário estiver logado */}
      {user && (
        <>
          <Header 
            toggleMenu={toggleMenu} 
            closeMenu={closeMenu} 
            handleSectionChange={handleSectionChange} 
            activeSection={activeSection} 
            toggleMenuPastas={toggleMenuVisibility} 
          />
          {menuVisible && activeSection === 'estudo-personalizado' && (
            <MenuPastas 
              onSelectPage={handleSelectPage} 
              isVisible={menuVisible} 
              toggleVisibility={toggleMenuVisibility} 
            />
          )}
        </>
      )}
      <div className="content" onClick={closeMenu}>
        <Routes>
        <Route path="/login" element={user ? <Navigate to="/" /> : <LoginForm />} />
        <Route element={<PrivateRoute allowedRoles={['pro', 'admin']} />}>
            <Route path="/novo-pagina" element={<NovaPagina />} />
            <Route path="/estudo-personalizado/*" element={<EstudoPersonalizado userId={user?.id || 0} />} />
            <Route path="/estudo-personalizado/minhas-notas/:pageId" element={<UsuarioPageWrapper />} />
          </Route>
          <Route element={<PrivateRoute allowedRoles={['free', 'pro', 'admin']} />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/desafios-do-dia" element={<div>Desafios do Dia</div>} />
          </Route>
          <Route element={<PrivateRoute allowedRoles={['pro', 'admin']} />}>
            <Route path="/alterar-email" element={<AlterarEmail />} />
            <Route path="/alterar-senha" element={<AlterarSenha />} />
            <Route path="/revisao-espacada" element={<RevisaoEspacadaPage usuarioId={user?.id || 0} />} />
          </Route>
          <Route path="/visualizar/componente/:id" element={<ComponenteViewer />} />
        </Routes>
      </div>
    </div>
  );
};

const UsuarioPageWrapper: React.FC = () => {
  const { pageId } = useParams<{ pageId: string }>();
  return <UsuarioPage pageId={Number(pageId)} />;
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
