import React, { useState } from 'react';
import './FlashCard.css';

interface FlashCardData {
  question: string;
  answer: string;
  saiba_mais?: string;
}

interface FlashCardProps {
  title: string;
  flashcardData: FlashCardData[];
}

const FlashCard: React.FC<FlashCardProps> = ({ title, flashcardData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [studyProgress, setStudyProgress] = useState<Record<number, string>>({});

  if (!flashcardData || flashcardData.length === 0) {
    return <p>Nenhum flashcard disponível.</p>;
  }

  const handleShowAnswer = () => setShowAnswer(true);

  const handleShowMoreInfo = () => setShowMoreInfo(!showMoreInfo);

  const handleDifficulty = (difficulty: string) => {
    setStudyProgress({ ...studyProgress, [currentIndex]: difficulty });
    nextCard();
  };

  const nextCard = () => {
    setShowAnswer(false);
    setShowMoreInfo(false);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % flashcardData.length);
  };

  const prevCard = () => {
    setShowAnswer(false);
    setShowMoreInfo(false);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + flashcardData.length) % flashcardData.length);
  };

  return (
    <div className="flashcard-container">
      <h2>{title}</h2>
      <div className="flashcard-question">
        <div dangerouslySetInnerHTML={{ __html: flashcardData[currentIndex].question ?? '' }} />
      </div>
      {!showAnswer && (
        <button className="flashcard-show-answer" onClick={handleShowAnswer}>Mostrar Resposta</button>
      )}
      {showAnswer && (
        <div className="flashcard-answer">
          <div dangerouslySetInnerHTML={{ __html: flashcardData[currentIndex].answer ?? '' }} />
        </div>
      )}
      {showAnswer && flashcardData[currentIndex].saiba_mais && (
        <button className="flashcard-show-more" onClick={handleShowMoreInfo}>
          {showMoreInfo ? 'Esconder Informação Adicional' : 'Mostrar Informação Adicional'}
        </button>
      )}
      {showAnswer && showMoreInfo && flashcardData[currentIndex].saiba_mais && (
        <div className="flashcard-more-info">
          <div dangerouslySetInnerHTML={{ __html: flashcardData[currentIndex].saiba_mais ?? '' }} />
        </div>
      )}
      {showAnswer && (
        <div className="flashcard-buttons">
          <button className="flashcard-button easy" onClick={() => handleDifficulty('easy')}>Fácil</button>
          <button className="flashcard-button medium" onClick={() => handleDifficulty('medium')}>Médio</button>
          <button className="flashcard-button hard" onClick={() => handleDifficulty('hard')}>Difícil</button>
          <button className="flashcard-button wrong" onClick={() => handleDifficulty('wrong')}>Errei</button>
        </div>
      )}
      <div className="flashcard-navigation">
        <button onClick={prevCard}>Anterior</button>
        <button onClick={nextCard}>Próximo</button>
      </div>
    </div>
  );
};

export default FlashCard;
