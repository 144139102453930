// @ts-nocheck
/* eslint-disable */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import JoditEditor from 'jodit-react';
import Prism from 'prismjs';
import { Button, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import './TextoEditor.css';
import 'jodit/es2021/jodit.min.css';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-javascript.min.js';
import 'prismjs/components/prism-css.min.js';
import 'prismjs/components/prism-python.min.js';

interface TextoEditorProps {
  initialContent: string;
  onSave: (content: string) => void;
  onCancel: () => void;
  hideButtons?: boolean; // Nova prop para ocultar os botões
}

const TextoEditor = forwardRef(({ initialContent, onSave, onCancel, hideButtons }: TextoEditorProps, ref) => {
  const editor = useRef<any>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [editorContent, setEditorContent] = useState(initialContent);
  const [editorKey, setEditorKey] = useState(0);

  useEffect(() => {
    setEditorContent(initialContent);
    setEditorKey(prevKey => prevKey + 1); // Força a recriação do editor
  }, [initialContent]);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useImperativeHandle(ref, () => ({
    save: () => {
      handleSave();
    }
  }));

  const handleSave = () => {
    if (editor.current) {
      const editorContent = editor.current.value;
      onSave(editorContent);
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const config = {
    readonly: false,
    height: isFullscreen ? 'calc(100vh - 100px)' : 400,
    autofocus: true,
    toolbar: true,
    buttons: [
      'source', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'superscript', 'subscript', '|',
      'ul', 'ol', '|',
      'outdent', 'indent', '|',
      'font', 'fontsize', 'brush', 'paragraph', '|',
      'image', 'video', 'table', 'link', '|',
      'align', 'undo', 'redo', '|',
      'hr', 'eraser', 'copyformat', 'symbols', '|',
      'fullsize', 'selectall', 'print', 'about', '|',
      {
        name: 'insertMergeField',
        tooltip: 'Insert Merge Field',
        iconURL: 'images/merge.png',
        popup: (editor: any, current: any, self: any, close: any) => {
          const onSelected = (e: any) => {
            const mergeField = e.target.value;
            if (mergeField) {
              editor.selection.insertNode(editor.create.inside.fromHTML(`{{${mergeField}}}`));
            }
          };
          const divElement = editor.create.div("merge-field-popup");

          const labelElement = document.createElement("label");
          labelElement.setAttribute("class", "merge-field-label");
          labelElement.textContent = 'Merge field: ';
          divElement.appendChild(labelElement);

          const selectElement = document.createElement("select");
          selectElement.setAttribute("class", "merge-field-select");
          selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
          selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
          selectElement.onchange = onSelected;
          divElement.appendChild(selectElement);

          return divElement;
        }
      },
      {
        name: "copyContent",
        tooltip: "Copy HTML to Clipboard",
        iconURL: "images/copy.png",
        exec: (editor: any) => {
          const html = editor.value;
          copyStringToClipboard(html);
        }
      }
    ]
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      handleSave();
    },
    reset: () => {
      setEditorContent(''); // Zera o conteúdo explicitamente
    }
  }));
  

  return (
    <div className={`TextoEditor-container ${isFullscreen ? 'fullscreen' : ''}`}>
      <div className="TextoEditor-editor">
        <JoditEditor
          key={editorKey} 
          ref={editor}
          value={editorContent}
          config={config}
        />
      </div>
      <IconButton onClick={toggleFullscreen} className="TextoEditor-fullscreenButton">
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>
      {!hideButtons && (
        <div className="TextoEditor-buttons">
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="contained" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
});

export default TextoEditor;
