import React, { useState, useEffect, useRef } from 'react';
import { Phase, Activity } from '../../../types';
import { IconButton, Button, Box, TextField, FormControlLabel, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface SequencingGameEditorProps {
  title?: string;
  phases: Phase[];
  activities: Activity[];
  considerarOrdem?: boolean;
  onSave: (title: string, phases: Phase[], activities: Activity[], considerarOrdem: boolean) => void;
  onCancel: () => void;
}

const SequencingGameEditor: React.FC<SequencingGameEditorProps> = ({
  title: initialTitle = '',
  phases: initialPhases,
  activities: initialActivities,
  considerarOrdem: initialConsiderarOrdem = false,
  onSave,
  onCancel,
}) => {
  const [title, setTitle] = useState<string>(initialTitle);
  const [phases, setPhases] = useState<Phase[]>(initialPhases);
  const [activities, setActivities] = useState<Activity[]>(initialActivities);
  const [considerarOrdem, setConsiderarOrdem] = useState<boolean>(initialConsiderarOrdem);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const [focusPhaseIndex, setFocusPhaseIndex] = useState<number | null>(null);
  const activityRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());
  const phaseRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());

  useEffect(() => {
    if (focusIndex !== null) {
      const ref = activityRefs.current.get(focusIndex);
      if (ref) {
        ref.focus();
        setFocusIndex(null); // Clear focusIndex to prevent continuous focusing
      }
    }
  }, [focusIndex]);

  useEffect(() => {
    if (focusPhaseIndex !== null) {
      const ref = phaseRefs.current.get(focusPhaseIndex);
      if (ref) {
        ref.focus();
        setFocusPhaseIndex(null); // Clear focusPhaseIndex to prevent continuous focusing
      }
    }
  }, [focusPhaseIndex]);

  const handleAddPhase = () => {
    const newPhase: Phase = { name: '', order: phases.length + 1 };
    setPhases([...phases, newPhase]);
    setFocusPhaseIndex(phases.length);
  };

  const handleEditPhaseName = (index: number, name: string) => {
    const updatedPhases = [...phases];
    const oldPhaseName = updatedPhases[index].name;
    updatedPhases[index].name = name;
    const updatedActivities = activities.map(activity =>
      activity.phase === oldPhaseName ? { ...activity, phase: name } : activity
    );
    setPhases(updatedPhases);
    setActivities(updatedActivities);
  };

  const handleMovePhase = (index: number, direction: 'up' | 'down') => {
    const newPhases = [...phases];
    const [movedPhase] = newPhases.splice(index, 1);
    newPhases.splice(direction === 'up' ? index - 1 : index + 1, 0, movedPhase);
    setPhases(newPhases);
  };

  const handleRemovePhase = (index: number) => {
    const phaseName = phases[index].name;
    const newPhases = phases.filter((_, i) => i !== index);
    const newActivities = activities.filter(activity => activity.phase !== phaseName);
    setPhases(newPhases);
    setActivities(newActivities);
  };

  const handleAddActivity = (phaseName: string, phaseIndex: number) => {
    const newActivity: Activity = { name: '', phase: phaseName };
    setActivities((prevActivities) => {
      const updatedActivities = [...prevActivities, newActivity];
      setFocusIndex(updatedActivities.length - 1);
      return updatedActivities;
    });
  };

  const handleEditActivityName = (activityIndex: number, name: string) => {
    const updatedActivities = activities.map((activity, index) =>
      index === activityIndex ? { ...activity, name } : activity
    );
    setActivities(updatedActivities);
  };

  const handleMoveActivity = (index: number, direction: 'up' | 'down') => {
    const newActivities = [...activities];
    const [movedActivity] = newActivities.splice(index, 1);
    newActivities.splice(direction === 'up' ? index - 1 : index + 1, 0, movedActivity);
    setActivities(newActivities);
  };

  const handleRemoveActivity = (activityIndex: number) => {
    const newActivities = activities.filter((_, index) => index !== activityIndex);
    setActivities(newActivities);
  };

  const handleSaveAll = () => {
    const updatedPhases = phases.map((phase, index) => ({ ...phase, order: index + 1 }));
    const filteredActivities = activities.filter(activity => activity.name.trim() !== '');
    onSave(title, updatedPhases, filteredActivities, considerarOrdem);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>, type: 'phase' | 'activity', phaseIndex: number, activityIndex?: number) => {
    const newValue = e.target.innerText.trim();
    if (type === 'phase') {
      handleEditPhaseName(phaseIndex, newValue);
    } else if (type === 'activity' && activityIndex !== undefined) {
      handleEditActivityName(activityIndex, newValue);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, type: 'phase' | 'activity', phaseName: string, phaseIndex: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (type === 'phase') {
        handleAddPhase();
      } else if (type === 'activity') {
        handleAddActivity(phaseName, phaseIndex);
      }
    }
  };

  return (
    <div className="sequencing-game-editor">
      <h2>Editor de Sequenciamento</h2>
      <TextField
        label="Título"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleAddPhase}>
        Adicionar Fase
      </Button>
      <FormControlLabel
        label="Considerar ordem das atividades"
        control={
          <Checkbox
            checked={considerarOrdem}
            onChange={(e) => setConsiderarOrdem(e.target.checked)}
            color="primary"
          />
        }
      />

      <h3>Fases</h3>
      <ul>
        {phases.map((phase, phaseIndex) => (
          <li key={phaseIndex}>
            <Box display="flex" alignItems="center">
              <div
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => handleBlur(e, 'phase', phaseIndex)}
                onKeyPress={(e) => handleKeyPress(e, 'phase', phase.name, phaseIndex)}
                ref={(el) => phaseRefs.current.set(phaseIndex, el)}
                style={{ borderBottom: '1px dashed #ccc', cursor: 'text', padding: '5px', flexGrow: 1 }}
              >
                {phase.name}
              </div>
              <IconButton onClick={() => handleRemovePhase(phaseIndex)}><DeleteIcon /></IconButton>
              <IconButton
                onClick={() => handleMovePhase(phaseIndex, 'up')}
                disabled={phaseIndex === 0}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => handleMovePhase(phaseIndex, 'down')}
                disabled={phaseIndex === phases.length - 1}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
            <ul>
              {activities
                .map((activity, activityIndex) => (
                  activity.phase === phase.name && (
                    <li key={activityIndex}>
                      <Box display="flex" alignItems="center">
                        <div
                          contentEditable
                          suppressContentEditableWarning
                          onBlur={(e) => handleBlur(e, 'activity', phaseIndex, activityIndex)}
                          onKeyPress={(e) => handleKeyPress(e, 'activity', phase.name, phaseIndex)}
                          ref={(el) => activityRefs.current.set(activityIndex, el)}
                          style={{ borderBottom: '1px dashed #ccc', cursor: 'text', padding: '5px', flexGrow: 1 }}
                        >
                          {activity.name}
                        </div>
                        <IconButton onClick={() => handleRemoveActivity(activityIndex)}><DeleteIcon /></IconButton>
                        <IconButton
                          onClick={() => handleMoveActivity(activityIndex, 'up')}
                          disabled={activityIndex === 0}
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleMoveActivity(activityIndex, 'down')}
                          disabled={activityIndex === activities.filter(a => a.phase === phase.name).length - 1}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </Box>
                    </li>
                  )
                ))}
              <li>
                <IconButton onClick={() => handleAddActivity(phase.name, phaseIndex)}><AddIcon /></IconButton>
              </li>
            </ul>
          </li>
        ))}
      </ul>

      <Button variant="contained" color="primary" onClick={handleSaveAll}>
        Salvar Tudo
      </Button>
      <Button variant="contained" color="secondary" onClick={onCancel}>
        Cancelar
      </Button>
    </div>
  );
};

export default SequencingGameEditor;
