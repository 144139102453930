import React, { useState, useEffect, useCallback } from 'react';
import { Excalidraw } from '@excalidraw/excalidraw';
import { Button, IconButton } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import './ExcalidrawEditor.css';

interface ExcalidrawEditorProps {
  initialData: {
    elements: any[];
    appState: any;
  };
  onSave: (data: { elements: any[]; appState: any }) => void;
  onCancel: () => void;
}

const ExcalidrawEditor: React.FC<ExcalidrawEditorProps> = ({ initialData, onSave, onCancel }) => {
  const [elements, setElements] = useState(initialData.elements);
  const [appState, setAppState] = useState({
    ...initialData.appState,
    collaborators: [], // Ensure collaborators is an empty array
  });
  const [isFullscreen, setIsFullscreen] = useState(true);

  useEffect(() => {
    setElements(initialData.elements);
    setAppState({
      ...initialData.appState,
      collaborators: [], // Ensure collaborators is an empty array
    });
  }, [initialData]);

  const handleSave = () => {
    onSave({ elements, appState });
  };

  const handleChange = useCallback(
    (updatedElements: readonly any[], updatedAppState: any) => {
      setElements([...updatedElements]);
      setAppState({ ...updatedAppState, collaborators: [] }); // Ensure collaborators is an empty array
    },
    []
  );

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div className={`excalidraw-editor-container ${isFullscreen ? 'fullscreen' : ''}`}>
      <div className="excalidraw-canvas-container">
        <Excalidraw
          initialData={{ elements, appState }}
          onChange={handleChange}
        />
      </div>
      <div className="excalidraw-editor-footer">
        <IconButton onClick={toggleFullscreen}>
          {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
        <Button variant="contained" color="primary" onClick={handleSave} style={{ marginRight: '10px' }}>
          Save
        </Button>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ExcalidrawEditor;
