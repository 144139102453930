import React, { useEffect, useState } from 'react';
import './QuizMultiploImediato.css';

interface QuestionData {
  ano: string;
  banca: string;
  orgao: string;
  prova: string;
  question: string;
  options: string[];
  answer: string;
  justification: string;
}

interface QuizMultiploImediatoProps {
  data: QuestionData[];
}

const QuizMultiploImediato: React.FC<QuizMultiploImediatoProps> = ({ data }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [questionStatus, setQuestionStatus] = useState<(string | null)[]>([]);
  const [result, setResult] = useState('');

  useEffect(() => {
    setUserAnswers(new Array(data.length).fill(null));
    setQuestionStatus(new Array(data.length).fill(null));
  }, [data]);

  const selectOption = (selectedIndex: number) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestionIndex] = data[currentQuestionIndex].options[selectedIndex];
    setUserAnswers(newUserAnswers);
  };

  const showResponse = () => {
    const currentQuestion = data[currentQuestionIndex];
    const userAnswer = userAnswers[currentQuestionIndex];
    const isCorrect = userAnswer === currentQuestion.answer;
    const newQuestionStatus = [...questionStatus];
    newQuestionStatus[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
    setQuestionStatus(newQuestionStatus);
    showResult();
  };

  const showResult = () => {
    const score = userAnswers.filter((answer, index) => answer === data[index].answer).length;
    const percentage = ((score / data.length) * 100).toFixed(2);
    setResult(`Você acertou ${score} de ${data.length} (${percentage}%)`);
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < data.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setUserAnswers(new Array(data.length).fill(null));
    setQuestionStatus(new Array(data.length).fill(null));
    setResult('');
  };

  return (
    <div className="quiz-multiplo-imediato-container">
      <div className="quiz-multiplo-imediato-header">
        Ano: {data[currentQuestionIndex].ano} | Banca: {data[currentQuestionIndex].banca} | Órgão: {data[currentQuestionIndex].orgao} | Prova: {data[currentQuestionIndex].prova}
      </div>
      <div className="quiz-multiplo-imediato-question">{data[currentQuestionIndex].question}</div>
      <div className="quiz-multiplo-imediato-options">
        {data[currentQuestionIndex].options.map((option, index) => (
          <button
            key={index}
            className={`quiz-multiplo-imediato-option ${userAnswers[currentQuestionIndex] === option ? 'selected' : ''}`}
            onClick={() => selectOption(index)}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="quiz-multiplo-imediato-navigation">
        <button onClick={prevQuestion} disabled={currentQuestionIndex === 0} className="quiz-multiplo-imediato-nav-button">⟵</button>
        <button onClick={showResponse} className="quiz-multiplo-imediato-nav-button">✔</button>
        <button onClick={nextQuestion} disabled={currentQuestionIndex === data.length - 1} className="quiz-multiplo-imediato-nav-button">⟶</button>
      </div>
      <div className="quiz-multiplo-imediato-response">
        {questionStatus[currentQuestionIndex] !== null && (
          <div>
            <h3 style={{ color: questionStatus[currentQuestionIndex] === 'correct' ? 'green' : 'red' }}>
              {questionStatus[currentQuestionIndex] === 'correct' ? 'ACERTOU' : 'ERROU'}
            </h3>
            <p>Resposta Correta: <span className="quiz-multiplo-imediato-correct">{data[currentQuestionIndex].answer}</span></p>
            <p>Justificativa: {data[currentQuestionIndex].justification}</p>
          </div>
        )}
      </div>
      <div className="quiz-multiplo-imediato-result">{result}</div>
      <button onClick={resetQuiz} className="quiz-multiplo-imediato-reset-button">Reiniciar</button>
    </div>
  );
};

export default QuizMultiploImediato;
