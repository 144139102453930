import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../services/api';
import {
  Button,
  TextField,
  Container,
  Typography,
  Paper,
} from '@mui/material';

const NovaPagina: React.FC = () => {
  const [titulo, setTitulo] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const topicoId = searchParams.get('topicoId');

  const handleSave = async () => {
    try {
      const response = await api.post('/paginasEstudo', {
        titulo,
        topico: { id: topicoId },
      });

      navigate(`/estudo/pagina/${response.data.id}`);
    } catch (error) {
      console.error('Error saving page:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper style={{ padding: '16px', marginTop: '16px' }}>
        <Typography variant="h6">Nova Página de Estudo</Typography>
        <TextField
          label="Título"
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Paper>
    </Container>
  );
};

export default NovaPagina;
