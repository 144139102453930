import React, { useState } from 'react';
import { TextField, Button, IconButton, Checkbox, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormGroup, Card, CardContent, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './PerguntaMultiplaEscolhaEditor.css';

interface Option {
  text: string;
  correct: boolean;
}

interface PerguntaMultiplaEscolhaEditorProps {
  initialQuestion?: string;
  initialOptions?: Option[];
  initialQuestionType?: 'single' | 'multiple';
  initialComment?: string;
  onSave: (question: string, options: Option[], questionType: 'single' | 'multiple', comment: string, action: 'close' | 'addNew') => void;
  onCancel: () => void;
  mode: 'add' | 'edit';
}

const PerguntaMultiplaEscolhaEditor: React.FC<PerguntaMultiplaEscolhaEditorProps> = ({
  initialQuestion = '',
  initialOptions = [{ text: '', correct: false }],
  initialQuestionType = 'single',
  initialComment = '',
  onSave,
  onCancel,
  mode,
}) => {
  const [question, setQuestion] = useState<string>(initialQuestion);
  const [options, setOptions] = useState<Option[]>(initialOptions);
  const [questionType, setQuestionType] = useState<'single' | 'multiple'>(initialQuestionType);
  const [comment, setComment] = useState<string>(initialComment);

  const handleOptionTextChange = (index: number, text: string) => {
    const newOptions = [...options];
    newOptions[index].text = text;
    setOptions(newOptions);
  };

  const handleOptionCorrectChange = (index: number) => {
    const newOptions = [...options];
    newOptions[index].correct = !newOptions[index].correct;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { text: '', correct: false }]);
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const handleSave = (action: 'close' | 'addNew') => {
    onSave(question, options, questionType, comment, action);
    if (mode === 'add' && action === 'addNew') {
      setQuestion('');
      setOptions([{ text: '', correct: false }]);
      setComment('');
    }
  };

  const handleQuestionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionType(event.target.value as 'single' | 'multiple');
  };

  return (
    <Card className="PerguntaMultiplaEscolhaEditor-card">
      <CardContent>
        <Typography variant="h5" component="div">
          {mode === 'add' ? 'Adicionar Nova Pergunta' : 'Editar Pergunta'}
        </Typography>
        <TextField
          label="Pergunta"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControl component="fieldset" className="PerguntaMultiplaEscolhaEditor-question-type-control">
          <FormLabel component="legend">Tipo de Pergunta</FormLabel>
          <RadioGroup
            row
            value={questionType}
            onChange={handleQuestionTypeChange}
          >
            <FormControlLabel value="single" control={<Radio />} label="Única Opção Correta" />
            <FormControlLabel value="multiple" control={<Radio />} label="Múltiplas Opções Corretas" />
          </RadioGroup>
        </FormControl>
        <FormGroup>
          {options.map((option, index) => (
            <div key={index} className="PerguntaMultiplaEscolhaEditor-option-container">
              <TextField
                label={`Opção ${index + 1}`}
                value={option.text}
                onChange={(e) => handleOptionTextChange(index, e.target.value)}
                fullWidth
                margin="normal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={option.correct}
                    onChange={() => handleOptionCorrectChange(index)}
                  />
                }
                label="Correta"
              />
              <IconButton color="secondary" onClick={() => handleRemoveOption(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </FormGroup>
        <Button variant="contained" color="primary" onClick={handleAddOption} startIcon={<AddIcon />}>
          Adicionar Opção
        </Button>
        <ReactQuill
          value={comment}
          onChange={setComment}
          modules={{
            toolbar: [
              [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['bold', 'italic', 'underline'],
              [{ 'align': [] }],
              ['link', 'image'],
              ['clean']
            ]
          }}
          formats={[
            'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image'
          ]}
        />
        <div className="PerguntaMultiplaEscolhaEditor-buttons">
          <Button variant="contained" color="primary" onClick={() => handleSave(mode === 'add' ? 'addNew' : 'close')} startIcon={<SaveIcon />}>
            {mode === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
          <Button variant="contained" onClick={onCancel} startIcon={<CancelIcon />}>
            Cancelar
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default PerguntaMultiplaEscolhaEditor;
